import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import BrandNav from '../component/brandNav';
import Firebase from "../../util/firebase";
import { withRouter } from "react-router-dom";
import Invite from './invite';
import ProposalReview from './proposalReview';
import SideBar from './sidebar';

const EditExisting = ({history, campaignId, partner}) => {
  const [step, setStep] = useState(3);
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [proposals, setProposals] = useState([]);
  const [selectInfluencers, setSelectedInfluencers] = useState([]);
  // temp list
  const [selectedList, setSelectedList] = useState([]);

  const addToList = (influencerId) => {
    setSelectedList(list => [...list, influencerId])
  };
  const removeFromList = (influencerId) => {
    setSelectedList(list => list.filter(item => item !== influencerId))
  };


  const credit = useMemo(() => {
    if(partner && partner.credit){
      return partner.credit;
    }
    return 0;
  }, [partner]);

  useEffect(() => {

    const fetchCampaign = async (campaignId) => {
      const campaignRef = Firebase.firestore().collection("campaign").doc(campaignId);
      try {
        const campaignDoc = await campaignRef.get();
        const campaignData = campaignDoc.data();
        setCampaign({
          ...campaignData,
          campaignId
        });
        const {status} = campaignData;
        if(status === 1){
          const {proposalList} = campaignData;
          if(proposalList){
            await fetchProposals(proposalList);
          }
        }
        setLoading(false);
      }catch(err){
        console.log(err);
      }
    };
    /* const fetchInfluencers = async (influencerList) => {
      try {
        const list = [];
        for(const influencer of influencerList){
          const influencerRef = Firebase.firestore().collection("influencer").doc(influencer); 
          const influencerDoc = await influencerRef.get();
          const influencerData = influencerDoc.data();
          const obj = {
            ...influencerData,
            influencerId:influencer
          }
          list.push(obj)
        }
        setInfluencers(list);
      }catch(err){
        console.log(err, 'fetch influencers error')
      }
    } */

    const fetchProposals = async (proposals) => {
      try {
        const list = [];
        for(const proposal of proposals){
          const proposalRef = Firebase.firestore().collection("proposal").doc(proposal); 
          const proposalDoc = await proposalRef.get();
          const proposalData = proposalDoc.data();
          const obj = {
            ...proposalData,
            proposalId:proposal
          }
          list.push(obj)
        }
        setProposals(list);
      }catch(err){
        console.log(err, 'fetch propsals error')
      }

    }

    const getData = async () => {
      try {
        await fetchCampaign(campaignId);
        setLoading(false);
      }catch(err){
        console.log(err, 'get data error')
      }
    }

    getData()
  }, [campaignId]);

  const campaignDetail = useMemo(() => {
    if(campaign){
      const {startDate, endDate, name, description,category, budget} = campaign;
      return {
        startDate,
        endDate,
        name,
        description,
        category,
        budget
      }
    }else {
      return {};
    }
  }, [campaign]);

  const unMutableInfluencersList = useMemo(() => {
    if(campaign && campaign.influencersList){
      return campaign.influencersList;
    }else {
      return [];
    }
  }, [campaign]);


  const saveInflueners = (influeners) => {
    setSelectedInfluencers(influeners);
    setStep(4);
  }

  const backToSelectInfluencers = () => { 
    setStep(3);
  }

  const selectProduct = useMemo(() => {
    if(campaign){
      return campaign.productId;
    }else {
      return null;
    }
  }, [campaign]);


  return <Container>
     <BrandNav credit={credit}/>
     <SideBar step={step}/>
     <Content>
     {
      step === 3 && <Invite 
      partner={partner} 
      tempDetail={campaignDetail} 
      saveInflueners={saveInflueners} 
      unMutableInfluencersList={unMutableInfluencersList}
      addToList={addToList}
      removeFromList={removeFromList}
      selectedList={selectedList}
      editExisting={true}
      />
     }
     {
      step === 4 && <ProposalReview tempDetail={campaignDetail} selectInfluencers={selectInfluencers} 
      partner={partner}
      /* updateTempDetail={updateTempDetail} */
      selectProduct={selectProduct}
      campaignMutable={false}
      editExisting={true}
      campaignId={campaignId}
      unMutableInfluencersList={unMutableInfluencersList}
      backToSelectInfluencers={backToSelectInfluencers}
      />
     }
     </Content>
  </Container>
}

const Container = styled.div`
width: 100%;
overflow-y: auto;
overflow-x: hidden;
background: #F6F6F6 0% 0% no-repeat padding-box;
position: relative;
`;


const Content = styled.div`
background-color: #F6F6F6;
height:calc(100vh);
width: 100%;
padding-top:108px;
padding-left:320px;
`;

export default withRouter(EditExisting);