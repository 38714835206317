import React, {useState} from 'react';
import * as API from "../../util/api";
import axios from "axios";
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined} from '@ant-design/icons';
import { debounce } from "lodash";
import { Button, Input, message } from 'antd';

const CreateGameByAppId = ({partner, history}) => {
  const [appId, setAppId] = useState("");
  const [gameDetail, setGameDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const searchGame = async () => {
    if(appId !== ""){
      try {
        const res = await axios.get(API.CHECK_GAME, {
          params: {
            appId
          }
        });
        const data = res.data;
        setGameDetail(data);
      }catch(err){
        message.error("There is no game related to the steam app id you provide")
      }
    }else {
      message.error("Please enter valid steam app id")
    }
  }

  const debounceCreatNewGame = debounce(() => {
    createNewGame()
  },500);

  const createNewGame = async () => {
    if(appId && gameDetail && !gameDetail.existed){
      const requestBody = {
        appId,
        companyId:partner.partnerId,
        publisher:partner.name
      }
      try {
        const res = await axios.post(API.CREATE_GAME, requestBody);
        if(res.status === 200){
          const {gameId} = res.data;
          setLoading(false);
          message.success("New Game is created")
          history.push(`/brand_admin/games/gameDetail/${gameId}`)
        }else {
          setLoading(false);
          message.error("Failed to add new game, please try again")
        }
      }catch(err){
        setLoading(false);
        message.error("Failed to add new game, please try again")
      }
    }
  }

  return <>
   <Top>
          <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
            history.push("/brand_admin/games")
          }}/>
         ADD YOUR GAME BY STEAM APP ID
      </Top>
      <Container>
        <div style={{display:'flex', justifyContent:'space-between'}}>
        <Input value={appId} onChange={(e) => {
            setAppId(e.target.value)
          }}
          placeholder='enter steam app id'
          />
          <SecondaryButton onClick={() => {
            searchGame()
          }}>
            Search For Game
          </SecondaryButton>
        </div>
        {
          gameDetail && <div style={{display:'flex', marginTop:24, alignContent:'center'}}>
            <Name>
             Game Name: <span style={{color:'#F72375'}}>
             {gameDetail.name}
              </span>
            </Name>
            <Name style={{marginLeft:24}}>
              Status: <span style={{color:'#F72375'}}>
              {
                gameDetail.existed? 'Already Stored In the Database' : 'Available to Add'
              }
              </span>
            </Name>
            {
             !gameDetail.existed &&  <PrimaryButton style={{marginLeft:'auto'}}
             onClick={() => {
              if(!loading){
                setLoading(true)
                debounceCreatNewGame()
              }
             }}
             disabled={loading}
             >
             Confirm and Add Game
           </PrimaryButton>
            }
          </div>
        }
      </Container>
  </>
}

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
}
&:focus {
  color: #FFFFFF !important;
  background: #F72375;
}
`;

const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
margin-bottom:16px;
`;

const Container = styled.div`
border-radius: 10px;
width:800px;
padding:24px;
background: #FFF;

.ant-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover,
  .ant-input-number
  {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
    margin-right:24px;
  }
`;

const Name = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
color: #000000;
cursor:pointer;
`;

export default withRouter(CreateGameByAppId);