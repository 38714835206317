import React, {useState, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import ReactPlayer from "react-player";
import countryList from 'react-select-country-list';
import Firebase from "../../util/firebase";
import {Rate} from 'antd';
import { Mixpanel } from '../../util/mixpanel';

const InfluencerRedeemDetail = ({current, back, game, partner}) => {
  const [influencer, setInfluencer] = useState(null);

  useEffect(() => {
    let listener = null;
    const fetchInfluencer = (current) => {
      listener = Firebase.firestore()
            .collection("influencer")
            .doc(current)
            .onSnapshot((doc) => {
              const obj = {
                ...doc.data(),
               influencerId:doc.id
              };
             setInfluencer(obj)
        });
    }
    fetchInfluencer(current);
    return () => {
      if(listener){
        listener();
      }
    }
  }, [current]);

  useEffect(() => {
    if(partner){
      Mixpanel.track("[Publisher-Portal] View Redeemed Codes Page", {
        partnerId:partner.partnerId,
        partnerName:partner.name
      })
    }
  }, [partner])

  
  const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId, twitchUrl, contactEmail} = influencer??{};
  const avg_viewers = metricsData?.avg_viewers??"-";
  const followers = metricsData?.followers??"-";
  const max_viewers = metricsData?.max_viewers??"-";
  const views_total = metricsData?.views_total??"-";

  const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
  const pricePerStream = pricePerQuest ? pricePerQuest:estimatedPrice;

  const rating = useMemo(() => {
    const {rating} = game;
    const result = rating.find(item => item.influencerId === current);
    if(result){
      return result
    }
    return null;
  }, [game, current]);

  return <Container>
    {
      influencer && <>
      <Title>
      <ArrowLeftOutlined onClick={back}/> Influencer Details
      </Title>
      <TopContainer>
        <ProfileImage src={profile_image_url} />
          <div style={{display:'flex', justifyContent:'space-between', flexDirection:"column"}}>
            <Text>
            Verified
            </Text>
            <Name>
            {name}
            </Name>
            <Text>
            <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/>
            {followers}
            </Text>
          </div>
          <Name style={{marginLeft:'auto'}}>
          {
            contactEmail
          }
          </Name>
      </TopContainer>
      <Middle>
        <ReactPlayer
              url={twitchUrl}
              playing={false}
              width="589px"
              height="332px"
              controls
        />
        <Detail>
            <Box>
              <Content style={{fontSize:14}}>
                {location? countryList().getLabel(location):""}
              </Content>
              <ContentTitle>
                Location
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:20, height:20, marginRight:4}}/>
              {pricePerStream}
              </Content>
              <ContentTitle>
                Price Per Stream
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {followers}
              </Content>
              <ContentTitle>
             Followers <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {avg_viewers}
              </Content>
              <ContentTitle>
              Avg. Concurrent Viewers<img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {views_total}
              </Content>
              <ContentTitle>
               Total Views(30D)<img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {language}
              </Content>
              <ContentTitle>
               language
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              partner
              </Content>
              <ContentTitle>
               status <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {max_viewers}
              </Content>
              <ContentTitle>
               peak viewers <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
        </Detail>
      </Middle>
      {
        rating && <>
        <Title style={{marginTop:24}}>
      Ratings & Reviews:
      </Title>
      <RatingBox>
        <div style={{display:'flex'}}>
        <div style={{marginRight:24}}>
        <Rate disabled defaultValue={rating.rating.fun} />
        <RateText>
        Fun to play
        </RateText>
      </div>
      <div style={{marginRight:24}}>
        <Rate disabled defaultValue={rating.rating.easy} />
        <RateText>
        Easy to learn
        </RateText>
      </div>
      <div style={{marginRight:24}}>
        <Rate disabled defaultValue={rating.rating.refer} />
        <RateText>
        Likely to refer
        </RateText>
      </div>
        </div>
      {
         rating.feedBack && <RateText style={{fontSize:16}}>
         {rating.feedBack}
       </RateText>
        }
      </RatingBox>
        </>
      }
      </>
    }
      
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;
`;

const Title = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 29px;
color: #000000;
text-transform: uppercase;
`;

const TopContainer = styled.div`
width: 100%;
height: 85px;

background: #FFFFFF;
border-radius: 10px;
margin:24px 0px;
display:flex;
padding:10px 24px;
align-items:center;
`;

const ProfileImage = styled.img`
height:65px;
width:65x;
border-radius:10px;
margin-right:16px;
`;

const Text = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
text-transform: uppercase;
`;

const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const Middle = styled.div`
width:100%;
display:flex;
justify-content:space-between;
`;

const Detail = styled.div`
display:grid;
grid-template-columns: repeat(3, 152px);
grid-row-gap:32px;
grid-column-gap:16px;
`;

const Box = styled.div`
width: 152px;
height: 90px;
background: #FFFFFF;
border-radius: 10px;
display:flex;
flex-direction:column;
justify-content:space-between;
padding:12px;
`;

const Content = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
text-transform: uppercase;
`;

const ContentTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
leading-trim: both;
text-edge: cap;
text-transform: uppercase;
`;

const RatingBox = styled.div`
width: 589px;
height: auto;
border-radius: 10px;
background: #FFF;
padding:16px;
margin:24px 0 48px;
`;

const RateText = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top:12px;
`;

export default InfluencerRedeemDetail;