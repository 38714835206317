import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Table
} from 'antd';
import moment from "moment";
import { proposalStatus, getProposalStatus } from '../createCampaign/option';
import Modal from "react-modal";
import * as API from "../../util/api";
import axios from "axios";
import { debounce } from "lodash";
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore

const CampaignDetailBeforeLaunch = ({campaign, influencers, proposals, viewInfluencerDetail, lanuchcampaign, history,}) => {

  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentProposal, setCurrentProposal] = useState(null);

  const allocatedBudget = useMemo(() => {
    if(influencers && campaign){
      const startDate = campaign?.startDate?? "";
    const endDate = campaign?.endDate??"";
    const weeks = Math.round(moment(endDate).diff(moment(startDate), 'weeks', true));
    const budgets = influencers.reduce((acc, curr) => {
        const priceInCredit =curr.pricePerQuest*weeks;
        return acc + priceInCredit;
    }, 0);
    return Math.ceil(budgets);
    }else {
      return 0;
    }
  }, [influencers, campaign]);

  const remainingBudget = useMemo(() => {
    if(campaign){
      return (campaign?.budget??0) - (allocatedBudget??0); 
    }else {
      return 0;
    }
  }, [campaign, allocatedBudget]);

  const totalViews = useMemo(() => {
    const views = influencers.reduce((acc, curr) =>{
      return acc + (curr?.metricsData?.views_total??0);
    }, 0);
    return views;
  }, [influencers]);

  const totalFollowers =  useMemo(() => {
    const followers = influencers.reduce((acc, curr) =>{
      return acc + curr?.metricsData?.followers?? 0;
    }, 0);
    return followers;
  }, [influencers]);

  const totalCCV = useMemo(() => {
    const ccvs = influencers.reduce((acc, curr) =>{
      return acc + curr?.metricsData?.avg_viewers?? 0;
    }, 0);
    return ccvs;
  }, [influencers]);

  const rejectedProposal = useMemo(() => {
    const viewed = proposals.filter(item => item.status === proposalStatus.REJECTED);
    return viewed.length;
  }, [proposals]);




  const acceptedProposal = useMemo(() => {
    const accepted = proposals.filter(item => item.status === proposalStatus.ACCEPTED || item.status === proposalStatus.ACTIVE || item.status === proposalStatus.COMPLETED || item.status === proposalStatus.SUBMITTED) ;
    return accepted.length;
  }, [proposals]);

  const cancelledProposal =  useMemo(() => {
    const cancelled = proposals.filter(item => item.status === proposalStatus.CANCELLED);
    return cancelled.length;
  }, [proposals]);

  const estQuests = useMemo(() => {
    if(campaign && influencers){
    const startDate = campaign?.startDate?? "";
    const endDate = campaign?.endDate??"";
    const weeks = Math.round(moment(endDate).diff(moment(startDate), 'weeks', true));
    const quests = influencers.length * weeks;
    return quests;
    }
    return 0;
  }, [campaign, influencers]);


  const showList = useMemo(() => {
    let arr = [];
    proposals.forEach((item) => {
      const influencer = influencers.find(i => i.influencerId === item.influencerId);
      const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId} = influencer;
      const avg_viewers = metricsData?.avg_viewers??"-";
      const followers = metricsData?.followers??"-";
      const priceInCredit = (pricePerQuest*4)*10;
      const influencerData = {
        location,
        priceInCredit: pricePerQuest? priceInCredit:0,
        pricePerQuest,
        description,
        name,
        avg_viewers,
        followers,
        profile_image_url,
        categories: item.categories??[],
        language,
        influencerId,
      }
      const obj = {
        influencerData,
        ...item
      }
      arr.push(obj)
    })
    return arr;
  }, [proposals, influencers])


  const columns = [
    {
      title:"Influencers",
      key:"description",
      render: (record) => {
        const influencerData = record.influencerData;
        return <ProfileContainer>
          <ProfileImage src={influencerData.profile_image_url} alt="logo"/>
          <div>
            <ProfileName onClick={() => {
              viewInfluencerDetail(influencerData.influencerId)
            }}>
                {influencerData.name}
            </ProfileName>
           <div style={{margin:"4px 0px", display:'flex', flexWrap:'wrap'}}>
              {
                influencerData.categories.map((item) => <ProfileTag>
                  {item}
                </ProfileTag>)
              }
           </div>
            <ProfileDesc>
                {influencerData.description}
            </ProfileDesc>
          </div>
        </ProfileContainer>
      }
    },
    {
      title:"Followers",
      key:"followers",
      render:(record) => {
        return <Followers>
          <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/>
          <span style={{display:'inline-block'}}>{record.influencerData.followers}</span>
        </Followers>
      }
    },
    {
      title:"Average Concurrent Viewers",
      key:"averageViwers",
      render:(record) => {
        return <Followers>
          <span style={{display:'inline-block'}}>{record.influencerData.avg_viewers}</span>
        </Followers>
      }
    },
    {
      title:"Status",
      key:"status",
      render:(record) => {
        const status = getProposalStatus(record.status);
        return <Status status={status}>
          {status}
        </Status>
      }
    },
/*     {
      title:"Reviews",
      key:'reviews',
      render: () => {
        return <div>-</div>
      }
    }, */
    {
      title:"Price(Monthly)",
      key:'price',
      render:(record) => {
        return <Followers>
          <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
          {record.influencerData.priceInCredit?? 0}
        </Followers>
      }
    },
    {
      title:"",
      key:"operation",
      render: (record) => {
        const status = getProposalStatus(record.status);
        if(status === 'RECEIVED'){
          return <img src="/assets/delete_proposal.svg" alt="twitch" style={{width:40, height:40}}
          onClick={() => {
            setCurrentProposal(record);
            setCancelModalOpen(true);
          }}
          />
        }
      }
    },

  ];

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: 600,
      height:'auto',
      padding:'24px',
      background: "#FFFFFF",
      borderRadius: "10px",
      zIndex: "10000",
    },
  };

  const debounceRemoveProposal = debounce(() => {
    cancelProposal()
  }, 500);

  const cancelProposal = async () => {
    try {
      const requestBody = {
        campaignId:campaign.campaignId,
        proposalId:currentProposal.proposalId
      }
      const url = API.CAMPAIGN_CANCEL_PROPOSAL;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setCancelModalOpen(false);
        setCurrentProposal(null);
        message.success("Proposal is cancelled")
      }else {
        setCancelModalOpen(false);
        setCurrentProposal(null);
        message.success("Failed to cancel Proposal, please try again")
      }
    }catch(err){
      setCancelModalOpen(false);
      setCurrentProposal(null);
      message.success("Failed to cancel Proposal, please try again")
    }
  }


  return <Container>
        <Top>
          <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
            history.push("/brand_admin/campaigns")
          }}/>
          campaign details
      </Top>
        <TopContainer>
              <Title>
              {campaign?.name??""}
              </Title>
              <Operations>
                <PrimaryButton onClick={() => {
                  history.push(`/create_campaign?type=edit&campaignId=${campaign.campaignId}`)
                }}>
                  Add Influencers <PlusOutlined />
                </PrimaryButton>
                {/* <PrimaryButton onClick={() => {
                  lanuchcampaign()
                }}>
                  Review & Launch
                </PrimaryButton> */}
              </Operations>
          </TopContainer>
          <Credits>
          <Credit style={{borderRight:"1px solid #A8A8A8"}}>
            <span style={{display:'inline-block'}}>
              Allocated Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {allocatedBudget}
              </span>
            </div>
          </Credit>
          <Credit>
          <span style={{display:'inline-block', marginLeft:8}}>
          Est. Remaining of Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {remainingBudget}
              </span>
            </div>
          </Credit>
        </Credits>
        <Details>
          <DetailLeft>
              <LeftBox>
                  <BoxContent>
                    {totalViews}
                  </BoxContent>
                  <BoxTitle>
                  est. Total Views
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                 <BoxContent>
                    {totalFollowers}
                  </BoxContent>
                  <BoxTitle>
                  Total Followers
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                <BoxContent>
                    {totalCCV}
                  </BoxContent>
                  <BoxTitle>
                  total avg CCV
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                <BoxContent>
                {moment(campaign?.startDate??"").format("MMM Do, YY")}
                  </BoxContent>
                  <BoxTitle>
                  start Date
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                <BoxContent>
                  {moment(campaign?.endDate??"").format("MMM Do, YY")}
                  </BoxContent>
                  <BoxTitle>
                  end date
                  </BoxTitle>
              </LeftBox>
              <LeftBox> 
                <BoxContent>
                  {Math.round(moment(campaign?.startDate??"").diff(moment(), 'days', true))}
                  </BoxContent>
                  <BoxTitle>
                  Days to launch
                  </BoxTitle>
              </LeftBox>
          </DetailLeft>
          <DetailRight>
              <RightBox>
                <BoxContent>
                 {proposals.length}
                  </BoxContent>
                  <BoxTitle>
                 Proposal sent
                  </BoxTitle>
              </RightBox>
              <RightBox>
               <BoxContent>
                  {rejectedProposal}
                  </BoxContent>
                  <BoxTitle>
                 Proposal Rejected
                  </BoxTitle>
              </RightBox>
              <RightBox>
                <BoxContent>
                  {acceptedProposal}
                  </BoxContent>
                  <BoxTitle>
                 Proposal Accepted
                  </BoxTitle>
              </RightBox>
              <RightBox>
                <BoxContent>
                  {cancelledProposal}
                  </BoxContent>
                  <BoxTitle>
                 Proposal CANCELLED
                  </BoxTitle>
              </RightBox>
              <RightBox>
                 <BoxContent>
                  {(allocatedBudget/campaign.budget)*100} %
                  </BoxContent>
                  <BoxTitle>
                  Budget allocated
                  </BoxTitle>
              </RightBox>
              <RightBox>
              <BoxContent>
                  {estQuests}
                  </BoxContent>
                  <BoxTitle>
                  EST.STREAM
                  </BoxTitle>
              </RightBox>
          </DetailRight>
        </Details>

        <Table columns={columns} dataSource={showList} />
        {
          cancelModalOpen && <Modal isOpen={cancelModalOpen}
          style={customStyles}
          onRequestClose={() => {
            setCancelModalOpen(false);
            setCurrentProposal(null)
          }}
          ariaHideApp={false}>
            <Top>
            <ArrowLeftOutlined style={{marginRight:8}}/>
            Remove Proposals
          </Top>
          <Text>
          Upon confirming, proposal with {currentProposal?.influencerData?.name} will be cancelled. All Schedules of this proposal will be removed.
          </Text>
          <SecondaryButton onClick={()=> {
            if(!loading){
              setLoading(true);
              debounceRemoveProposal()
            }
          }}
          disabled={loading}
          >
            {loading? 'Loading...':'Send'}
          </SecondaryButton>
          </Modal>
        }
    </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const TopContainer = styled.div`
display:flex;
margin:24px 0px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
  border:none;
}
`;

const Operations = styled.div`
display:flex;
margin-left:auto;
`;


const Credits = styled.div`
display:flex;
width: 576px;
height: 41px;
background: #FFFFFF;
border-radius: 7px;

font-weight: 600;
font-size: 14px;
color: #000000;
padding:8px;
`;

const Credit = styled.div`
display:flex;
width:50%;
justify-content:space-between;
align-items:center;
`;

const Details = styled.div`
margin:24px 0px;
display:flex;
width:100%;
justify-content:space-between;
`;

const DetailLeft = styled.div`
display:grid;
grid-template-columns: repeat(3, 180px);
grid-row-gap:16px;
grid-column-gap:16px;
`;

const DetailRight = styled.div`
display:grid;
grid-template-columns: repeat(3, 160px);
grid-row-gap:16px;
grid-column-gap:16px;
`;

const RightBox = styled.div`
width: 160px;
height: 76px;

background: #FFFFFF;
border-radius: 10px;
padding:12px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const LeftBox = styled.div`
width: 180px;
height: 76px;

background: #FFFFFF;
border-radius: 10px;
padding:12px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const BoxContent = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
text-transform: uppercase;
color: #000000;
`;

const BoxTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
leading-trim: both;
text-edge: cap;
text-transform: uppercase;

color: #000000
`;

const ProfileContainer = styled.div`
width:232px;
height:auto;
display:flex;
`;

const ProfileImage = styled.img`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

const Status = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
${p => p.status === 'RECEIVED' && `color:#7B7B7B;`};
${p => (p.status === 'ACCEPTED' || p.status === 'ACTIVE' || p.status === 'COMPLETED' || p.status === 'SUBMITTED') && `color:#007A0C;`};
${p => (p.status === 'REJECTED' || p.status==='CANCELLED') && `color:#FF0000;`};
`;


const Text = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #000000;
margin:24px 0px;
`;

const SecondaryButton = styled(Button)`
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

export default withRouter(CampaignDetailBeforeLaunch);