import React, { useState, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from "moment";
import { proposalStatus } from '../createCampaign/option';
import ReactPlayer from "react-player";
import countryList from 'react-select-country-list';
import Firebase from "../../util/firebase";
import InfluencerProposalDetail from './influencerProposalDetail';
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore

const InfluencerDetailWithProposal = ({currentRecord, backToCampaignDetail}) => {
  const {currentInfluencer, currentProposal} = currentRecord;
  const [view, setView] = useState(1);
  const [quests, setQuests] = useState([]);
  const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId, twitchUrl} = currentInfluencer;
  const avg_viewers = metricsData?.avg_viewers??"-";
  const followers = metricsData?.followers??"-";
  const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
  const pricePerStream = pricePerQuest ? pricePerQuest:estimatedPrice;
  const max_viewers = metricsData?.max_viewers??"-";
  const views_total = metricsData?.views_total??"-";


  useEffect(() => {
    const fetchQuests = async (questsList) => {
      try {
        const result = await Promise.all(questsList.map(async (questId) => {
          const questRef = Firebase.firestore().collection("creator_quest").doc(questId);
          const questDoc = await questRef.get();
          const questData = questDoc.data();
          return {
            questId,
            ...questData
          }
        }));
        setQuests(result)
      }catch(err){
        console.log(err);
      }

    }
    if(currentProposal.status === proposalStatus.ACTIVE || currentProposal.status === proposalStatus.COMPLETED || currentProposal.status === proposalStatus.SUBMITTED){
      const {questsList} = currentProposal;
      console.log()
      fetchQuests(questsList);
    }
  }, [currentProposal])

  const questsRun = useMemo(() => {
    const completedQuests = quests.filter(item => item.status === 3);
    return completedQuests.length;
    }, [quests])


  const getProposalStatus = () => {
    if(currentProposal){
      const proposal = currentProposal;
      if(proposal.status === proposalStatus.RECEIVED){
        return 'RECEIVED'
      }else if(proposal.status === proposalStatus.ACCEPTED){
        return 'ACCEPTED'
      }else if(proposal.status === proposalStatus.REJECTED){
        return 'REJECTED'
      }else if(proposal.status === proposalStatus.ACTIVE){
        return 'ACTIVE'
      }else if(proposal.status === proposalStatus.COMPLETED){
        return 'COMPLETED'
      }else if(proposal.status === proposalStatus.CANCELLED){
        return 'CANCELLED'
      }else if(proposal.status === proposalStatus.SUBMITTED){
        return 'COMPLETED'
      }
    }
  }

  const detailView = useMemo(() => {
    if(currentProposal.status === proposalStatus.ACCEPTED || currentProposal.status === proposalStatus.ACTIVE || currentProposal.status === proposalStatus.COMPLETED || currentProposal.status === proposalStatus.SUBMITTED){
      return true;
    }
    return false;
  }, [currentProposal])
  return <Container>
       <Top>
        <ArrowLeftOutlined style={{marginRight:8}} onClick={backToCampaignDetail}/>
          Influencer details
        </Top>
        <StatusBox>
         <ProfileImage src={profile_image_url} />
          <div style={{display:'flex', justifyContent:'space-between', flexDirection:"column"}}>
            <Text>
            Verified
            </Text>
            <Name>
            {name}
            </Name>
            <Text>
            <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/>
            {followers}
            </Text>
          </div>
          <div style={{marginLeft:'auto', marginRight:16}}>
            <div style={{display:'flex', justifyContent:'space-between', flexDirection:"column"}}>
              <Status status={getProposalStatus()}>
                {getProposalStatus()}
              </Status>
              <Text>Status</Text>
            </div>
          </div>
          <img src="/assets/added_to_campaign.svg"  alt="fortnite" style={{
            width:32,
            height:32,
      }}
          />
        </StatusBox>
        <Choices >
          <Choice active={view === 1}  onClick={() => {
              setView(1)
            }}>
               Overview
          </Choice>
          {
            detailView &&  <Choice active={view === 2}  onClick={() => {
              setView(2)
            }}>
               Campaign Details
          </Choice>
          }
          </Choices>
          {
            view === 1 && <InfluencerContent>
                <ReactPlayer
              url={twitchUrl}
              playing={false}
              width="589px"
              height="332px"
              controls
        />
        <Detail>
            <Box>
              <Content style={{fontSize:14}}>
                {countryList().getLabel(location)?? "-"}
              </Content>
              <ContentTitle>
                Location
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:20, height:20, marginRight:4}}/>
              {pricePerStream}
              </Content>
              <ContentTitle>
                Price Per Steam
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {questsRun}
              </Content>
              <ContentTitle>
               Quests Run
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {followers}
              </Content>
              <ContentTitle>
             Followers <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {avg_viewers}
              </Content>
              <ContentTitle>
              Avg. Concurrent Viewers<img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {views_total}
              </Content>
              <ContentTitle>
               Total Views(30D)<img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {language}
              </Content>
              <ContentTitle>
               language
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              partner
              </Content>
              <ContentTitle>
               status <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
            <Box>
              <Content>
              {max_viewers}
              </Content>
              <ContentTitle>
               peak viewers <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginLeft:4}}/>
              </ContentTitle>
            </Box>
        </Detail>
            </InfluencerContent>
          }
          {
            view === 2 && <InfluencerProposalDetail proposal={currentProposal} quests={quests} isRejected={false}/>
          }
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const StatusBox = styled.div`
width:100%;
height:85px;
background: #FFFFFF;
border-radius: 10px;
margin:24px 0px;
padding:8px 12px;
display:flex;
align-items:center;
`;

const ProfileImage = styled.img`
height:65px;
width:65x;
border-radius:10px;
margin-right:16px;
`;

const Text = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 14px;
text-transform: uppercase;
`;

const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const Status = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
${p => p.status === 'DELIVERED' && `color:#7B7B7B;`};
${p => p.status === 'VIEWED' && `color:#000000;`};
${p => p.status === 'ACCEPTED' && `color:#007A0C;`};
${p => p.status === 'REJECTED' && `color:#FF0000;`};
`;

const Choices = styled.div`
width:100%;
margin:12px 0px 24px;
display:flex;
height:24px;
border-bottom:2px solid #A8A8A8;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
/* UI Properties */
letter-spacing: 0px;
text-align:center;
font-weight: 700;
font-size: 16px;
height:24px;
line-height:24px;

text-transform: capitalize;

color: #7B7B7B;
cursor:pointer;
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:2px solid #F72375;`
        : ``}
`;

const InfluencerContent = styled.div`
width:100%;
display:flex;
justify-content:space-between;
`;

const Detail = styled.div`
display:grid;
grid-template-columns: repeat(3, 152px);
grid-row-gap:32px;
grid-column-gap:16px;
`;

const Box = styled.div`
width: 152px;
height: 90px;
background: #FFFFFF;
border-radius: 10px;
display:flex;
flex-direction:column;
justify-content:space-between;
padding:12px;
`;

const Content = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
text-transform: uppercase;
`;

const ContentTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
leading-trim: both;
text-edge: cap;
text-transform: uppercase;
`;

const DetailContent = styled.div`
display:flex;
flex-direction:column;
`;


export default withRouter(InfluencerDetailWithProposal);