import React, {useState, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import {Table, Rate, Button} from 'antd';
import moment from 'moment'
import InfluencerRedeemDetail from './influencerRedeemDetail';

const ManageCode = ({game, partner}) => {

  const [view, setView] = useState('record');
  const [current, setCurrent] = useState(null);

  const redeemedCodeCount = useMemo(() => {
    return (game.redeemedCodes??[]).length;
  },[game])

  const availableCount = useMemo(() => {
    return (game.availableCodes??[]).length;
  },[game]);

  const viewInfluencerDetail = (influencerId) => {
    setCurrent(influencerId);
    setView("detail")
  }

  const getRating = (record) => {
    const influencerId = record.influencerId;
    const result = game.rating.find(item => item.influencerId === influencerId);
    if(result  && result.rating){
      const rating = (result.rating.easy + result.rating.fun + result.rating.refer)/3;
      return rating;
    }else {
      return 0;
    }
  }

  const columns = [
   
    {
      title:"Influencer",
      key:"name",
      render:(record) => {
        return <Name>
         {record.influencerName}
        </Name>
      }
    },

    {
      title:"Date Redeemed",
      key:'description',
      render:(record) => {
        return <Name>
          {moment(record.date).format("L")}
        </Name>
      }
    },
    {
      title:"Reviews",
      render:(record) => {
        const rating = getRating(record)
        return  <>
        {
          rating !==0 ?  <Rate disabled defaultValue={rating} allowHalf /> :"Not Submitted Yet"
        }
        </>
      }
    },
    {
      title:"Codes",
      render:(record) => {
        return  <Name>
        {record.code}
        </Name>
      }
    },
    {
      title:"",
      key:"operation",
      render: (record) => {
        return <PrimaryButton style={{width:130}} onClick={() => {
          console.log(record.influencerId)
          viewInfluencerDetail(record.influencerId)
        }}>
          View
        </PrimaryButton>
      }
    },
  ]


  return <Container>
    {
      view === 'record' && <>
      <div style={{margin:'24px 0px', display:'flex'}}>
      <Box>
        <BoxContent>
          {redeemedCodeCount}
        </BoxContent>
        <BoxTitle>
        Redeemed Codes
        </BoxTitle>
      </Box>
      <Box>
        <BoxContent>
          {availableCount}
        </BoxContent>
        <BoxTitle>
        Available Codes
        </BoxTitle>
      </Box>
      <Box>
        <BoxContent>
          {availableCount + redeemedCodeCount}
        </BoxContent>
        <BoxTitle>
        Total Codes
        </BoxTitle>
      </Box>
    </div>
    <Table columns={columns} dataSource={game.redeemHistory} />
      </>
    }
    {
      view === 'detail' && <InfluencerRedeemDetail game={game} current={current}
      partner={partner}
      back={() => {
        setView("record");
        setCurrent(null);
      }}
      />
    }
    
  </Container>
}

const Container = styled.div`
width:100%;

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
`;

const Box = styled.div`
width: 152px;
height: 80px;
padding:12px;
margin-right:24px;
display:flex;
flex-direction:column;
justify-content:space-between;
border-radius: 10px;
background: #FFF;
`;

const BoxContent = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
`;

const BoxTitle = styled.div`
color: #000;
leading-trim: both;
text-edge: cap;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-transform: uppercase;
`;

const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #000000;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
&:focus {
  color: #FFFFFF !important;
  background: #F72375;
  box-shadow:none;
}
`;


export default ManageCode;