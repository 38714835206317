import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from "moment";
import { Carousel, Divider, Button } from 'antd';
import ReactPlayer from "react-player";
import EditGameModal from './editGameModal';

const GameOverview = ({game}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const isRichText = (text) => {
    const htmlTagsRegex = /<\/?[a-z][\s\S]*>/i;
    return htmlTagsRegex.test(text);
  }

  return <Container>
    <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center', marginBottom:24}}>
      <div style={{width:566}}>
      <ReactPlayer
              url={game?.video??""}
              playing={false}
              width="100%"
              controls
            />
      </div>
      <div style={{width:566,padding:24}}>
      <Carousel autoplay>
      {
        (game?.productImages??[]).map((item) => {
          return <ProductImage src={item} />
        })
      }
    </Carousel>
      </div>
    </div>
    <Divider />
    <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginBottom:48}}>
      <div style={{width:808}}>
        <Title>
        Short description
        </Title>
        <Text>
        {game.short_description}
        </Text>
        <Title>
        Detailed description
        </Title>
        {
          isRichText(game.long_description) ? <div dangerouslySetInnerHTML={{ __html: game?.long_description }}/> : <Text>
        {game.long_description}
        </Text>
        }
      </div>
      <div style={{width:302}}>
          <PrimaryButton style={{width:302, marginBottom:24}} onClick={() => {
            setModalOpen(true);
          }}>
            Edit
          </PrimaryButton>
          <Detail>
            <DetailTitle>
            Date game created
            </DetailTitle>
            <DetailContent>
            {game.createdDate? moment(game.createdDate).format("L"): "-"}
            </DetailContent>
          </Detail>
          <Detail>
            <DetailTitle>
            Genre
            </DetailTitle>
            <DetailContent>
              {game.genre?? "-"}
            </DetailContent>
          </Detail>
          <Detail>
            <DetailTitle>
            Category
            </DetailTitle>
            <DetailContent>
              {game.categories?? "-"}
            </DetailContent>
          </Detail>
          <Detail>
            <DetailTitle>
            Price
            </DetailTitle>
            <DetailContent>
              {game.regular_price?? "-"}
            </DetailContent>
          </Detail>
          <Detail>
            <DetailTitle>
            Status
            </DetailTitle>
            <DetailContent>
              {game.status === 1? "Published":'Private'}
            </DetailContent>
          </Detail>
      </div>
    </div>
    <EditGameModal game={game} open={modalOpen} closeModal={() => {
      setModalOpen(false)
    }} />
  </Container>
}

const Container = styled.div`
width:100%;
`;

const ProductImage = styled.img`
width:100%;
`;

const Title = styled.div`
color: #363636;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const Text = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom:16px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
&:focus {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
`;

const Detail = styled.div`
width:100%;
display:flex;
justify-content:space-between;
margin-bottom:12px;
`;

const DetailTitle = styled.div`
color: #363636;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
`;

const DetailContent = styled.div`
color: #A8A8A8;
text-align: right;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
`;

export default GameOverview;