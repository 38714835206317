import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Form,
  Input,
  message,
  Upload
} from 'antd';
import {categoriesOption} from "../createCampaign/option";
import Select from 'react-select';
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore
// Import other Firebase services as needed
import Firebase from "../../util/firebase";
import * as API from "../../util/api";
import axios from "axios";
import { UploadOutlined} from '@ant-design/icons';

// Daniel messing with Supabase login

import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'

const supabase = createClient(
  'https://yeyotfuxvnzyzelrakwi.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlleW90ZnV4dm56eXplbHJha3dpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYyNDI1MzEsImV4cCI6MjAwMTgxODUzMX0.KiFzhtS6VPBlQX9MnkE8aSQdvwcryh4ipJPV5DyXk2g'
  )

const App = () => <Auth supabaseClient={supabase} />

const signInWithGoogle = async () => {
  try {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });
    if (error) throw error;
    // Redirect or handle the user after successful sign-in
  } catch (error) {
    console.error('Error signing in with Google:', error.message);
  }
};


// 

const { TextArea } = Input;

const BrandProfile = ({partner}) => {
  const [form] = Form.useForm();

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width:"autos",
      height: "auto",
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000",
      borderRadius: "5px",
      boxShadow: "none",
      '&:hover': {
        border: '1px solid #000000',
     }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
      };
    },
  };
  const onFinish = async (values) => {
    try {
      let updatedData = values;
      if(values.category){
        const cate = values.category.value;
        delete updatedData.category;
        updatedData.category = cate;
      }
      const requestBody = {
        updatedData,
        partnerId:partner.partnerId
      }
      const endPoint = API.EDIT_PARTNER;
      const res = await axios.post(endPoint, requestBody);
      if(res.status === 200){
        message.success("Your Profile is Updated");
      }else {
        message.error("Failed to Update Your Profile, Please Try Again")
      }
    }catch(err){
      message.error("Failed to Update Your Profile, Please Try Again")
    }
  }

  const customRequestBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              bannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      console.log("done");
    }
    if (info.file.status === "error") {
      console.log("upload error");
    }
  };

  return <>
    <Top>
    Your Brand Profile
      </Top>
      <Container>
      <Form
      name="validate_other"
      onFinish={onFinish}
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
      initialValues={{
        name:partner?.name??"",
        link:partner?.link??"",
        info:partner?.info??"",
        logo:partner?.logo??"",
        category: partner?.category? {
          label:partner.category,
          value:partner.category
        }: undefined
      }
      }
    >
      <Form.Item
        name="name"
        label="Company Name"
        labelAlign='Right'
        rules={[{ required: true,  message: "this field is required" }]}
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
          name="logo"
          label="Logo"
          labelAlign='Right'
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestBannerUrl}
          showUploadList={false}>
            <UploadButton icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</UploadButton>
        </Upload>

      <Form.Item
        name="link"
        label="Website"
        labelAlign='Right'
      >
         <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="category"
        label="Category"
      >
        <Select options={categoriesOption} 
                isClearable={true}
                styles={colourStyles}
                placeholder="Categories"
                />
      </Form.Item>  


      <Form.Item
        name="info"
        label="Description"
      >
        <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          span: 12
        }}
        style={{marginTop:32}}
      >
        <PrimaryButton type="primary" htmlType="submit" >
          Save
        </PrimaryButton>
        {/* <PrimaryButton type="primary" htmlType="submit" onClick={signInWithGoogle} >
          Link Account
        </PrimaryButton> */}
      </Form.Item>
      </Form>
      </Container>
  </>
}

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const Container = styled.div`
width: 432px;
height: 548px;
margin-top:24px;

background: #FFFFFF;
border-radius: 10px;
padding:24px;

.ant-form-item-label > label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height:16px;
}
  .ant-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
  }
  .ant-form-item {
    margin-bottom:8px;
  }

`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375 !important;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
border:none !important;
color: #FFFFFF !important;
`;

const UploadButton = styled(Button)`
&:hover {
  background: #FFFFFF !important;
  color:#000000;
  border: 1px solid #000000;
}
&:focus {
  background: #FFFFFF !important;
  color:#000000;
  border: 1px solid #000000;
}

border: 1px solid #000000;
`;

export default withRouter(BrandProfile)
