import React, { useState, useEffect, useMemo} from 'react';
import { withRouter } from 'react-router-dom';
import Firebase from "../util/firebase";
import Loading from "../util/loading";
import CampaignDetailBeforeLaunch from "./campaignComponents/campaignBeforeLaunch";
import CampaignDetailAfterStart from "./campaignComponents/campaignAfterStart";
import InfluencerDetailWithProposal from './campaignComponents/influencerDetailWithProposal';
import ReviewCampaign from "./campaignComponents/reviewCampaign";
import moment from 'moment';

const CampaignDetail = ({history, match}) => {
    const [campaign, setCampaign] = useState(null);
    const [influencers, setInfluencers] = useState([]);
    const [proposals, setProposals] = useState([]);
    const [loading, setLoading] = useState(true);
    // 1: campaign detail before launch
    // 2: campaign influencer detail page
    // 3: campaign launch review page
    const [view, setView] = useState(1);
    const [currentRecord, setCurrentRecord] = useState(null);

    const campaignStarted = useMemo(() => {
      if(campaign){
        const {startDate} = campaign;
        if(moment().isAfter(moment(startDate))){
          return true;
        }
      }
      return false;
    }, [campaign]);

    useEffect(() => {
      let listener = null;
      const fetchCampaign = async (campaignId) => {
        const campaignRef = Firebase.firestore().collection("campaign").doc(campaignId);
        try {
          const campaignDoc = await campaignRef.get();
          const campaignData = campaignDoc.data();
          setCampaign({
            ...campaignData,
            campaignId
          });
          const {proposalList, influencersList} = campaignData;
          await fetchProposals(campaignId);
          /* if(proposalList){
            await fetchProposals(proposalList);
          } */
          if(influencersList){
            await fetchInfluencers(influencersList);
          }
          setLoading(false);
        }catch(err){
          console.log(err);
        }

      };

      const fetchInfluencers = async (influencerList) => {
        try {
          const list = [];
          for(const influencer of influencerList){
            const influencerRef = Firebase.firestore().collection("influencer").doc(influencer); 
            const influencerDoc = await influencerRef.get();
            const influencerData = influencerDoc.data();
            const obj = {
              ...influencerData,
              influencerId:influencer
            }
            list.push(obj)
          }
          setInfluencers(list);
        }catch(err){
          console.log(err, 'fetch influencers error')
        }
      }

      const fetchProposals = async (campaignId) => {
        listener = Firebase.firestore()
          .collection("proposal")
          .where("campaignId", "==", campaignId)
          .onSnapshot(querySnapshot => {
            let arr = [];
            querySnapshot.forEach(function (doc) {
              const obj = {
                ...doc.data(),
               proposalId: doc.id,
              };
              arr.push(obj);
            });
            setProposals(arr);
          },
          error => {
            console.log(error);
          }
      )
        /* try {
          const list = [];
          for(const proposal of proposals){
            const proposalRef = Firebase.firestore().collection("proposal").doc(proposal); 
            const proposalDoc = await proposalRef.get();
            const proposalData = proposalDoc.data();
            const obj = {
              ...proposalData,
              proposalId:proposal
            }
            list.push(obj)
          }
          setProposals(list);
        }catch(err){
          console.log(err, 'fetch propsals error')
        } */

      }

      const campaignId = match.params.campaignId;

      const getData = async () => {
        try {
          await fetchCampaign(campaignId);
          setLoading(false);
        }catch(err){
          console.log(err, 'get data error')
        }
      }

      getData()
      return () => {
        if(listener){
          listener();
        }
      }
    },[match]);


    const viewInfluencerDetail = (influencerId) => {
      const currentInfluencer = influencers.find(item => item.influencerId === influencerId);
      const currentProposal = proposals.find((item) => item.influencerId === influencerId);
      setCurrentRecord({
        currentInfluencer,
        currentProposal
      })
      setView(2);
    }

   

    return <>
    {
      loading ? <div style={{marginTop:24}}>
        <Loading />
        </div> : <>
        {
          view === 1 && campaignStarted && <CampaignDetailAfterStart influencers={influencers} campaign={campaign} 
          proposals={proposals}
          viewInfluencerDetail={viewInfluencerDetail}/>
        }
        {
          view === 1 && !campaignStarted &&  <CampaignDetailBeforeLaunch influencers={influencers} campaign={campaign} 
          proposals={proposals}
          viewInfluencerDetail={viewInfluencerDetail}
          lanuchcampaign={() => {
            setView(3)
          }}
          />
        }
        {
          view === 2 && currentRecord && <InfluencerDetailWithProposal currentRecord={currentRecord}
          backToCampaignDetail={()=> {
            setView(1);
            setCurrentRecord(null)
          }}
          />
        }
        {
          view === 3 && <ReviewCampaign influencers={influencers} campaign={campaign} 
          proposals={proposals} 
          back={() => {
            setView(1);
          }}
          />
        }
        </>
    }
    </>

    
}



export default withRouter(CampaignDetail);