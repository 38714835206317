import React, {useState, useMemo, useEffect} from 'react';
import Firebase from "../../util/firebase";
import { withRouter } from 'react-router-dom';
import Loading from "../../util/loading";
import moment from 'moment';
import styled from 'styled-components';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  message,
} from 'antd';
import GameOverview from './gameOverview';
import ManageCode from './manageCode';
import AddCodeModal from './addCodeModal';
import AvailableCodes from './availableCode';
import * as API from "../../util/api";
import axios from "axios";

const GameDetail = ({partner, history, match}) => {
  const [game, setGame] = useState(null);
  const [view, setView] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  

  useEffect(() => {
    let listener = null;
    const gameId = match.params.gameId;

    const fetchGame = async (gameId) => {
      listener = Firebase.firestore()
            .collection("partnerGames")
            .doc(gameId)
            .onSnapshot((doc) => {
              const obj = {
                ...doc.data(),
               gameId:doc.id
              };
             setGame(obj)
        });
    }

    const getData = async () => {
      try {
        await fetchGame(gameId);
      }catch(err){
        console.log(err, 'get data error')
      }
    }
    getData()
    return () => {
      if(listener){
        listener();
      }
    }
  }, [match]);


const publishGame = async () => {
  const requestBody = {
    gameId:game.gameId
  }
  const url = API.PUBLISH_GAME;
  try {
    const res =await axios.post(url, requestBody);
    if(res.status === 200){
      message.success("Your game is published and available to influencers")
    }else {
      message.error("Failed to publish game, please try again")
    }
  }catch(err){
    message.error("Failed to publish game, please try again")
  }
} 

return <>
  {
  !game ? <div style={{marginTop:24}}>
    <Loading />
    </div> : <Container>
     <TopContainer>
              <Title>
              <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
            history.push("/brand_admin/games")
          }}/>
              {game?.name??""}
              </Title>
              <Operations>
                <PrimaryButton onClick={() => {
                  setModalOpen(true)
                }}>
                  Add Codes <PlusOutlined />
                </PrimaryButton>
                {
                  game.status === 0 && <SecondaryButton  onClick={() => {
                    publishGame()
           }}>
           Publish
           </SecondaryButton>
                }
              </Operations>
          </TopContainer>
          <Choices>
          <Choice active={view === 1}  onClick={() => {
              setView(1)
            }}>
              Overview
          </Choice>
           <Choice active={view === 2}  onClick={() => {
              setView(2)
            }}>
               Redeemed Codes
          </Choice>
          <Choice active={view === 3}  onClick={() => {
              setView(3)
            }}>
               Available Codes
          </Choice>
          </Choices>
          {
            view === 1  &&  <GameOverview game={game} />
          }
          {
            view === 2 && <ManageCode game={game} partner={partner} />
          }
          {
            view === 3 && <AvailableCodes game={game} />
          }
          <AddCodeModal game={game} closeModal={() => {
            setModalOpen(false)
          }} 
          open={modalOpen}
          />
    </Container>
  }
  </>
}

const TopContainer = styled.div`
display:flex;
margin:24px 0px 0px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
&:focus {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
`;

const Operations = styled.div`
display:flex;
margin-left:auto;
`;


const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
`;

const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

const Choices = styled.div`
width:100%;
margin:24px 0px 24px;
display:flex;
height:24px;
border-bottom:2px solid #A8A8A8;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
/* UI Properties */
letter-spacing: 0px;
text-align:center;
font-weight: 700;
font-size: 16px;
height:24px;
line-height:24px;

text-transform: capitalize;

color: #7B7B7B;
cursor:pointer;
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:2px solid #F72375;`
        : ``}
`;
export default withRouter(GameDetail)