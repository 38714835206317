import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import PublisherNavBar from "./nav";
import { message } from "antd";
import axios from "axios";
import * as API from "../util/api";
/* import PublisherNavBar from "../nav/publisherNavBar"; */
import { useJune } from '../util/useJune';


const PublisherEntry = ({history}) => {
  // June analytics code F6S
  const { loggedIn, profile, id: userId} = useSelector((state) => state.user);
  
  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "xq63n93f",
        name: profile?.name, // Full name
        email: profile?.email, // Email for your user
        created_at: profile?.createdAt // Signup date as a Unix timestamp
      });
    }
  }, [profile]);
  
  const isAdmin = useMemo(() => {
    const permission_level = profile?.permission_level??0;
    if(permission_level === 4 && profile.adminOfPartner){
      return true;
    }
    return false;
  }, [profile]);

  const role = useMemo(() => {
    if(profile){
      if(profile.role === 'influencer'){
        return 'influencer'
      }
      if(profile.role === 'publisher'){
        return 'publisher'
      }
    }
    return 'player'
  }, [profile]);

  const changeRole = async () => {
    if((profile.permission_level === 3 && profile.influencerId) || (profile.permission_level === 5 && profile.adminOfInfluencer)){
      message.error("You are linked to our influencer, please contact us to change the role");
    }else {
      try {
        const url = API.SAVE_USER_ROLE;
        const requestBody = {
          userId,
          role:"publisher"
        }
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          message.success("You have successfully switch to influencer account")
        }else {
          message.error("Failed to change to influencer account, please contact us")
        }
      }catch(err){
        message.error("Failed to change to influencer account, please contact us")
      }
    }
  }

  const redirectWithRole = () => {
    if(role === 'influencer'){
      window.location.replace("https://club.arcadequest.app/")
    }
    if(role === 'player'){
      window.location.replace("https://play.arcadequest.app/")
    }
  }

  return <Container>
  <PublisherNavBar activeTab="home" isDarkMode={true} />

  <Content>
    <Top>
      
      <TopTitle>
      Use Ai to Search Influencers
      </TopTitle>
      <TopTitle>
      Who Drive Sales
      </TopTitle>
      <Desc>
      Find out who drive game sales. Leverage our AI-powered platform to identify the influencers who have a proven track record of boosting game sales. With ArcadeQuest, you can easily search and filter through a curated list of influencers based on the specific games they've successfully promoted.
      </Desc>
    <div style={{display:'flex', marginBottom:48}}>
      { 
        // Changed from isAdmin to loggedIn so that they can redirect if they're logged in
        loggedIn ? <PrimaryButton style={{marginRight:24}} onClick={() => {
          history.push("/brand_admin/search")
        }}>
        Dashboard
      </PrimaryButton> : <SecondaryButton onClick={() => {
        history.push("/login");
      }}>
        Get Access
      </SecondaryButton>
    }
    </div>
    <div style={{display:'flex'}}>
      <GameImage src="/assets/indexPage/steam.png" alt="game"/>
      <GameImage src="/assets/indexPage/twitch.png" alt="game"/>
      <GameImage src="/assets/indexPage/xbox.png" alt="game"/>
    </div>
    </Top>
    <TopTitle style={{marginLeft:24}}>
    What’s next?
    </TopTitle>
    <ContentBox>
      <div style={{width:326}}>
        <ContentTitle>
        expand your reach
        </ContentTitle>
        <ContentText>
        Focus your efforts on influencers who are proven to drive sales. Partner with the right voices to amplify your game's presence in the market.
        </ContentText>
      </div>
      <div style={{width:326}}>
        <ContentTitle>
        Save Time
        </ContentTitle>
        <ContentText>
        Streamline your search for the perfect match. Quickly identify influencers whose audiences resonate with your game, ensuring a more targeted approach.
        </ContentText>
      </div>
      <div style={{width:326}}>
        <ContentTitle>
        Increase ROI
        </ContentTitle>
        <ContentText>
        Invest wisely in influencers who deliver results. Minimize wasted spend and maximize returns, achieving a higher overall return on investment.
        </ContentText>
      </div>
    </ContentBox>
    </Content>
    {
      loggedIn && role !== 'publisher' && <Box>
        <BoxTitle>
        Confirm your role
        </BoxTitle>
        <BoxContent>
        Hi! We noticed that you are on our {role} account. This is our publisher portal, would you like to change your role to publisher?
        </BoxContent>
        <div style={{display:'flex'}}>
          <SecondaryButton style={{marginRight:16}} onClick={() => {
            changeRole()
          }}>
            Change Role
          </SecondaryButton>
          <PrimaryButton onClick={() => {
            redirectWithRole()
          }}>
            {
              role === 'player' && `Player Site`
            }
            {
              role === 'influencer' && `Influencer Site`
            }
          </PrimaryButton>
        </div>
      </Box>
    }
    <Footer>
      <FooterLink href="https://www.arcadequest.app/terms-of-service" target="_blank">
        Terms and Conditions
      </FooterLink>
      <FooterLink href="https://www.arcadequest.app/privacy-policy" target="_blank">
        Privacy Policy
      </FooterLink>
    </Footer>
    </Container>
}

const Footer = styled.div`
  width: 100%;
  background-color: #00000; // Change the background color as needed
  padding: 20px;
  text-align: center;
`;

const FooterLink = styled.a`
  margin: 0 10px;
  color: #FFFFFF; // Change the text color as needed
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Container = styled.div`
  width: 100%;
  background:#000000 0% 0% no-repeat padding-box;
  min-height:calc(100vh);
  padding-top:108px;
  overflow-y:auto;
  position:relative;
`;

const Box = styled.div`
  display: flex;
  width: 471px;
  border-radius: 8px;
  background: rgba(247, 35, 117, 0.18);
  backdrop-filter: blur(45px);
  position: fixed;
  top: 68px;
  right: 24px;
  padding: 24px;
  height: auto;
  z-index: 100;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 90%;
    right: 5%;
    top: 60px;
  }
`;

const BoxTitle = styled.div`
color: #FAFAFA;
font-family: 'Roboto', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:16px;
`;

const BoxContent = styled.div`
color: #FAFAFA;
font-family: 'Roboto', sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 21px */
margin-bottom:16px;
`;

const Content = styled.div`
  width: 1260px;
  margin: 0px auto 48px;

  @media (max-width: 768px) {
    width: 90%;
    margin: 0px auto 24px;
  }
`;

const Top = styled.div`
  width: 100%;
  height: 545px;
  flex-shrink: 0;
  border-radius: 24px;
  background: url("/assets/indexPage/topBackground.png");
  padding: 36px;
  margin-bottom: 36px;

  @media (max-width: 768px) {
    height: auto;
    padding: 24px;
    margin-bottom: 24px;
  }
`;

const TopTitle = styled.div`
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  width: 700px;
  white-space: wrap;

  @media (max-width: 768px) {
    font-size: 36px;
    width: 100%;
  }
`;

const Desc = styled.div`
  color: #FAFAFA;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 510;
  line-height: normal;
  margin: 24px 0px 36px;
  width: 600px;

  @media (max-width: 768px) {
    font-size: 16px;
    width: 100%;
    margin: 16px 0px 24px;
  }
`;

const PrimaryButton = styled.div`
  display: flex;
  width: 194px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F72375;
  cursor: pointer;
  color: #FAFAFA;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;

  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 14px;
  }
`;

const SecondaryButton = styled.div`
  display: flex;
  width: 194px;
  height: 45px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #F72375;
  color: #F72375;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;

  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 14px;
  }
`;

const GameImage = styled.img`
  height: 40px;
  margin-right: 36px;

  @media (max-width: 768px) {
    height: 30px;
    margin-right: 24px;
  }
`;

const ContentBox = styled.div`
  width: 100%;
  height: 170px;
  border-radius: 10px;
  background: #383838;
  display: flex;
  padding: 24px;
  justify-content: space-between;
  margin: 24px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 16px;
  }
`;

const ContentTitle = styled.div`
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    padding-top: 15px; /* Adjust the value for mobile devices as needed */
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

const ContentText = styled.div`
  color: #FFF;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;


export default withRouter(PublisherEntry)