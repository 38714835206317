import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';


export const getCheckoutUrl = async (firebaseApp, priceId) => {
    const auth = firebaseApp.auth();
    const userId = auth.currentUser?.uid;
    if (!userId) throw new Error("User is not authenticated");
  
    const db = firebaseApp.firestore();
    const checkoutSessionRef = db.collection("customers").doc(userId).collection("checkout_sessions");
  
    const docRef = await checkoutSessionRef.add({
      price: priceId, // Make sure priceId is a valid value
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });
  
    return new Promise((resolve, reject) => {
      const unsubscribe = docRef.onSnapshot((snap) => {
        const { error, url } = snap.data();
        if (error) {
          unsubscribe();
          reject(new Error(`An error occurred: ${error.message}`));
        }
        if (url) {
          console.log("Stripe Checkout URL:", url);
          unsubscribe();
          resolve(url);
        }
      });
    });
};

export const getPortalUrl = async () => {
  const auth = firebase.auth();
  const user = auth.currentUser;

  let dataWithUrl;
  try {
    const functions = firebase.functions();
    const functionRef = functions.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    dataWithUrl = data;
    console.log("Reroute to Stripe portal: ", dataWithUrl.url);
  } catch (error) {
    console.error(error);
  }

  return new Promise((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};
