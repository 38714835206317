import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import {
  Input,
  Button,
  Table,
  List,
  Rate
} from 'antd';
import {
  CloseOutlined, CheckOutlined
} from '@ant-design/icons';
import countryList from 'react-select-country-list';
import {categoriesOption, CCVOptions, followerOptions, sortedByCVV, sortedByFollowers} from "./option";
import Select from 'react-select';
import moment from "moment";
import ISO6391 from 'iso-639-1';

const InfluencerList = ({influencers, viewInfluencerDetail, addToList, removeFromList, selectedList, tempDetail, reviewCampaign, unMutableInfluencersList, editExisting, saveForLater}) => {

  const [searchInput, setSearchInput] = useState([]);

  const countryOptions = useMemo(() => countryList().getData(), []);const languageName = ISO6391.getAllNames()??[];

  const languageOptions = languageName.map((key) => {
    return {
      value: key,
      label: key
    }
  })

  const [languageFilter, setLanguageFilter] = useState([]);

  const [ccvFilter, setCCVFilter] = useState([]);

  const [followerFilter, setFollowerFilter] = useState([]);

  const [searchFilter, setSearchFilter] = useState(false);

  const isSelected = (influencerId) => {
    return selectedList.includes(influencerId);
  }

  const isInUnmutableList = (influencerId) => {
    return (unMutableInfluencersList??[]).includes(influencerId);
  }

  const list = useMemo(() => {
    const arr = [];
    influencers.forEach((item) => {
      const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId, twitchUrl} = item;
      const avg_viewers = metricsData?.avg_viewers??"-";
      const followers = metricsData?.followers??"-";
      const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
      const pricePerStream = pricePerQuest ? pricePerQuest: estimatedPrice;
      const obj = {
        location,
        pricePerStream,
        description,
        name,
        avg_viewers,
        followers,
        profile_image_url,
        categories: item.categories??[],
        language,
        influencerId,
        twitchUrl,
        priceSet:pricePerQuest? true:false
      }
      arr.push(obj)
    });
    return arr;
  }, [influencers]);

  const sortedList = useMemo(() => {
    let sortedList = list;
    if(languageFilter && languageFilter.length >0){
      const availablelanguage = languageFilter.reduce((acc, curr) => {
        acc.push(curr.value);
        return acc;
      }, []);
      console.log(availablelanguage,'language filter');
      sortedList = sortedList.filter((item) => availablelanguage.includes(item.language))
    } 
    if(ccvFilter && ccvFilter.length > 0){
      const availableCVV = ccvFilter.reduce((acc, curr) => {
        acc.push(curr.value);
        return acc;
      }, []);
      sortedList = sortedByCVV(availableCVV, sortedList);
    }
    if(followerFilter && followerFilter.length > 0){
      const availablefollowers = followerFilter.reduce((acc, curr) => {
        acc.push(curr.value);
        return acc;
      }, []);
      sortedList = sortedByFollowers(availablefollowers, sortedList)
    }
    if(searchFilter && searchInput){
      sortedList = sortedList.filter(item => item.name.toUpperCase().includes(searchInput.toUpperCase()));
     }
    const unMutableArr = sortedList.filter(item => (unMutableInfluencersList??[]).includes(item.influencerId));
    const selectedArr = sortedList.filter(item => selectedList.includes(item.influencerId));
    const restArr = sortedList.filter(item => !selectedList.includes(item.influencerId) && !(unMutableInfluencersList??[]).includes(item.influencerId));
    const result = unMutableArr.concat(selectedArr).concat(restArr);
    return result;
  }, [list,  followerFilter, ccvFilter, languageFilter, searchFilter, searchInput, unMutableInfluencersList, selectedList]);

  const getInfluencerStatus = (influencerId) => {
    if(isInUnmutableList(influencerId)){
      return <StatusContainer unmuted={true}>
       <CheckOutlined style={{fontSize:18, marginRight:8}}/>
          Selected
      </StatusContainer>
    }else {
      if(isSelected(influencerId)){

        return <StatusContainer onClick={(e) => {
          removeFromList(influencerId);
          e.stopPropagation()
        }}
        selected={true}
        >
          <CheckOutlined style={{fontSize:18, marginRight:8}}/>
          Selected
          </StatusContainer>
      }else {
        return <StatusContainer onClick={(e) => {
          addToList(influencerId);
          e.stopPropagation()
        }}>
          <img src="/assets/add_to_campaign.svg"  alt="fortnite" style={{
          width:32,
          height:32,
          cursor:"pointer"
        }}
        
        />
        Add To Campaign
          </StatusContainer>
      }
    }
   
  }

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width:256,
      height: "auto",
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000",
      borderRadius: "5px",
      boxShadow: "none",
      '&:hover': {
        border: '1px solid #000000',
     }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
      };
    },
  };

  const getFilteredList = (list) => {
    const string = list.reduce((acc, curr) => {
      return `\xa0 ${acc} ${curr.label} \xa0`
    }, '') ;
    return string;
  }

  const allocatedBudget = useMemo(() => {
    const startDate = tempDetail?.startDate?? "";
    const endDate = tempDetail?.endDate??"";
    const weeks = Math.round(moment(endDate).diff(moment(startDate), 'weeks', true));
    const list = selectedList.concat(unMutableInfluencersList);
    const budgets = list.reduce((acc, curr) => {
      const influencer = influencers.find(item => item.influencerId === curr);
      if(influencer){
        const avg_viewers = influencer.metricsData?.avg_viewers??"-";
        const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
        const pricePerStream = influencer.pricePerQuest ? influencer.pricePerQuest: estimatedPrice;
        const price = pricePerStream* weeks;
        return acc + price;
      }else {
        return acc;
      }
    }, 0);
    return budgets;
  }, [selectedList, influencers, tempDetail, unMutableInfluencersList]);

  const remainingBudget = useMemo(() => {
    return (tempDetail?.budget??0) - (allocatedBudget??0); 
  }, [tempDetail, allocatedBudget])


  return <Container>
    <TopContainer>
      <div>
        <Title>
          Invite Influencers
        </Title>
        <Subtitle>
        Find who you want in your campaign!
        </Subtitle>
        </div>
        <Credits>
          <Credit style={{borderRight:"1px solid #A8A8A8"}}>
            <span style={{display:'inline-block'}}>
              Allocated Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {allocatedBudget}
              </span>
            </div>
          </Credit>
          <Credit>
          <span style={{display:'inline-block', marginLeft:8}}>
          Est. Remaining of Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {remainingBudget}
              </span>
            </div>
          </Credit>
        </Credits>
    </TopContainer>
    <SearchContainer>
      <SearchTop>
        <SearchInput onChange={(e) => {
          setSearchInput(e.target.value);
          setSearchFilter(false);
        }}
        value={searchInput}
        placeholder='Search Influencer...'
        >
        </SearchInput>
        <PrimaryButton onClick={() => {
          setSearchFilter(true);
        }}>
          Search
        </PrimaryButton>
        {
        searchFilter && searchInput && <SecondaryButton onClick={() => {
          setSearchFilter(false);
          setSearchInput("")
        }}>
          Clear
        </SecondaryButton>
       }
        <Operations>
          {
            !editExisting &&  <SecondaryButton onClick={() => {
              saveForLater()
            }}>
            Save For Later
          </SecondaryButton>
          }
          <PrimaryButton onClick={reviewCampaign}>
            Review and Send
          </PrimaryButton>
        </Operations>
      </SearchTop>
      <Filters>
          <div style={{width:"50%"}}>
            <FilterTitle>
              Filter By Creator
            </FilterTitle>
            <div style={{marginTop:8}}>
            <div style={{display:'inline-block', marginRight:16}}>
              <Select options={languageOptions} 
                  isClearable={true}
                  isMulti
                  styles={colourStyles}
                  placeholder="Language"
                  value={languageFilter}
                  onChange={(item) => {
                    setLanguageFilter(item)
                  }}
                  />
              </div>
 
            </div>
          </div>
          <div style={{width:"50%"}}>
          <FilterTitle>
              Filter By Performance
            </FilterTitle>
            <div style={{marginTop:8}}>
              <div style={{display:'inline-block', marginRight:16}}>
                  <Select options={followerOptions} 
                isClearable={true}
                isMulti
                styles={colourStyles}
                placeholder="Followers"
                value={followerFilter}
                onChange={(items) => {
                  setFollowerFilter(items)
                }}
                />
              </div>
              <div style={{display:'inline-block'}}>
              <Select options={CCVOptions} 
                  isClearable={true}
                  isMulti
                  styles={colourStyles}
                  placeholder="Average Concurrent Viewers"
                  value={ccvFilter}
                  onChange={(items) => {
                    setCCVFilter(items);
                  }}
                  />
              </div>
            </div>
          </div>
      </Filters>
      <FilterContainer>
          <FilterTitle style={{marginRight:12}}>
            Filters Applied:
          </FilterTitle>
          {
            languageFilter && languageFilter.length > 0 && <AppliedFilter>
              Creator Language: {getFilteredList(languageFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setLanguageFilter([])
              }}
              />
            </AppliedFilter>
          }
          {
            followerFilter && followerFilter.length > 0 && <AppliedFilter>
              Followers : {getFilteredList(followerFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setFollowerFilter([])
              }}/>
            </AppliedFilter>
          }
          { 
            ccvFilter && ccvFilter.length > 0 && <AppliedFilter>
              Followers : {getFilteredList(ccvFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setCCVFilter([])
              }}/>
            </AppliedFilter>
          }
          <Reset onClick={() => {
            setLanguageFilter([])
            setCCVFilter([]);
            setFollowerFilter([]);
          }}>
            Reset Filters
          </Reset>
      </FilterContainer>
    </SearchContainer>
    {/* <Table columns={columns} dataSource={sortedList} /> */}
    <List
    grid={{ gutter: 16, column: 3 }}
    dataSource={sortedList}
    renderItem={(item) => (
      <List.Item>
        <CardContainer 
          onClick={() => {
            viewInfluencerDetail(item.influencerId)
          }}
        >
            <div style={{display:'flex'}}>
              <ProfileImage src={item.profile_image_url??""} alt="profile"/>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                <ProfileName>
                  {item.name}
                </ProfileName>
                <ProfileLink>
                  {item.twitchUrl}
                </ProfileLink>
                <div style={{display:'flex', flexWrap:'wrap', minHeight:16}}>
              {
                item.categories.map((item) => <ProfileTag>
                  {item}
                </ProfileTag>)
              }
              </div>
              </div>
            </div>
            <ProfileDesc style={{marginTop:16}}>
              {item.description}
            </ProfileDesc>
            <div style={{display:'flex', justifyContent:'space-evenly', margin:'24px 0px'}}>
              <div style={{width:'30%', textAlign:'left', borderRight:'2px solid rgba(0, 0, 0, 0.42)'}}>
                <ItemContent>
                <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/> {item.followers}
                </ItemContent>
                <ItemTitle>
                  Followers
                </ItemTitle>
              </div>
              <div style={{width:'35%', textAlign:'left', borderRight:'2px solid rgba(0, 0, 0, 0.42)',paddingLeft:8}}>
                <ItemContent>
                <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/> {item.avg_viewers}
                </ItemContent>
                <ItemTitle>
                  Concurrent Viewers
                </ItemTitle>
              </div>
              <div style={{width:'35%', textAlign:'left', paddingLeft:8}}>
                <ItemContent>
                <Rate disabled defaultValue={5} />
                </ItemContent>
                <ItemTitle>
                  Reviews(0)
                </ItemTitle>
              </div>
            </div>
            <ItemContent>
            <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
            {item.pricePerStream}
            </ItemContent>
            <ItemTitle>
            {
                item.priceSet ? 'Price Per Steam' : "Estimated Price Per Stream"
              }
            </ItemTitle>
            {getInfluencerStatus(item.influencerId)}
           
          </CardContainer>
      </List.Item>
    )}
    />
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused
{
  background:#F3F2F3;
  border:none !important;
  box-shadow:none !important;
  border-radius:4px;
}

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
.ant-rate {
  font-size:14px;
}
`;

const TopContainer = styled.div`
display:flex;

`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const Credits = styled.div`
margin-left:auto;
display:flex;
width: 576px;
height: 41px;
background: #FFFFFF;
border-radius: 7px;

font-weight: 600;
font-size: 14px;
color: #000000;
padding:8px;
`;

const Credit = styled.div`
display:flex;
width:50%;
justify-content:space-between;
align-items:center;
`;


const SearchContainer = styled.div`
margin-top:24px;
width:100%;
height:auto;
background: #FFFFFE;
border: 1px solid #D9D9D9;
border-radius: 10px;
margin-bottom:24px;
`;

const SearchTop = styled.div`
display:flex;
height:68px;
border-bottom: 1px solid #D9D9D9;
padding:18px 24px;
`;

const SearchInput = styled(Input)`
width:325px;
margin-right:16px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;

const Operations = styled.div`
display:flex;
margin-left:auto;
`;

const SecondaryButton = styled(Button)`
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

const Filters = styled.div`
width:100%;
height:auto;
border-bottom: 1px solid #D9D9D9;
display:flex;
padding:12px 24px;
`;


const FilterTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
color: #000000;
`;


const FilterContainer = styled.div`
width:100%;
height:auto;
display:flex;
padding:16px 24px 16px 24px;
flex-wrap: wrap;
row-gap: 8px;
align-items:center;
`;


const ProfileContainer = styled.div`
max-width:280px;
display:flex;
height:108px;
`;

const ProfileImage = styled.img`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const AppliedFilter = styled.div`
width:auto;
height: 25px;
padding:0 12px;

background: rgba(247, 35, 117, 0.09);
border-radius: 7px;

font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 25px;

color: #F72375;

margin-right:16px;
`;

const Reset = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-decoration-line: underline;
margin-left:auto;
color: #000000;
cursor:pointer;
`;


const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

const CardContainer  = styled.div`
width: 370px;
height:auto;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
padding:16px;
cursor:pointer;
`;

const ProfileLink = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const ItemContent = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:8px;
text-align:left;
`;

const ItemTitle = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align:left;
`;

const StatusContainer = styled.div`
margin-top:24px;
width: 100%;
height: 36px;
text-align:center;
border-radius: 5px;
background: #F72375;

color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
line-height: 36px;

${p => p.selected && `background:white; color: #F72375; border:1px solid #F72375;`}
${p => p.unmuted && `background:white; color: #6A6A6A; border:1px solid #6A6A6A; cursor:not-allow;`}
`;


export default InfluencerList;