import React, {useState, useEffect, useMemo} from 'react';
import InfluencerList from '../campaignComponents/influencerList';
import InfluencerDetail from '../campaignComponents/influencerDetail';
import { Mixpanel } from '../../util/mixpanel';

const Members = ({partner, influencers, club}) => {
  const [view, setView] = useState(1);
  const [current, setCurrent] = useState(null);

  const members = useMemo(() => {
    const influencerMembers = club?.influencerMembers??[];
    const result = influencers.filter((item) => influencerMembers.some(i => i.influencerId === item.influencerId) );
    return result;
  }, [club, influencers]);
  

  useEffect(() => {
    if(partner){
      Mixpanel.track("[Publisher-Portal] Search Influencers", {
        partnerId:partner.partnerId,
        partnerName:partner.name
      })
    }
  }, [partner])

  const viewInfluencerDetail = (influencerId) => {
    const influencer = influencers.find(item => item.influencerId === influencerId);
    setCurrent(influencer);
    setView(2);
  }
  
  return <>
  {
    view === 1 && <InfluencerList influencers={members} viewInfluencerDetail={viewInfluencerDetail} 
    />
  }
  {
    view === 2 && <InfluencerDetail current={current} back={() => {
      setView(1);
      setCurrent(null)
    }}/>  
  }
  </>
}

export default Members;