import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
} from 'antd';
import React, {useState} from 'react';
import styled from 'styled-components';
import moment from "moment";
import {categoriesOption} from "./option";
import Select from "react-select"

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
};

const CampaignDetail = ({saveTempDetail, saveForLater}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width:726,
      height: "auto",
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000",
      borderRadius: "5px",
      boxShadow: "none",
      '&:hover': {
        border: '1px solid #000000',
     }
    }),
  };


  return <Container>
    <Title>
    Campaign Details
    </Title>
    <Subtitle>
    What do you want influencers to know about your campaign when they see your proposal, why should they work with you? This is your pitch to them.
    </Subtitle>
    <SelectContainer>
    <Form
      name="validate_other"
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
    > 
      <Form.Item
          name="name"
          label="CAMPAIGN NAME"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
          name="budget"
          label="BUDGET($USD)"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" type="number"/>
      </Form.Item>
      <Form.Item
          name="date"
          label="DURATION"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <RangePicker disabledDate={disabledDate} />
      </Form.Item>
      <Form.Item
        name="category"
        label="Category"
        labelAlign='Right'
      >
          <Select
          styles={colourStyles}
      options={categoriesOption}
    />
      </Form.Item>
      <Form.Item
          name="description"
          label="DESCRIPTION"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
           <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
        />
      </Form.Item>

      <div style={{display:'flex', alignItem:'center', marginTop:24}} >
         <PrimaryButton type="primary" htmlType="submit" disabled={loading} onClick={() => {
          const values = form.getFieldsValue(true);
          saveTempDetail(values)
         }}>
          Next
         </PrimaryButton>
         <SecondaryButton type="primary" htmlType="submit" disabled={loading} onClick={() => {
          const values = form.getFieldsValue(true);
          saveForLater(values);
         }}>
         Save For Later
         </SecondaryButton>
         </div>
      </Form>
    </SelectContainer>
  </Container>
}

const Container = styled.div`
display:flex;
flex-direction:column;

font-family: SF Pro Display !important;
.ant-form-item-label > label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height:16px;
}
  .ant-input,
  .ant-input-number-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
  }
  .ant-form-item {
    margin-bottom:8px;
  }

  .ant-btn {
    border: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background: #F72375;
  }

  .ant-picker,
  .ant-picker-focused {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
    width:726px;
  }
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const SelectContainer = styled.div`
margin-top:24px;
width: 750px;
height: auto;

background: #FFFFFF;
border-radius: 10px;
padding:12px;
margin-bottom:24px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;

const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

export default CampaignDetail