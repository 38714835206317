import React, {useEffect, useMemo, useState} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import * as API from "../util/api";
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Input, Button, message} from "antd";
import { ToastContainer, toast } from "react-toastify";
import { Mixpanel } from '../util/mixpanel';
import PublisherNavBar from "./nav";
// For the pop up
import { Modal } from 'antd';
// Analytics
import { useJune } from "../util/useJune";
//firebase
import Firebase, { googleProvider } from "../util/firebase";
import { useDispatch } from "react-redux";

const SignUp = ({history}) => {
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("")
  const analytics = useJune('NI29pX081wlcqqoi');


  const loginProfile = (profile, id) => ({
    type:"LOG_IN",
    profile,
    id
  });

  const updateProfile = (profile) => ({
    type:"UPDATE_PROFILE",
    payload:profile
  });

  const finishSignUp = async(values) => {
    const { email, firstName, password } = values;
    setLoading(true);
    try {
      const url = API.SIGNUP_WITHROLE;
      const requestBody = {
        firstName,
        email,
        password,
        role:'publisher'
      };
      const response = await axios.post(url, requestBody);
    if (response.status === 201) { // Check for status 201 instead of 200
      // After successful sign-up, identify the user with June
      analytics.identify(email, {
        email: email,
        name: firstName,
        role: 'publisher'
      });
      toast.success(
        "You have successfully signed up. A verification link has been sent to your email address. Please verify your email before logging in!",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        }
      );
      history.push("/login"); // Redirect to the login page
    } else {
      setLoading(false);
      toast.error("System Error, please try again or contact support", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    }
  } catch (error) {
    setLoading(false);
    toast.error(error.response?.data?.message || "An error occurred during sign-up. Please try again.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  }
  };
      // const response = await axios.post(url, requestBody);
  //     if (response.status === 200) {
  //       // After successful sign-up, identify the user with June
  //       analytics.identify(email, {
  //         email: email,
  //         name: firstName,
  //         role: 'publisher'
  //         // Add any other user traits here
  //       });
  //       toast.success(
  //         "You have successfully sign up, a verification link has been sent to your email address. Please verify your email before log in!",
  //         {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: false,
  //           progress: undefined,
  //         }
  //       );
  //       history.push("https://arcadequest.ai/login")
  //     } else {
  //       setLoading(false);
  //       toast.error("System Error, please try again or contact support", {
  //         position: "top-right",
  //         autoClose: 3000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: false,
  //         progress: undefined,
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(error.response.data.message, {
  //       position: "top-right",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: false,
  //       progress: undefined,
  //     });
  //   }
  // }


  const dispatch = useDispatch();

  const storUserData = (userData, id) => {
    dispatch(loginProfile(userData, id));
    Firebase.firestore()
    .collection("users")
    .doc(id)
    .onSnapshot((doc) => {
      const data = doc.data();
      dispatch(updateProfile(data))
    });
  }

  const checkUser = async (user, uid) => {
    try {
      const userRef = Firebase.firestore().collection("users").doc(uid);
      const doc = await userRef.get();
    if(!doc.exists){
      const name = user.displayName? user.displayName.split(" ")[0]:"";
      const email = user.email;
      const requestBody = {
        uid,
        name,
        email,
        role:'publisher'
      }
      const endpoint = API.CREATE_USER_DOC_WITHROLE;
      await axios.post(endpoint, requestBody);
      setTimeout(() => {
        processUserWithGoogle(uid)
      },500)
    }else {
      processUserWithGoogle(uid)
    }
    }catch(err){
      console.log(err);
      message.error("Failed to Sign In with Google, Please Try Again")
      setGoogleLoading(false);
    }
  }

  const processUserWithGoogle = async (id) => {
     const userRef = Firebase.firestore().collection("users").doc(id);
    
    try {
      const userData = (await userRef.get()).data();
      if(userData){
        storUserData(userData, id);
        history.push("/")
      }
    }catch(err){
      message.error("Failed to Sign In with Google, Please Try Again")
      console.log(err);
    }finally {
      setGoogleLoading(false);
    }
  }

  const signInWithGoogle = () => {
    Firebase.auth()
    .signInWithPopup(googleProvider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      var credential = result.credential;
  
      // This gives you a Google Access Token. You can use it to access the Google API.
      var token = credential.accessToken;
      // The signed-in user info.
      var user = result.user;
      // IdP data available in result.additionalUserInfo.profile.
        // ...
      Mixpanel.track("[Publisher Portal] Sign Up With Google")
      if(user.uid) {
        if (analytics) {
          analytics.track('[Publisher Portal] Sign Up With Google', {
            userEmail: user.email,
            // Add any other relevant properties here
          });
        }
        checkUser(user, user.uid);
      }
    }).catch((error) => {
      message.error("Failed to Sign In with Google, Please Try Again")
      console.log(error, 'error');

      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
  
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      var credential = error.credential;
      // ...
    });
  }



  return <Container>
    <PublisherNavBar isDarkMode={true} />
    <Header>
          <img
              src="/assets/aq_logo.png"
              style={{ height: "45px" }}
              alt="logo"
          /> 
    </Header>
    {
      step === 1 && <ContentContainer>
          <Step>1</Step>
          <StepIntro>
          STEP <b>1</b> OF <b>3</b>
          </StepIntro>
          <StepOneText style={{marginBottom:16, height:"auto"}}>
          Set Up Your Account
          </StepOneText>
          {
            googleLoading ? <GoogleContainer>
            Loading With Google...
          </GoogleContainer>:<img
              src="/assets/google_login.svg"
              alt="google"
              style={{ width:345, cursor:'pointer', marginTop:16 }}
              onClick={() => {
                if(!googleLoading){
                  setGoogleLoading(true);
                  signInWithGoogle()
                }
              }}
            />
          }
          <Divider>
        or
      </Divider>
          <NextButton style={{
            width:345,
            height:54,
            font:"normal normal bold 18px/54px SF PRO, serif",
            marginTop:16
          }}onClick={() => {
            setStep(2);
          }}>
            Sign Up With Email
          </NextButton>
      </ContentContainer>
    }
    {
      step === 2 && <ContentContainer>
      <StepIntro style={{width:453, textAlign:'left'}}>
        STEP <b>2</b> OF <b>3</b>
        </StepIntro>
        <StepOneText style={{width:453, textAlign:'left', marginBottom:12}}>
        Create a password to start your membership
        </StepOneText>
        <StepTwoText>
        Just a few more steps and you’re done!
        </StepTwoText>
        <Form
            style={{ margin: "0 auto", width:464}}
            name="nest-messages"
            onFinish={finishSignUp}
          >
            <Label>Username</Label>
            <Form.Item
              name="firstName"
              wrapperCol={24}
              rules={[{ required: true, message: "username is required" }]}
            >
              <Input
                style={{
                  width: 464,
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                placeholder="username"
              />
            </Form.Item>
            <Label>Email</Label>
            <Form.Item
              name="email"
              wrapperCol={24}
              rules={[{ required: true, message: "email is required" }]}
              initialValue={email? email:""}
            >
              <Input
                style={{
                  width: 464,
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                placeholder="email"
              />
            </Form.Item>
            <Label>Password</Label>
            <Form.Item
              name="password"
              wrapperCol={24}
              rules={[{ required: true, message: "password is required" }]}
            >
              <Input
                style={{
                  width: 464,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                  boxSizing: "border-box",
                }}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Label>Confirm Password</Label>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                style={{
                  width: 464,
                  height: 32,
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  borderRadius: 7,
                  border: "1px solid #000000",
                }}
                type="password"
                placeholder="ConfirmPassword"
              />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 8 }} >
            <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                style={{
                  width: 392,
                  height: 72,
                  marginRight:35,
                  border: "none",
                  borderRadius: 11,
                  font: "normal normal bold 30px SF PRO, serif",
                  color: "#FFFFFF",
                  background: loading
                    ? "#B7B7B7 0% 0% no-repeat padding-box"
                    : "#F72375 0% 0% no-repeat padding-box"
                }}
              >
                {loading? 'Loading...':'Next'}
                {!loading && <ArrowRightOutlined />} 
              </Button>
            </Form.Item>
            </Form>
    </ContentContainer>
    }
  </Container>
}
const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  background: #000000 0% 0% no-repeat padding-box;
  position: absolute;
  min-height: calc(100vh);
`;

const Header = styled.div`
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  height: 78px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: none !important;
    border: 0.5px solid #000000 !important;
  }
`;

const Step = styled.div`
  width: 92px;
  height: 92px;
  border: 3px solid #F72375;
  border-radius: 50%;
  text-align: center;
  font: normal normal bold 58px/92px Roboto;
  letter-spacing: 0px;
  color: #F72375;
  margin-bottom: 16px;
`;

const StepIntro = styled.div`
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  font: normal normal normal 24px 'Roboto', serif;
  margin-bottom: 16px;
`;

const StepOneText = styled.div`
  width: 376px;
  height: 98px;
  text-align: center;
  font-family: 'Roboto', serif;
  font-style: Bold;
  font-size: 38px;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: 48px;
`;

const StepTwoText = styled.div`
  font: normal normal 600 24px/34px Roboto, serif;
  letter-spacing: 0px;
  color: #ACACAC;
  text-align: left;
  width: 464px;
  margin-bottom: 12px;
`;

const NextButton = styled.div`
  margin-top: 48px;
  width: 392px;
  height: 72px;
  background: #F72375 0% 0% no-repeat padding-box;
  border-radius: 11px;
  font: normal normal bold 36px Roboto, serif;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-align: center;
  line-height: 72px;
  cursor: pointer;
`;

const Label = styled.span`
  text-align: left;
  font: normal normal 600 18px Roboto, serif;
  letter-spacing: 0px;
  color: #727272;
  display: block;
  margin-bottom: 4px;
`;

const Divider = styled.div`
  text-align: center;
  font: normal normal 600 16px Roboto, serif;
  letter-spacing: 0px;
  color: #D6D6D6;
  width: 345px;
  margin-top: 16px;
`;


const GoogleContainer = styled.div`
  width: 345px;
  height: 54px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  background: #B7B7B7 0% 0% no-repeat padding-box;
  cursor: not-allowed;
  font: normal normal 600 24px/54px Roboto, serif;
  text-align: center;
  color: #FFFFFF;
`;

export default withRouter(SignUp)
