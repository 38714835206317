import React, { useEffect } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Firebase from "./util/firebase";

//Action
import { updateProfile, updateLinkedProfile } from "./action/user";

import Entry from "./home/entry";
import Login from "./home/login";
import SignUp from "./home/signUp";
import BrandPortalEntry from "./brandPortal/index";
import CreateCampaign from "./brandPortal/createCampaign/index";

import { useSelector } from "react-redux";

const Router = ({ updateProfile,  updateLinkedProfile }) => {

  const { profile, loggedIn, id: userId} = useSelector((state) => state.user);


  useEffect(() => {
    const getProfileUpdate = async () => {
      if (loggedIn) {
        Firebase.firestore()
          .collection("users")
          .doc(userId)
          .onSnapshot((doc) => {
            const data = doc.data();
            updateProfile(data)
          });
      }
    };

    try {
      getProfileUpdate();
    } catch(err){
      console.log(err);
    }
  }, [updateProfile, userId, loggedIn]);

  useEffect(() => {
    const getPartnerProfile = (partnerId) => {
      Firebase.firestore()
        .collection("partners")
        .doc(partnerId)
        .onSnapshot((doc) => {
          const data = doc.data();
          updateLinkedProfile(data)
        });
    }
    if(profile){
      if(profile.permission_level === 4 && profile.adminOfPartner){
        getPartnerProfile(profile.adminOfPartner)
      }
    }
  }, [profile, updateLinkedProfile])


  return (
    <BrowserRouter>
      <Route exact path="/" component={Entry} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={SignUp} />
      <Route path="/brand_admin" component={BrandPortalEntry}/>
      <Route path="/create_campaign" component={CreateCampaign} />
    </BrowserRouter>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateProfile,
  updateLinkedProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
