import React from 'react';
import { withRouter } from 'react-router-dom';
import CreateProductForm from '../createCampaign/createProductForm';
import styled from 'styled-components';
import { ArrowLeftOutlined} from '@ant-design/icons';

const CreateProduct = ({partner, history}) => {
  return <>
     <Top>
          <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
            history.push("/brand_admin/products")
          }}/>
         Create a new Product
      </Top>
      <Container>
    <CreateProductForm partner={partner} isInPage={true}/>
    </Container>
    </>
}

const Container = styled.div`
padding:24px;

background: #FFFFFF;
border-radius: 10px;
margin-top:24px;
width:880px;
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

export default withRouter(CreateProduct)