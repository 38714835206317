import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  ArrowLeftOutlined
} from '@ant-design/icons';
import { withRouter } from "react-router-dom";

const SideBar = ({step, history}) => {
  return <Container>
    <Option>
    <Title>
    <ArrowLeftOutlined onClick={() => {
      history.push("/brand_admin/campaigns")
    }}/> Create Campaigns
    </Title>
    </Option>
    <Option>
    <Circle selected={step === 1}>
      1
    </Circle>
     <SubTitle selected={step === 1}>
        Select Product
     </SubTitle>
    </Option>
    <Option>
    <Circle selected={step === 2}>
      2
    </Circle>
     <SubTitle selected={step === 2}>
        Campaign Detail
     </SubTitle>
    </Option>
    <Option>
    <Circle selected={step === 3}>
      3
    </Circle>
     <SubTitle selected={step === 3}>
       Invite Influencers
     </SubTitle>
    </Option>
    <Option>
    <Circle selected={step === 4}>
      4
    </Circle>
     <SubTitle selected={step === 4}>
      Send Proposals
     </SubTitle>
    </Option>
  </Container>
}

const Container = styled.div`
  background-color: #FFFFFF;
  width: 234px;
  position: fixed;
  inset: 0%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: 108px;
  padding-bottom:64px;
  font-family:SF Pro Display;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
text-transform: uppercase;
color: #000000;
`;

const Option = styled.div`
display:flex;
margin-bottom:24px;
align-items:center;
padding-left:32px;
`;


const Icon = styled.img`
width:18px;
height:18px;
margin-right:12px;
`;

const SubTitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height:18px;
${p => p.selected ?`color: #F72375;`:
`color: #7B7B7B;`}
`;

const Circle = styled.div`
width:18px;
height:18px;
${p => p.selected ?`color: #F6F6F6; background:#000000`:
`color: #F6F6F6; background:#BCBCBC`};
border-radius:50%;
margin-right:12px;
text-align:center;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
`;

export default withRouter(SideBar);