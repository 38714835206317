// src/components/RichTextEditor.js
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

// Import the quill-image-resize module
import 'quill-image-resize';

function RichTextEditor({ value, onChange }) {

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'color': []}, {'background': []}],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
      ['clean'],
    ],
  };

  return (
    <Container>
       <ReactQuill
      value={value}
      onChange={onChange}
      modules={modules}
    />
    </Container>
  );
}

const Container = styled.div`
.ql-editor {
  min-height: 400px; /* Adjust the value as needed */
}



`;

export default RichTextEditor;