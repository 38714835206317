import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter, useParams} from "react-router-dom";
import RichTextEditor from './textEditor';
import { ArrowLeftOutlined } from '@ant-design/icons';
import axios from "axios";
import { debounce } from "lodash";
import { Input, message} from "antd";
import * as API from "../../util/api";


const CreatePost = ({club, games, partner, history}) => {
  const [content, setContent] = useState('');
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const finishPost = () => {
    setTitle("");
    setContent("");
    setLoading(false);
  }

  const debounceCreate = debounce(() => {
    createNewPost()
  },500)

  const createNewPost = async () => {
    if(title !== "" && content !== ""){
      const newPost = {
        title,
        content,
        creator: {
          name:partner?.name,
          image:partner?.logo??"",
          type:'pulisher'
        }
      }
      try {
        const requestBody = {
          newPost,
          clubId:club?.clubId,
          creatorType:'pulisher',
          creatorId:partner?.partnerId
        };
        const url = API.CLUB_CREATE_POST;
        const res = await axios.post(url, requestBody);
        if(res.status === 200){
          message.success("You have successfully create the post")
          finishPost();
          history.push(`/brand_admin/club`)
        }else {
          message.error("Failed to create post, please try again");
          setLoading(false);
        }
      }catch(err){
        console.log(err)
        message.error("Failed to create post, please try again");
        setLoading(false)
      }
    }
  }
  return <Content>
    <Title>
    <ArrowLeftOutlined onClick={() => {
          history.push(`/brand_admin/club`)
        }} style={{marginRight:12}}/>
    make a post
    </Title>
    <PostContainer>
      <Subtitle>
        Title
      </Subtitle>
      <StyledInput value={title} onChange={(e) => {
        setTitle(e.target.value)
      }}/>
      <Subtitle>
        Content
      </Subtitle>
    <RichTextEditor value={content} onChange={setContent} />
    <Submit onClick={() => {
      if(!loading){
        setLoading(true);
        debounceCreate()
      }
    }}>
      Post
    </Submit>
    </PostContainer>
    </Content>
}


const Content = styled.div`
width:854px;
border-radius: 10px;
`;

const Title = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const PostContainer = styled.div`
border-radius: 10px;
background: #FFF;
width:100%;
padding:16px;
`;

const Subtitle = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
margin-bottom:16px;
`;

const StyledInput = styled(Input)`
border-radius: 5px;
border: 1px solid #000;
display: flex;
padding: 8px 12px;
align-items: center;
margin-bottom:16px;
`;

const Submit = styled.div`
margin:24px 0px;
width: 130px;
height: 32px;
flex-shrink: 0;
border-radius: 7px;
background: #F72375;
color: #F6F6F6;
text-align: center;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 32px;
cursor:pointer;
`;

export default withRouter(CreatePost);