import React, { useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { Button, Input } from "antd";
import Firebase from "../../util/firebase";

const ForgetPasswordModal = ({ open, closeModal }) => {
  const [view, setView] = useState(1);
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const finish = () => {
    setView(1);
    setEmail("");
    setErrorMessage("");
    setLoading(false);
    closeModal();
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const resetEmail = () => {
    const auth = Firebase.auth();
    setLoading(true);

    auth.sendPasswordResetEmail(email)
      .then(() => {
        setView(2);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
        setTimeout(() => {
          setErrorMessage("");
        }, 5000);
      });
  };

  const customStyles = {
    content: {
      top: "20%",
      margin: "0 auto",
      bottom: "auto",
      width: "512px",
      height: "auto",
      background: "#FAFAFA 0% 0% no-repeat padding-box",
      boxShadow: "0px 3px 6px #00000029",
      borderRadius: "24px",
      padding:'48px'
    },
  };

  return (
    <Modal
      isOpen={open}
      style={customStyles}
      onRequestClose={closeModal}
      ariaHideApp={false}
    >
      <Detail>
        {view === 1 && (
          <>
            <Title>Forgot Password</Title>
            <EmailContainer>
              <Enter>Enter Your Email Address:</Enter>
              <Email>
                <img
                  src="/assets/email.png"
                  alt=""
                  style={{
                    height: "22px",
                    width: "22px",
                    marginRight: "16px",
                  }}
                />
                <Input
                  style={{
                    width: 330,
                    height: 28,
                    background: "#FAFAFA 0% 0% no-repeat padding-box",
                    border: "none",
                  }}
                  value={email}
                  onChange={changeEmail}
                  type="email"
                />
              </Email>
              <Error>{errorMessage}</Error>
            </EmailContainer>
            <ResetButton
              disabled={email.length === 0 || loading}
              onClick={resetEmail}
            >
              Reset Password
            </ResetButton>
          </>
        )}
        {view === 2 && (
          <>
            <Title>Forgot Password</Title>
            <SubTitle>
              Thank you! If there’s an email address associated with this
              account you will get reset instructions sent to your inbox.
            </SubTitle>
            <Home onClick={finish}>Finish</Home>
          </>
        )}
      </Detail>
    </Modal>
  );
};

// Styled components remain the same...

const SubTitle = styled.div`
  width: 456px;
  margin: 40px auto;
  text-align: center;
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
`;

const Home = styled(Button)`
  width: 122px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
`;

const Error = styled.span`
  font: normal 12px SF PRO, serif;
  color: red;
  text-align: red;
`;

const Email = styled.div`
  width: 379px;
  border-bottom: 3px solid #70707030;
  height: 40px;
  display: flex;
  align-items: center;
`;

const EmailContainer = styled.div`
  width: 379px;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Enter = styled.div`
  font: normal 18px/23px SF PRO, serif;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 10px;
`;

const ResetButton = styled(Button)`
  width: 225px;
  height: 27px;
  background: #ff5f93 0% 0% no-repeat padding-box;
  border-radius: 6px;
  font: bold 12px/17px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
`;

const Title = styled.div`
  font: bold 35px/38px SF PRO, serif;
  letter-spacing: 0px;
  font-weight: 900;
  color: #000000;
`;

const Detail = styled.div`
  padding: 60px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default ForgetPasswordModal;