import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import CreateNew from "./createNew";
import EditExisting from "./editExisting";
import Firebase from "../../util/firebase";


const Entry = ({history,location}) => {
  const [type, setType] = useState('create');
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const type = searchParams.get("type");
    if(type && type === 'edit'){
      const campaignId = searchParams.get("campaignId");
      if(campaignId){
        setType('edit');
        setCurrentCampaign(campaignId)
      }
    }else {
      setType("create")
      setCurrentCampaign(null)
    }

    let listener = null;
    if(!loggedIn || !profile.adminOfPartner || profile.permission_level !== 4){
      history.push("/")
    }else {
      const partnerId = profile.adminOfPartner;
      try {
        listener = Firebase.firestore().collection("partners").doc(partnerId).onSnapshot((doc) => {
          const obj = {...doc.data(), partnerId};
          setPartner(obj);
       })
      }catch(err){
        history.push("/")
      }
    }
    return () => {
      if(listener){
        listener();
      }
    }
    
  }, [location,loggedIn, history, profile]);


  return <>
  {
    type === 'create' && <CreateNew partner={partner}/>
  }
  {
    type === 'edit'  && <EditExisting partner={partner} campaignId={currentCampaign} />
  }
  </>

}

export default withRouter(Entry)