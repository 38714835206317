import React, { useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  Input,
  Button,
  Table,
} from 'antd';
import Firebase from "../util/firebase";
import {
  PlusOutlined
} from '@ant-design/icons';
import moment from "moment"
import Loading from "../util/loading";

const Campaigns = ({partner, history}) => {
  const [searchInput, setSearchInput] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [loading ,setLoading] = useState(true);
  const [searchFilter, setSearchFilter] = useState(false);

  useEffect(() => {
    const getCampaigns = async () => {
      let list = []
      try {
        const campaignsArray = partner?.campaigns??[];
        for(const id of campaignsArray){
          const campaignRef = Firebase.firestore().collection("campaign").doc(id);
          const campaignDoc = await campaignRef.get();
          const campaignData = campaignDoc.data();
          const obj = {
            ...campaignData,
            campaignId:id
          }
          list.push(obj);
        }
        setCampaigns(list);
        setLoading(false);
      }catch(err){
        console.log(err, 'fetch campaigns error')
      }
    }

    getCampaigns()
  },[partner]);

  const list = useMemo(() => {
    let arr = [];
    let campaignsResult = campaigns;
    if(searchInput !== ""  && searchFilter){
      campaignsResult = campaigns.filter(item => item.name.toUpperCase().includes(searchInput.toUpperCase()));
    }
    for(const campaign of campaignsResult){
      const {name, description, budget, category, startDate, proposalList, productName, status, campaignId} = campaign;
      const obj = {
        name,
        description,
        budget,
        category,
        startDate,
        proposalAmount:proposalList?.length??0,
        productName,
        status,
        campaignId
      }
      arr.push(obj);
    }
    return arr;
  }, [campaigns,searchInput, searchFilter]);


  const columns = [
    {
      title:"Campaign",
      key:"description",
      render: (record) => {
        return <ProfileContainer>
           <CampaignBackground status={record.status}/>
          <div>
            <ProfileName >
                {record.name}
            </ProfileName>
           <div style={{margin:"4px 0px", display:'flex', flexWrap:'wrap'}}>
             <ProfileTag>
                  {record.category}
                </ProfileTag>
           </div>
            <ProfileDesc>
                {record.description}
            </ProfileDesc>
          </div>
        </ProfileContainer>
      }
    },
    {
      title:"Proposal Sent",
      key:"proposalAmount",
      render:(record) => {
        return <Followers>
          {record.proposalAmount}
        </Followers>
      }
    },
    {
      title:"Start Date",
      key:"startDate",
      render:(record) => {
        return <Followers>
         {moment(record.startDate).format("L")}
        </Followers>
      }
    },

    {
      title:"Product",
      key:'productName',
      render:(record) => {
        return <Followers>
          {record.productName}
        </Followers>
      }
    },
    {
      title:"Budget",
      key:'budget',
      render:(record) => {
        return <Followers>
           <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
          {record.budget}
        </Followers>
      }
    },
    {
      title:"",
      key:"operation",
      render: (record) => {
        return <PrimaryButton onClick={() => {
          history.push(`/brand_admin/campaigns/${record.campaignId}`)
        }}>
          View
        </PrimaryButton>
      }
    },

  ];


  return <Container>
    {
      loading ? <Loading />: <>
         <Title>
     Your Campaigns
        </Title>
        <Subtitle>
        All your active campaigns.
        </Subtitle>
        <TopContainer>
        <SearchInput onChange={(e) => {
          setSearchInput(e.target.value);
          setSearchFilter(false);
        }}
        value={searchInput}
        placeholder='Search campaign'
        >
        </SearchInput>
         <PrimaryButton onClick={() => {
          if(searchInput !== ""){
            setSearchFilter(true);
          }
         }}>
          Search
         </PrimaryButton>
         {
          searchFilter && searchInput && 
            <SecondaryButton onClick={() => {
              setSearchFilter(false);
              setSearchInput("");
            }}>
              Clear
            </SecondaryButton>
         }
          <PrimaryButton style={{marginLeft:'auto'}} onClick={() => {
            history.push("/create_campaign")
          }}>
            Create Campaign <PlusOutlined />
          </PrimaryButton>
        </TopContainer>
        <Table columns={columns} dataSource={list} />
      </>
    }
    
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused
{
  background:#F3F2F3;
  border:none !important;
  box-shadow:none !important;
  border-radius:4px;
}

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const TopContainer = styled.div`
display:flex;
margin:24px 0px;
`;

const SearchInput = styled(Input)`
width:325px;
margin-right:16px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;

const ProfileContainer = styled.div`
width:232px;
height:auto;
display:flex;
`;

const CampaignBackground = styled.div`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
${p => p.status === 0 && `background-color:#D9D9D9;`};
${p => p.status === 1 && `background-color:#007A0C;`}
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

export default withRouter(Campaigns)