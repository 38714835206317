import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment'
import { withRouter } from "react-router-dom";
import { calcCreatorQuestPool } from "../../util/helper";
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore

const QuestCard = ({item, history}) => {
  const calcPrizePool = (item) => {
    return calcCreatorQuestPool(item);
  }

  const displayTime = (time) => {
    const date = moment(time).format("MMMM Do YYYY - HH:mm")
    return date;
  }

  return <QuestBox key={item.questId} onClick={() => {
    history.push(`/influencer_admin/quests/${item.questId}`)
  }}>
      <Top>
        <ActiveIcon src="/assets/quest_upcoming_active.svg" alt="icon"/>
        {item.questName}
      </Top>
      <Middle>
        <MiddleTop>
        <Text>
          PRIZE
        </Text>
        <img src="/assets/influencer_coin.svg" alt="icon" style={{
          height:24,
          width:24,
          margin:'0 24px'
        }}/>
        <Prize>
        {calcPrizePool(item)}
        </Prize>
        </MiddleTop>
        <div style={{display:'flex'}}> 
        <MiddleBottom style={{borderRight: "1px solid #1C1D20"}}>
            <TextContent>
            {item?.players?.length?? 0}
              </TextContent>
              <Text>
                PLAYERS
              </Text>
        </MiddleBottom>
        <MiddleBottom >
            <TextContent>
            {(item?.duration?? 0)/60} hrs
              </TextContent>
              <Text>
               Duration
              </Text>
        </MiddleBottom>
        </div>
      </Middle>
      <Time>
        {displayTime(item.startTime)}
      </Time>
  </QuestBox>
}

const QuestBox = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 12px;

width: 249px;
height: 214px;

background: #1C1D20;
border-radius: 8px;
cursor:pointer;
`;


const Top = styled.div`
font-style: normal;
font-weight: 700;
font-size: 15px;
color: #FAFAFA;
line-height:24px;
`;

const Middle = styled.div`
width: 219px;
height: 106px;

/* Primary/Bastille */

background: #30333B;
border-radius: 8px;
margin:12px auto;
`;

const MiddleTop = styled.div`
border-bottom: 1px solid #1C1D20;
height:54px;
width:100%;
display:flex;
align-items:center;
justify-content:center;
`;

const Text = styled.span`
display:block;
font-style: normal;
font-weight: 510;
font-size: 12px;
leading-trim: both;
text-edge: cap;

color: #9B9C9D;
`;

const Prize = styled.div`
font-style: normal;
font-weight: 590;
font-size: 24px;
leading-trim: both;
text-edge: cap;

color: #FAFAFA;
`;

const MiddleBottom = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:space-around;
width:50%;
padding:4px 0px;
`;

const TextContent = styled.div`
font-style: normal;
font-weight: 590;
font-size: 15px;

color: #FAFAFA;
`;

const Time = styled.div`
font-style: normal;
font-weight: 510;
font-size: 15px;
width:219px;
text-align:center;
color: #9B9C9D;
`;

const ActiveIcon = styled.img`
height:24px;
width:24px;
margin-right:8px;
`;


export default withRouter(QuestCard)