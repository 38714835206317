import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Table,
  Progress
} from 'antd';
import moment from "moment";
import { proposalStatus, getProposalStatus } from '../createCampaign/option';
import Firebase from "../../util/firebase";
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore
// Import other Firebase services as needed

const CampaignDetailAfterStart = ({history, campaign, proposals, influencers, viewInfluencerDetail}) => {

  const [view, setView] = useState(1);

  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchSessions = async () => {
      const activeProposals = proposals.filter(item => item.sessionId);
      const result = await Promise.all(activeProposals.map(async (proposal) => {
        const sessionId = proposal.sessionId;
        const sessionRef = Firebase.firestore().collection("brand_session").doc(sessionId);
        const sessionDoc = await sessionRef.get();
        const sessionData = sessionDoc.data();
        return {
          sessionId,
          ...sessionData
        }
      }));
      setSessions(result)
    }
    fetchSessions();
  }, [proposals])

  const campaignProgress = useMemo(() => {
    const duration = moment(campaign.endDate).diff(campaign.startDate, "seconds");
      const now = moment();
      const initial = now.diff(campaign.startDate, "seconds");
      console.log(duration, initial);
      console.log((initial/duration).toFixed(3))
      return (((initial/duration).toFixed(3)) * 100).toFixed(2);
  }, [campaign]);

  const getLeads = (proposalId) => {
    const target = sessions.find(item => item.proposalId === proposalId)
    return target?.totalJoin??0;
  }

  const activeList = useMemo(() => {
    const activeProposals = proposals.filter(item => (item.status === proposalStatus.ACTIVE) 
    || (item.status === proposalStatus.COMPLETED) ||(item.status === proposalStatus.SUBMITTED));

    let arr = [];
    activeProposals.forEach((item) => {
      const influencer = influencers.find(i => i.influencerId === item.influencerId);
      const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId} = influencer;
      const avg_viewers = metricsData?.avg_viewers??"-";
      const followers = metricsData?.followers??"-";
      const priceInCredit = (pricePerQuest*4)*10;
      const influencerData = {
        location,
        priceInCredit: pricePerQuest? priceInCredit:0,
        pricePerQuest,
        description,
        name,
        avg_viewers,
        followers,
        profile_image_url,
        categories: item.categories??[],
        language,
        influencerId,
      }
      const obj = {
        influencerData,
        ...item
      }
      arr.push(obj)
    })
    return arr;
  }, [influencers, proposals]);

  const archievedList = useMemo(() => {
    const archievedProposals = proposals.filter(item => (item.status === proposalStatus.RECEIVED) 
    || (item.status === proposalStatus.REJECTED) || (item.status === proposalStatus.CANCELLED) );

    let arr = [];
    archievedProposals.forEach((item) => {
      const influencer = influencers.find(i => i.influencerId === item.influencerId);
      const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId} = influencer;
      const avg_viewers = metricsData?.avg_viewers??"-";
      const followers = metricsData?.followers??"-";
      const priceInCredit = (pricePerQuest*4)*10;
      const influencerData = {
        location,
        priceInCredit: pricePerQuest? priceInCredit:0,
        pricePerQuest,
        description,
        name,
        avg_viewers,
        followers,
        profile_image_url,
        categories: item.categories??[],
        language,
        influencerId,
      }
      const obj = {
        influencerData,
        ...item
      }
      arr.push(obj)
    })
    return arr;
  }, [proposals, influencers])




  const activeColumns = [
    {
      title:"Influencers",
      key:"description",
      render: (record) => {
        const influencerData = record.influencerData;
        return <ProfileContainer>
          <ProfileImage src={influencerData.profile_image_url} alt="logo"/>
          <div>
            <ProfileName onClick={() => {
              viewInfluencerDetail(influencerData.influencerId)
            }}>
                {influencerData.name}
            </ProfileName>
           <div style={{margin:"4px 0px", display:'flex', flexWrap:'wrap'}}>
              {
                influencerData.categories.map((item) => <ProfileTag>
                  {item}
                </ProfileTag>)
              }
           </div>
            <ProfileDesc>
                {influencerData.description}
            </ProfileDesc>
          </div>
        </ProfileContainer>
      }
    },
    {
      title:"Followers",
      key:"followers",
      render:(record) => {
        return <Followers>
          <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/>
          <span style={{display:'inline-block'}}>{record.influencerData.followers}</span>
        </Followers>
      }
    },
    {
      title:"Price(Monthly)",
      key:'price',
      render:(record) => {
        return <Followers>
          <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
          {record.influencerData.priceInCredit?? 0}
        </Followers>
      }
    },
    {
      title:"Leads",
      key:'leads',
      render: (record) => {
        return <Followers>{getLeads(record.proposalId)}</Followers>
      }
    },
    {
      title:"Quests(Scheduled)",
      key:'scheduledQest',
      render:(record) => {
        return <Followers>
          {record.questsList.length?? 0}
        </Followers>
      }
    },
    {
      title:"Quests(Done)",
      key:"operation",
      render: (record) => {
        const {schedulePlan} = record;
        const doneList = schedulePlan.filter(item => moment(item.startDate).isBefore(moment()));
        return <Followers>
        {doneList?.length?? 0}
      </Followers>
      }
    },
    {
      title:"",
      key:'operation',
      render:(record) => {
        return <img src="/assets/brand_portal_view_proposal.svg" alt="view" style={{width:32, height:32}}
        onClick={() => {
          viewInfluencerDetail(record.influencerData.influencerId)
        }}
        />
      }
    }
  ];

  const archievedColumns = [
    {
      title:"Influencers",
      key:"description",
      render: (record) => {
        const influencerData = record.influencerData;
        return <ProfileContainer>
          <ProfileImage src={influencerData.profile_image_url} alt="logo"/>
          <div>
            <ProfileName onClick={() => {
              viewInfluencerDetail(influencerData.influencerId)
            }}>
                {influencerData.name}
            </ProfileName>
           <div style={{margin:"4px 0px", display:'flex', flexWrap:'wrap'}}>
              {
                influencerData.categories.map((item) => <ProfileTag>
                  {item}
                </ProfileTag>)
              }
           </div>
            <ProfileDesc>
                {influencerData.description}
            </ProfileDesc>
          </div>
        </ProfileContainer>
      }
    },
    {
      title:"Followers",
      key:"followers",
      render:(record) => {
        return <Followers>
          <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/>
          <span style={{display:'inline-block'}}>{record.influencerData.followers}</span>
        </Followers>
      }
    },
    {
      title:"Price(Monthly)",
      key:'price',
      render:(record) => {
        return <Followers>
          <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
          {record.influencerData.priceInCredit?? 0}
        </Followers>
      }
    },
    {
      title:"Average Concurrent Viewers",
      key:"averageViwers",
      render:(record) => {
        return <Followers>
          <span style={{display:'inline-block'}}>{record.influencerData.avg_viewers}</span>
        </Followers>
      }
    },
    {
      title:"Stauts",
      key:'leads',
      render: (record) => {
        return <Status status={getProposalStatus(record.status)}>
          {getProposalStatus(record.status)}
        </Status>
      }
    },
    {
      title:"",
      key:'operation',
      render:(record) => {
        return <img src="/assets/brand_portal_view_proposal.svg" alt="view" style={{width:32, height:32}}
        onClick={() => {
          viewInfluencerDetail(record.influencerData.influencerId)
        }}
        />
      }
    }
  ];

  const questsScheduled = useMemo(() => {
    const count = sessions.reduce((acc, curr) => {
      const num = curr?.questsList?.length??0;
      return acc + num;
    }, 0);
    return count;
  }, [sessions]);

  const questsDone = useMemo(() => {
    const count = proposals.reduce((acc, curr) => {
      const doneList = (curr.schedulePlan??[]).filter(item => moment(item.startDate).isBefore(moment()))
      const num = doneList?.length??0;
      return acc + num;
    }, 0);
    return count;
  }, [proposals]);

  const totalLeads = useMemo(() => {
    const count = sessions.reduce((acc, curr) => {
      const num = curr?.totalJoin??0;
      return acc + num;
    }, 0);
    return count;
  }, [sessions]);

  const totalBill = useMemo(() => {

    const total = activeList.reduce((acc, curr) => {
      const quests = (curr.schedulePlan??[]).length;
      const pricePerquest = curr.influencerData.pricePerQuest;
      const num =(quests * pricePerquest) * 10;
      return acc + num;
    }, 0);
    return total;
  }, [activeList])

  return <Container>
  <Top>
    <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
      history.push("/brand_admin/campaigns")
    }}/>
    campaign details
  </Top>
  <TopContainer>
              <Title>
              {campaign?.name??""}
              </Title>
              <TimeContainer>
                {moment(campaign.startDate).format("LL")} - {moment(campaign.endDate).format("LL")}
              </TimeContainer>
              </TopContainer>
              <ProgressBox>
          <span style={{display:'block'}}>
            CAMPAIGN PROGRESS
          </span>
          <span style={{display:'block'}}>
            {campaignProgress} %
          </span>
        </ProgressBox>
  <Progress
      percent={campaignProgress}
      showInfo={false}
      strokeColor="#FF0FCA"
      strokeWidth={5}
    />
    <div style={{display:'flex', justifyContent:'space-between', margin:'24px 0px'}}>
    <LeftBox>
                  <BoxContent>
                    {activeList.length}
                  </BoxContent>
                  <BoxTitle>
                  Active Proposals
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                 <BoxContent>
                    {archievedList.length}
                  </BoxContent>
                  <BoxTitle>
                  Archieved Proposals
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                 <BoxContent>
                    {questsScheduled}
                  </BoxContent>
                  <BoxTitle>
                  Quests Scheduled
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                 <BoxContent>
                    {questsDone}
                  </BoxContent>
                  <BoxTitle>
                  Quests Done
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                 <BoxContent>
                    {totalLeads}
                  </BoxContent>
                  <BoxTitle>
                  Total Leads
                  </BoxTitle>
              </LeftBox>
              <LeftBox>
                 <BoxContent>
                    {totalBill}
                  </BoxContent>
                  <BoxTitle>
                  Total Bill 
                  </BoxTitle>
              </LeftBox>
    </div>
        <Choices >
          <Choice active={view === 1}  onClick={() => {
              setView(1)
            }}>
               Active Proposals
          </Choice>
           <Choice active={view === 2}  onClick={() => {
              setView(2)
            }}>
               Archieved Proposals
          </Choice>
          </Choices>
    {
      view === 1 && <Table columns={activeColumns} dataSource={activeList} />
    }
    {
      view === 2 && <Table columns={archievedColumns} dataSource={archievedList} />
    }
</Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}

.ant-progress-inner {
  background-color:#A8A8A8;
}
`;


const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const TopContainer = styled.div`
display:flex;
margin:24px 0px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const TimeContainer = styled.div`
width: auto;
height: 29px;
flex-shrink: 0;
padding:0 24px;
border-radius: 4px;
background: #FFF;
color: #000;
leading-trim: both;
text-edge: cap;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 29px;
text-transform: uppercase;
margin-left:36px;
`;

const ProgressBox = styled.div`
color: #000;
/* font-family: SF Pro Display; */
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
display:flex;
justify-content:space-between;
`;

const ProfileContainer = styled.div`
width:232px;
height:auto;
display:flex;
`;

const ProfileImage = styled.img`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

const Choices = styled.div`
width:100%;
margin:24px 0px 24px;
display:flex;
height:24px;
border-bottom:2px solid #A8A8A8;
`;

const Choice = styled.div`
width: auto;
padding:0 12px;
/* UI Properties */
letter-spacing: 0px;
text-align:center;
font-weight: 700;
font-size: 16px;
height:24px;
line-height:24px;

text-transform: capitalize;

color: #7B7B7B;
cursor:pointer;
${(p) =>
   p.active
        ? `color:#F72375; border-bottom:2px solid #F72375;`
        : ``}
`;


const Status = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
${p => p.status === 'RECEIVED' && `color:#7B7B7B;`};
${p => (p.status === 'ACCEPTED' || p.status === 'ACTIVE' || p.status === 'COMPLETED' || p.status === 'SUBMITTED') && `color:#007A0C;`};
${p => p.status === 'REJECTED' && `color:#FF0000;`};
`;


const LeftBox = styled.div`
width: 180px;
height: 76px;

background: #FFFFFF;
border-radius: 10px;
padding:12px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const BoxContent = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
text-transform: uppercase;
color: #000000;
`;

const BoxTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
leading-trim: both;
text-edge: cap;
text-transform: uppercase;

color: #000000
`;

export default withRouter(CampaignDetailAfterStart);