import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

const BrandNav = ({history}) => {
  const toIndex = () => {
    history.push("/");
  };

  return (
    <Container >
        <Logo onClick={toIndex} src="/assets/aq_logo.png">
        </Logo>
      <Right>
          {/* <CreateButton onClick={()=>{
            history.push("/create_campaign")
          }}>
            Create Campaign
          </CreateButton> */}
          
          {/* Removed because I just want the search <Icon src="/assets/user_black.svg" alt="user" /> */}
      </Right>
    </Container>
  )

}

const Container = styled.div`
  height: 78px;
  padding: 15px 40px;
  display: flex;
  background: #FFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  font-family: SF Pro Display;
`;

const Logo = styled.img`
width: auto;
height: 40px;
margin-right: 48px;
cursor: pointer;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  padding-left: 32px;
`;

const CreateButton = styled.div`
width: 160px;
height: 32px;
background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 32px;
text-align:center;
color: #FFFFFF;
margin-right:32px;
cursor:pointer;
`;

const Credit = styled.div`
font-style: normal;
font-weight: 600;
font-size: 18px;
color: #F6F6F6;
margin-right:24px;
`;

const Icon = styled.img`
height:32px;
width:32px;
`;

export default withRouter(BrandNav)