import React, {useState, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from "react-router-dom";
import moment from 'moment';

const GameCard = ({game}) => {

  const displayImage = useMemo(() => {
    if(game.bannerUrl){
      return game.bannerUrl;
    }else if(game.productImages && game.productImages.length > 0){
      return game.productImages[0];
    }else {
      return null;
    }
  }, [game]);

  const hasPriceSet = useMemo(() => {
    if(game.original_price && game.promote_price){
      return true;
    }
    return false;
  }, [game]);

  const discountPercent = useMemo(() => {
    if(game && game.original_price && game.promote_price){
      const diff = game.original_price - game.promote_price;
      const percent = Math.round((diff/game.original_price)*100);
      return percent
    }
    return null;
  }, [game]);



  return <Container>
    <div style={{display:'flex'}}>
      {
        displayImage && <img src={displayImage} alt="logo" style={{width:131, height:61, marginRight:12}}/>
      }
      <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
        <Title>
          {game.name}
        </Title>
        <Date>
          {moment(game?.createdDate).format("L")}
        </Date>
      </div>
    </div>
    <Desc>
      {game?.short_description}
    </Desc>
  </Container>
}

const Container = styled.div`
width: 372px;
height: auto;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
margin-bottom:24px;
padding:24px;
`;

const Title = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;

const Date = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;

const Desc = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin:12px 0px;
`;



export default withRouter(GameCard)