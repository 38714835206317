import React, { useState, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import { Steps, Card, Tag } from 'antd';
import { proposalStatus } from '../createCampaign/option';
import QuestCard from './questCard';
import { getLabel } from '../createCampaign/option';
const { Step } = Steps;



const InfluencerProposalDetail = ({proposal, quests, isRejected}) => {
  const getTimeStamp = (status) => {
    const {timeLine} = proposal;
    const target = timeLine.find(item => item.type === status);
    if(target){
      return <TimeText>{moment(target.date).format("LLL")}</TimeText>
    }else {
      return "";
    }
  }

 
  
  const customDot = (dot, { status, index }) => {
    if(status === "finish"){
      return <Icon src="/assets/proposal_timeline_finished.svg"/>
    }else if(status === "process"){
      if(isRejected && index === 1){
        return <Icon src="/assets/proposal_timeline_rejected.svg"/>
      }
      return <Icon src="/assets/proposal_timeline_process.svg"/>
    }else {
      return <Icon src="/assets/proposal_timeline_waiting.svg"/>
    }
  }
  const current = useMemo(() => {
    if(proposal.status === 2){
      return 1;
    }else if(proposal.status === 1){
      return 2;
    }else {
      return proposal.status;
    }
  }, [proposal])

  return <>
     <Container>
   <Steps current={current} progressDot={customDot}>
    <Step title="Received" description={getTimeStamp(0)} />
    <Step title={isRejected ? 'Rejected':"Accepted"} description={proposal.status === 2 ? getTimeStamp(2): getTimeStamp(1)} />
    <Step title="Scheduling"  />
    <Step title="Live" description={getTimeStamp(3)} />
    <Step title="Completed" description={getTimeStamp(4)} />
    <Step title="Submitted" description={getTimeStamp(5)} />
  </Steps>
  </Container>
  {
    proposal.status === proposalStatus.ACCEPTED && proposal.schedulePlan && <>
    <Title>
    Proposed Quests Time
  </Title>
    <QuestContainer>
      {(proposal.schedulePlan ?? []).map((item, index) => {
      return  <Card size="small" title={`No.${index+1} Quest`} 
      key={item.planId}
      >
      <Content>
      <span style={{display:'block', color:"#9B9C9D"}}>
          Quest Name:
        </span>
        <span style={{display:'block'}}>
        {item.name}
        </span>
      </Content>
      <Content>
        <span style={{display:'block', color:"#9B9C9D"}}>
          Proposed Time:
        </span>
        <span style={{display:'block'}}>
        {moment(item.startDate).format("LLL")}
        </span>
      </Content>
      <Content style={{borderBottom:"none"}}>
      <span style={{display:'block', marginBottom:4, color:"#9B9C9D"}}>
          Game Modes:
        </span>
        {item.gameModeSelected? <>
        {
          (item.modes??[]).map((item, index) => {
            return <Tag color="green" key={index} style={{marginBottom:8}}>{getLabel(item)}</Tag>
          }) 
        }
        </>:<span style={{display:'block'}}>ALL</span>}
      </Content>
    </Card>
    })  
  }
    </QuestContainer>
    </>
  }
  {
    (proposal.status === proposalStatus.ACTIVE || proposal.status === proposalStatus.COMPLETED || proposal.status === proposalStatus.SUBMITTED) && <>
    <Title>
      Scheduled Quest
    </Title>
    <QuestContainer>
      {
          quests.map((item) => {
            return <QuestCard item={item} key={item.questId}/>
          })
        }
    </QuestContainer>
    </>
  }
  </>
}

const Container = styled.div`
padding: 30px;
border-radius: 8px;
background:#FFFFFF;
padding:24px 30px 24px 12px;
width:100%;
margin-bottom:24px;

.ant-steps-item-tail {
  top:11px !important;
  margin: 0 0 0 78px !important;
}

.ant-steps-item-tail::after {
  height:2px !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color:#00FB82 !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color:#9B9C9D !important;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color:#9B9C9D !important;
}

.ant-steps-dot .ant-steps-item-content {
  margin-top:24px;
}

.ant-steps-dot .ant-steps-item-title {
  /* color:#FAFAFA !important; */
  margin-left:36px;
}
`;

const Icon = styled.img`
width:24px;
height:24px;
`;

const TimeText = styled.div`
color: #9B9C9D;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 120%; /* 16.8px */
margin-left:36px;
`;

const Title = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin-bottom:24px;
`;

const QuestContainer = styled.div`
border-radius: 8px;
border: 1px solid #1C1D20;
background:#FFFFFF;
padding:16px;
width:100%;
min-height:256px;
display:grid;
grid-template-columns: repeat(4, 249px);
  justify-content:space-between;


.ant-card {
  background:#1C1D20;
  padding:0px;
}

.ant-card-bordered {
  border:none;
}

.ant-card-body {
  padding:0px;
}

.ant-card-head {
  border-bottom:2px solid black;
  color:#FAFAFA;
  font-family: SF Pro;
font-size: 20px;
}
`;

const Content = styled.div`
height:auto;
width:100%;
border-bottom:2px solid black;
color: #FAFAFA;
font-family: SF Pro;
font-size: 16px;
font-style: normal;
font-weight: 590;
line-height: normal;
padding:12px;
`;

export default withRouter(InfluencerProposalDetail);
