import React, {useState} from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  message
} from 'antd';
import { debounce } from "lodash";
import * as API from "../../util/api";
import axios from "axios";

const AddCodeModal = ({game, open, closeModal}) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const debounceAddCode = debounce(() => {
    addCode()
  },500);


  const addCode = async () => {
    const requestBody = {
      gameId:game.gameId,
      code
    }
    const url = API.ADD_GAME_CODE;
    try {
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setLoading(false);
        setCode("");
        closeModal();
        message.success("Code is added to the list")
      }else {
        setLoading(false);
        message.error("Failed to add code, please try again")
      }
    }catch(err){
      console.log(err);
      setLoading(false);
      message.error("Failed to add code, please try again")
    }
  }

  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: 600,
      height:'auto',
      padding:'24px',
      background: "#FFFFFF",
      borderRadius: "10px",
      zIndex: "10000",
    },
  };

  return <Modal
  isOpen={open}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
  <Container>
<Top>
  <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
    closeModal()
  }}/>
  Add Code
</Top>
<StyledInput value={code} onChange={(e) => {
  setCode(e.target.value)
}}/>
 <PrimaryButton 
 style={{float:'right'}}
 disabled={loading}
 onClick={() => {
                  if(code && !loading){
                    setLoading(true);
                    debounceAddCode()
                  }
                }}>
                  {
                    loading ? 'Loading...' : 'Add Code'
                  }
  </PrimaryButton>
</Container>
</Modal>

}

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const StyledInput = styled(Input)`
border-radius: 10px;
background: #F6F6F6;
width:100%;
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
height:40px;
margin:24px 0px;
`;

const Container = styled.div`
.ant-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    box-shadow: none !important;
    border:none;
  }
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
&:hover {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
&:focus {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
`;

export default AddCodeModal;