import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import {
  EyeOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { useDispatch } from "react-redux";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { useHistory } from 'react-router-dom'; // Use useHistory instead of useNavigate for version 8
import { getStripePayments, createCheckoutSession } from "@invertase/firestore-stripe-payments";
//import { getCheckoutUrl } from '../campaignComponents/stripePayment';
import Firebase from "../../util/firebase";
import { getCheckoutUrl } from '../campaignComponents/stripePayment';
import PaymentModal from '../campaignComponents/paymentModal';

const SideBar = ({currentSelect, partner}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const partnerName = partner?.name ?? "";
  const partnerType = partner?.type ?? "brand";
  const hasClub = useMemo(() => !!partner?.communityId, [partner]); // Define hasClub based on your logic
  
  // For display by permsision level
  const [userPermissionLevel, setUserPermissionLevel] = useState(null);

  useEffect(() => {
    const fetchUserPermissionLevel = async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
        const userData = userDoc.data();
        setUserPermissionLevel(userData.permission_level);
      }
    };
    fetchUserPermissionLevel();
  }, []);


  // Initialize Stripe Payments
  const payments = getStripePayments(Firebase.firestore(), {
    productsCollection: 'products',
    customersCollection: 'customers',
  });

  // Stripe subscription Flow
  const handleSubscribe = async () => {
    const priceId = 'price_1OxGbWCHKZ1BDpQTqnXJ1Huq';
    const checkoutUrl = await getCheckoutUrl(Firebase, priceId)
    window.location.assign(checkoutUrl);
    // try {
    //   const session = await createCheckoutSession(payments, {
    //     price: priceId,
    //     success_url: window.location.origin + '/brand_admin/search', // Update with your success URL
    //     cancel_url: window.location.origin + '/',   // Update with your cancel URL
    //   });
    //   // Redirect the user to the Stripe Checkout page
    //   window.location.assign(session.url);
    // } catch (error) {
    //   console.error('Error creating checkout session:', error);
    //   // Handle the error appropriately in your UI
    // }
  };

  return <Container>
    {
      // Commented out fields that I want to hide for now because they aren't set up with the rest of Supabase
      (partnerType === 'game_publisher' && hasClub) && <Link  to="/brand_admin/club"> 
      {/* <Option>
      {currentSelect === 'club' ? <EyeOutlined style={{
        color:'#F72375',
        marginRight:12,
      }}/> : <EyeOutlined style={{
        color:'#A8A8A8',
        marginRight:12
      }}/>}
      <Title selected={currentSelect === "club"}>
             Manage
          </Title>
      </Option> */}
      </Link>
    }
    {
      (partnerType === 'game_publisher' && hasClub) && <Link  to="/brand_admin/members"> 
      {/* <Option>
      {currentSelect === 'members' ? <UsergroupAddOutlined style={{
        color:'#F72375',
        marginRight:12,
      }}/> : <UsergroupAddOutlined style={{
        color:'#A8A8A8',
        marginRight:12
      }}/>}
      <Title selected={currentSelect === "members"}>
             Members
          </Title>
      </Option> */}
      </Link>
    }
     <Link to="/brand_admin/campaigns" >
       {/* <Option >
        {
          currentSelect === "campaigns" ? <Icon src="/assets/campaign_active.svg" alt="icon"/> : <Icon src="/assets/campaigns_inactive.svg" alt="icon"/>
        }
          <Title selected={currentSelect === "campaigns"}>
              Campaigns
          </Title>
       </Option> */}
     </Link>
     <Link to="/brand_admin/search" >
       <Option >
        {
          currentSelect === "search" ? <Icon src="/assets/search_active.svg" alt="icon"/> : <Icon src="/assets/search_inactive.svg" alt="icon"/>
        }
          <Title selected={currentSelect === "search"}>
              Search
          </Title>
       </Option>
     </Link>
     {
      partnerType === 'brand' &&  <Link to="/brand_admin/products" >
      {/* Hidden because I don't want to link them to a publisher account <Option >
       {
         currentSelect === "products" ? <Icon src="/assets/product_active.svg" alt="icon"/> : <Icon src="/assets/products_inactive.svg" alt="icon"/>
       }
         <Title selected={currentSelect === "products"}>
             Products
         </Title>
      </Option> */}
    </Link>
     }
     {
      partnerType === 'game_publisher' && <Link to="/brand_admin/games" >
      <Option >
       {
         currentSelect === "games" ? <Icon src="/assets/product_active.svg" alt="icon"/> : <Icon src="/assets/products_inactive.svg" alt="icon"/>
       }
         <Title selected={currentSelect === "games"}>
             Games
         </Title>
      </Option>
    </Link>
     }
     <ButtonContainer>
      {
        userPermissionLevel !== 4 && (
        <CreditButton onClick={() => {
          //setModalOpen(true)
          window.location.href = "https://buy.stripe.com/bIY9DL2cZfPX0dW3ci"; // Replace with your desired URL
        }}>
          Upgrade to View All
        </CreditButton>
        )}
      {/* <Option style={{margin:'auto', padding:12}}>
        <img src="/assets/user_black.svg" alt="icon" style={{
          width:32,
          height:32,
          marginRight:16
        }}/>
        <Link to="/brand_admin/profile" >
          <Name >{partnerName}</Name>
        </Link>
      </Option> */}
     </ButtonContainer>
     <PaymentModal open={modalOpen} closeModal={()=> {
        setModalOpen(false);
     }}/>
  </Container>
}


const Container = styled.div`
background: #FFF;
  width: 204px;
  position: fixed;
  inset: 0%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: 108px;
  padding-bottom:64px;
  font-family:SF Pro Display;

  .anticon svg {
    width:21px;
    height:21px;
  }
`;

const Option = styled.div`
display:flex;
margin-bottom:24px;
align-items:center;
padding-left:32px;
`;

const Icon = styled.img`
width:21px;
height:21px;
margin-right:12px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
${p => p.selected ?`color: #F72375;`:
`color: #A8A8A8;`}
`;

const ButtonContainer = styled.div`
margin-top:auto;
`;

const CreditButton = styled.div`
width: 170px;
height: 32px;
margin:auto;
background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 32px;
text-align:center;
color: #FFFFFF;
margin-bottom:32px;
`;

const Name = styled.div`
font-style: normal;
font-weight: 400;
font-size: 20px;
color: #000000;
cursor:pointer;
`;



export default SideBar;