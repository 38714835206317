import React, { useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Firebase from "../../util/firebase";
import Loading from "../../util/loading";
import GameCard from './gameCard';
import PostDetail from './postDetail';

const Club = ({partner, games, history, club}) => {
  const members = useMemo(()=> {
    return (club?.influencerMembers?.length??0) + (club?.playerMembers?.length??0);
  }, [club]);

  return <Container>
    <div style={{width:'754px',display:'flex', flexDirection:'column',}}>
          <Desc>
            <div style={{display:'flex'}}>
            {
        partner?.logo && <img src={partner?.logo} alt="logo" style={{width:97}}/>
            }
            <div style={{marginLeft:'auto', marginRight:64, display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
              <DescText>
                {members}
              </DescText>
              <DescTitle>
              Members
              </DescTitle>
           </div>
           <div style={{display:'flex', flexDirection:'column', justifyContent:'space-around'}}>
           <DescText>
              {partner?.games.length}
            </DescText>
            <DescTitle>
              Games
            </DescTitle>
           </div>
            </div>
            <div style={{display:'flex', alignItems:'center', marginTop:24}}>
              <InfoText>
                {partner?.info}
              </InfoText> 
              <CreateButton onClick={() => {
                history.push("/brand_admin/club/createPost")
              }}>
                Create Post
              </CreateButton>
            </div>
          </Desc>
          <div  style={{margin:'24px 0px'}}>
            {club?.posts?.map((item) => {
              return <PostDetail postId={item}/>
            })}
          </div>
        </div>
        <div>
        <div style={{width:'372px'}}>
          {
            games.map((item) => <GameCard game={item}/>)
          }
        </div>
        </div>
  </Container>
}

const Container = styled.div`
width:1260px;
margin:48px auto;
display:flex;
justify-content:space-between;
`;

const Desc = styled.div`
width: 754px;
height: auto;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
padding:24px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const DescText = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: uppercase;
`;

const DescTitle = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: uppercase;
`;

const InfoText = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
width:324px;
`;

const CreateButton = styled.div`
width: 107px;
height: 33px;
flex-shrink: 0;
border-radius: 4px;
background: #F72375;
color: #FFF;
text-align: center;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 33px;
cursor:pointer;
margin-left:auto;
`;

export default withRouter(Club)

