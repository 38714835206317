import React, {useState} from 'react';
import styled from 'styled-components';
import { ArrowLeftOutlined, UploadOutlined} from '@ant-design/icons';
import {
  Input,
  Form,
  Button,
  Upload,
  message
} from 'antd';
import ReactPlayer from "react-player";
import firebase from "firebase/app";
import Firebase from "../../util/firebase";
import * as API from "../../util/api";
import axios from "axios";


const { TextArea } = Input;

const ViewProductForm = ({current, goBack, updateProductInArray}) => {
  const [edit, setEdit] = useState(false);
  const [form] = Form.useForm();

  const updateProduct = async (values) => {
    const productId = current.productId;
    try {
      const requestBody = {
        productId,
        updatedData: {...values}
      }
      const endPoint = API.UPDATE_PRODUCT;
      const res = await axios.post(endPoint, requestBody);
      if(res.status === 200){
        setEdit(false);
        message.success("Your Product is Updated");
        updateProductInArray(productId, {...values});
      }else {
        message.error("Failed to update the product, please try again")
      }
    }catch(err){
      message.error("Failed to update the product, please try again")
    }
  }

  const customRequestBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              bannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestMobileBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              mobileBannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestLowerBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              lowerBannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestProductUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productImage/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              productUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestVideo = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productVideo/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              video:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };


  const handleChange = async (info) => {
    if (info.file.status === "done") {
      console.log("done");
    }
    if (info.file.status === "error") {
      console.log("upload error");
    }
  };
  return <Container>
    <Top>
          <ArrowLeftOutlined style={{marginRight:8}} onClick={() => {
            goBack();
          }}/>
          Your Product
      </Top>
      {
        edit === false && <div style={{marginBottom:64}}>
        <Name>
          {current.name}
        </Name>
        <Label>
          Product Link
        </Label>
        <Input value={current?.link??""} disabled style={{display:'block'}}/>
        <Label>
         Description
        </Label>
        <TextArea
          type="text"
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
          value={current?.description??""}
          disabled
        />
        <Label>
         Large Banner image
        </Label>
        <img src={current.bannerUrl} alt="bannerUrl" style={{width:'100%'}}/>
        {
          current.mobileBannerUrl && <>
          <Label>
          mobile banner image
        </Label>
        <img src={current.bannerUrl} alt="bannerUrl" style={{width:'350px'}}/>
          </>
        }
        <Label>
        Secondary banner image
        </Label>
        <img src={current.bannerUrl} alt="bannerUrl" style={{width:'824px'}}/>
        <Label>
        Product image
        </Label>
        <img src={current.productUrl} alt="bannerUrl" style={{width:'220px'}}/>
        <Label>
        product video
        </Label>
        <ReactPlayer
              url={current.video}
              playing={false}
              width="456px"
              height="326px"
              controls
            />
          <PrimaryButton style={{marginTop:24, width:130}} onClick={() => {
            setEdit(true)
          }}>
            Edit
            </PrimaryButton>
        </div>
      }
      {
        edit === true && <Form
        name="validate_other"
        onFinish={updateProduct}
        labelCol={{ span: 24 }}
        form={form}
        initialValues={{
          name:current.name,
          link:current.link,
          description:current.description,
          bannerUrl:current.bannerUrl,
          lowerBannerUrl:current.lowerBannerUrl,
          mobileBannerUrl:current?.mobileBannerUrl??"",
          productUrl:current.productUrl,
          video:current.video
        }
        }
      >
        <Form.Item
          name="name"
          label="Product Name"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Form.Item
          name="description"
          label="Product description"
          labelAlign='Right'
        >
           <TextArea
            type="text"
            showCount
            autoSize={{ minRows: 3, maxRows: 8 }}
            maxLength={320}
          />
        </Form.Item>
  
        <Form.Item
          name="link"
          label="Product Link"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
        </Form.Item>

  
        <Form.Item
          name="bannerUrl"
          label="Banner Image"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestBannerUrl}
          showUploadList={false}>
            <UploadButton icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</UploadButton>
        </Upload>
  
        <Form.Item
          name="mobileBannerUrl"
          label="Mobile Banner Image"
          labelAlign='Right'
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestMobileBannerUrl}
          showUploadList={false}>
            <UploadButton icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</UploadButton>
        </Upload>
  
        <Form.Item
          name="lowerBannerUrl"
          label="Product Lower Banner Image"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestLowerBannerUrl}
          showUploadList={false}>
            <UploadButton icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</UploadButton>
        </Upload>
  
        <Form.Item
          name="productUrl"
          label="Product Image"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestProductUrl}
          showUploadList={false}>
            <UploadButton icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</UploadButton>
        </Upload>
  
        <Form.Item
          name="video"
          label="Product Video"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
  
        <Upload 
          onChange={handleChange}
          accept="video/*"
          customRequest={customRequestVideo}
          showUploadList={false}>
            <UploadButton icon={<UploadOutlined />} style={{marginTop:8, marginBottom:16}}>Upload from local</UploadButton>
        </Upload>
  

        <div style={{display:'flex', alignItem:'center', marginTop:16, marginBottom:64}} >
         <PrimaryButton type="primary" htmlType="submit" onClick={() => {
          form.submit()
         }}>
           Update
         </PrimaryButton>
         <SecondaryButton onClick={() => {
          setEdit(false)
         }}>
          Cancel
         </SecondaryButton>
         </div>
      </Form>
      }
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused,
.ant-input[disabled],
.ant-picker-disabled
{
  border: 1px solid #000000;
  box-shadow: none !important;
  border-radius:5px;
  color: #000000;
  height:38px;
}
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
color: #000000;
margin-bottom:12px;
`;

const Label = styled.div`
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
text-transform: uppercase;

color: #363636;
margin:12px 0px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;


const UploadButton = styled(Button)`
&:hover {
  background: #FFFFFF !important;
  color:#000000;
}
&:focus {
  background: #FFFFFF !important;
  color:#000000;
}
`;


const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border:none !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border:none !important;
  box-shadow:none;
}
`;




export default ViewProductForm

