import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Table
} from 'antd';
import moment from "moment";
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore


const ReviewCampaign = ({campaign, influencers, proposals, back}) => {

  const allocatedBudget = useMemo(() => {
    if(influencers && campaign){
      const startDate = campaign?.startDate?? "";
    const endDate = campaign?.endDate??"";
    const weeks = Math.round(moment(endDate).diff(moment(startDate), 'weeks', true));
    const budgets = influencers.reduce((acc, curr) => {
      const avg_viewers = curr.metricsData?.avg_viewers??"-";
      const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
    const pricePerStream = curr.pricePerQuest ? curr.pricePerQuest:estimatedPrice;
    const price = pricePerStream*weeks;
        return acc + price;
    }, 0);
    return Math.ceil(budgets);
    }else {
      return 0;
    }
  }, [influencers, campaign]);

  const remainingBudget = useMemo(() => {
    if(campaign){
      return (campaign?.budget??0) - (allocatedBudget??0); 
    }else {
      return 0;
    }
  }, [campaign, allocatedBudget]);

  const totalViews = useMemo(() => {
    const views = influencers.reduce((acc, curr) =>{
      return acc + (curr?.metricsData?.views_total??0);
    }, 0);
    return views;
  }, [influencers]);

  const totalFollowers =  useMemo(() => {
    const followers = influencers.reduce((acc, curr) =>{
      return acc + curr?.metricsData?.followers?? 0;
    }, 0);
    return followers;
  }, [influencers]);

  const totalCCV = useMemo(() => {
    const ccvs = influencers.reduce((acc, curr) =>{
      return acc + curr?.metricsData?.avg_viewers?? 0;
    }, 0);
    return ccvs;
  }, [influencers]);

  const estQuests = useMemo(() => {
    if(campaign && influencers){
    const startDate = campaign?.startDate?? "";
    const endDate = campaign?.endDate??"";
    const weeks = Math.round(moment(endDate).diff(moment(startDate), 'weeks', true));
    const quests = influencers.length * weeks;
    return quests;
    }
    return 0;
  }, [campaign, influencers]);

  const list = useMemo(() => {
    const arr = [];
    influencers.forEach((item) => {
      const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId} = item;
      const avg_viewers = metricsData?.avg_viewers??"-";
      const followers = metricsData?.followers??"-";
      const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
      const pricePerStream = pricePerQuest ? pricePerQuest:estimatedPrice;
      const obj = {
        location,
        pricePerStream,
        description,
        name,
        avg_viewers,
        followers,
        profile_image_url,
        categories: item.categories??[],
        language,
        influencerId
      }
      arr.push(obj)
    });
    return arr;
  }, [influencers]);

  const columns = [
    {
      title:"Influencers",
      key:"description",
      render: (record) => {
        return <ProfileContainer>
          <ProfileImage src={record.profile_image_url} alt="logo"/>
          <div>
            <ProfileName >
                {record.name}
            </ProfileName>
           <div style={{margin:"4px 0px", display:'flex', flexWrap:'wrap'}}>
              {
                record.categories.map((item) => <ProfileTag>
                  {item}
                </ProfileTag>)
              }
           </div>
            <ProfileDesc>
                {record.description}
            </ProfileDesc>
          </div>
        </ProfileContainer>
      }
    },
    {
      title:"Proposed Time (Repeats Weekly)",
      key:'time',
      render: () => {
        return <div>-</div>
      }
    },
    {
      title:"Average Concurrent Viewers",
      key:"averageViwers",
      render:(record) => {
        return <Followers>
          <span style={{display:'inline-block'}}>{record.avg_viewers}</span>
        </Followers>
      }
    },
    {
      title:"Price(Monthly)",
      key:'price',
      render:(record) => {
        return <Followers>
          <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
          {record.pricePerStream?? 0}
        </Followers>
      }
    },
    {
      title:"",
      key:"operation",
      render: (record) => {
       
      }
    },
  ]

  return <Container>
     <Top>
          <ArrowLeftOutlined style={{marginRight:8}}/>
          Review campaign before launch
      </Top>
      <TopContainer>
              <Title>
              {campaign?.name??""}
              </Title>
                <SecondaryButton onClick={() => {
                  
                }}
                style={{marginLeft:'auto'}}
                >
                 Confirm All & launch
                </SecondaryButton>
          </TopContainer>
          <Credits>
          <Credit style={{borderRight:"1px solid #A8A8A8"}}>
            <span style={{display:'inline-block'}}>
              Allocated Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {allocatedBudget}
              </span>
            </div>
          </Credit>
          <Credit>
          <span style={{display:'inline-block', marginLeft:8}}>
          Est. Remaining of Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {remainingBudget}
              </span>
            </div>
          </Credit>
        </Credits>
        <Details>
          <DetailBox>
            <BoxContent>
                    {influencers.length}
                  </BoxContent>
                  <BoxTitle>
                  Influencers
                  </BoxTitle>
          </DetailBox>
          <DetailBox>
            <BoxContent>
                    {totalViews}
                  </BoxContent>
                  <BoxTitle>
                  est. Total Views
                  </BoxTitle>
          </DetailBox>
          <DetailBox>
           <BoxContent>
                    {totalFollowers}
                  </BoxContent>
                  <BoxTitle>
                  Total Followers
                  </BoxTitle>
          </DetailBox>
          <DetailBox>
                <BoxContent>
                    {totalCCV}
                  </BoxContent>
                  <BoxTitle>
                  total avg CCV
                  </BoxTitle>
              </DetailBox>
              <DetailBox>
              <BoxContent>
                  {estQuests}
                  </BoxContent>
                  <BoxTitle>
                  EST.QUESTS
                  </BoxTitle>
              </DetailBox>
              <DetailBox>
                 <BoxContent>
                  {(allocatedBudget/campaign.budget)*100} %
                  </BoxContent>
                  <BoxTitle>
                  Budget allocated
                  </BoxTitle>
              </DetailBox>
        </Details>
        <Table columns={columns} dataSource={list} />
  </Container>

}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const TopContainer = styled.div`
display:flex;
margin:24px 0px;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const SecondaryButton = styled(Button)`
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

const Credits = styled.div`
display:flex;
width: 576px;
height: 41px;
background: #FFFFFF;
border-radius: 7px;

font-weight: 600;
font-size: 14px;
color: #000000;
padding:8px;
`;

const Credit = styled.div`
display:flex;
width:50%;
justify-content:space-between;
align-items:center;
`;

const Details = styled.div`
display:flex;
justify-content:space-between;
margin:24px 0px;
`;

const DetailBox = styled.div`
width: 180px;
height: 76px;

background: #FFFFFF;
border-radius: 10px;
padding:12px;
display:flex;
flex-direction:column;
justify-content:space-between;
`;

const BoxContent = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
text-transform: uppercase;
color: #000000;
`;

const BoxTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 12px;
leading-trim: both;
text-edge: cap;
text-transform: uppercase;

color: #000000
`;

const ProfileContainer = styled.div`
width:280px;
height:auto;
display:flex;
max-height:120px;
`;

const ProfileImage = styled.img`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

export default withRouter(ReviewCampaign)