import React, {useState, useEffect} from 'react';
import Firebase from "../../util/firebase";
import InfluencerList from './influencerList';
import InfluencerDetail from './influencerDetail';

const Invite = ({tempDetail, saveInflueners, unMutableInfluencersList, addToList, removeFromList, selectedList, editExisting}) => {
  const [view, setView] = useState(1);
  const [influencers, setInfluencers] = useState([]);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const snapshot = await Firebase.firestore()
        .collection("influencer")
        .get();
        let resultArray = [];

      snapshot.forEach((doc) => {
      const obj ={
        influencerId:doc.id,
        ...doc.data()
      };
      resultArray.push(obj);
      });
      setInfluencers(resultArray);
      }catch(err){
        console.log(err);
      }
    }
    fetchInfluencers()
  }, []);

  const viewInfluencerDetail = (influencerId) => {
    const influencer = influencers.find(item => item.influencerId === influencerId);
    setCurrent(influencer);
    setView(2);
  }
  
  // save selected influencers and return to index page control
  const reviewCampaign = () => {
    const list = selectedList.concat(unMutableInfluencersList);
    const selectedInfluencers = list.reduce((acc, curr) => {
      const influencer = influencers.find((item) => item.influencerId === curr);
      if(influencer){
        acc.push(influencer);
        return acc;
      }else {
        return acc;
      }
    },[]);
    saveInflueners(selectedInfluencers);
  }
  return <>
  {
    view === 1 && <InfluencerList 
    influencers={influencers} 
    viewInfluencerDetail={viewInfluencerDetail} 
    selectedList={selectedList} 
    addToList={addToList} 
    removeFromList={removeFromList}
    tempDetail={tempDetail}
    reviewCampaign={reviewCampaign}
    unMutableInfluencersList={unMutableInfluencersList}
    editExisting={editExisting}
    />
  }
  {
    view === 2 && <InfluencerDetail current={current} back={() => {
      setView(1);
      setCurrent(null)
    }}
    unMutableInfluencersList={unMutableInfluencersList}
    selectedList={selectedList} 
    addToList={addToList} 
    removeFromList={removeFromList}
    />  
  }
  </>
}

export default Invite;