import React, { useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Table,
} from 'antd';
import Firebase from "../../util/firebase";
import {
  PlusOutlined
} from '@ant-design/icons';
import Loading from "../../util/loading";
import ViewProductForm from "../campaignComponents/viewProductForm"

const Products = ({history, partner}) => {
  const [products, setProducts] = useState([]);
  const [loading ,setLoading] = useState(false);
  const [view, setView] = useState(1);
  const [current, setCurrent] = useState(null);
  useEffect(() => {
    const fetchProducts = async () => {

      const snapshot = await Firebase.firestore()
        .collection("partnerProducts")
        .where("companyId", "==", partner?.partnerId??"")
        .get();

        let resultArray = [];

        snapshot.forEach((doc) => {
          const product ={
            productId:doc.id,
            ...doc.data()
          };
          resultArray.push(product);
        });
      setProducts(resultArray);
      setLoading(false);
    };
    
    fetchProducts()
  },[partner]);

  const updateProductInArray = (productId, data) => {
    const index = products.findIndex(item => item.productId === productId);
    const newArray = products.with(index, {
      ...data,
      productId
    });
    setProducts(newArray)
  }

 


  const columns = [
   
    {
      title:"Product Name",
      key:"name",
      render:(record) => {
        return <Name>
         {record.name}
        </Name>
      }
    },

    {
      title:"Description",
      key:'description',
      render:(record) => {
        return <Description>
          {record.description}
        </Description>
      }
    },
    {
      title:"Image",
      key:'productUrl',
      render:(record) => {
        return <img src={record.productUrl} style={{width:100}} alt="productUrl"/>
      }
    },
    {
      title:"",
      key:"operation",
      render: (record) => {
        return <PrimaryButton style={{width:130}} onClick={() => {
          setCurrent(record);
          setView(2)
        }}>
          View
        </PrimaryButton>
      }
    },

  ]

  return <Container>
    {
      loading ? <Loading /> : <>
      {
        view === 1 && <>
      <TopContainer>
      <div>
        <Title>
        Products
        </Title>
        <Subtitle>
        Things you want influencers to promote
        </Subtitle>
        </div>
        <PrimaryButton style={{marginLeft:'auto'}} onClick={() => {
            history.push("/brand_admin/products/create")
          }}>
            Create Product <PlusOutlined />
          </PrimaryButton>
    </TopContainer>
    <Table columns={columns} dataSource={products} />
        </>
      }
      {
        view ===2 && <>
          <ViewProductForm current={current} goBack={() => {
            setView(1);
            setCurrent(null)
          }} 
          updateProductInArray={updateProductInArray}
          />
        </>
      }
      </>
    }
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;


.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const TopContainer = styled.div`
display:flex;
margin:24px 0px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;

const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #000000;
`;

const Description = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 19px;
width:300px;
`;

export default withRouter(Products);