import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import BrandNav from './component/brandNav';
import Firebase from "../util/firebase";
import { Route, BrowserRouter as Router} from "react-router-dom";
import SideBar from './component/sidebar';
import CampaignDetail from './campaignDetail';
import Campaigns from './campaigns';
import Products from './products/index';
import Games from "./games/index";
import GameDetail from './games/gameDetail';
import CreateProduct from "./products/createProduct";
import CreateGame from './games/createGame';
import CreateGameByAppId from './games/createGameByAppId';
import BrandProfile from './campaignComponents/brandProfile';
import SearchInfluencer from "./campaignComponents/searchInfluencer";
import ClubDetail from "./club"
import CreatePost from './club/createPost';
import Members from './club/members';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { useJune } from '../util/useJune';




const Board = ({history, location}) => {
  const { loggedIn, profile, id: userId } = useSelector((state) => state.user);
  const [partner, setPartner] = useState(null);
  const [games, setGames] = useState([]);
  const [club, setClub] = useState(null);
  const [influencers, setInfluencers] = useState([])

  const currentSelect = useMemo(() => {
    const pathName = location.pathname;
    const arr = pathName?.split("/")?? [];
    if(arr && arr[2]){
      return arr[2]
    }else {
      return null;
    }
  }, [location]);

  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const snapshot = await Firebase.firestore()
        .collection("influencer")
        .get();
        let resultArray = [];

      snapshot.forEach((doc) => {
      const obj ={
        influencerId:doc.id,
        ...doc.data()
      };
      if(!obj.archieved){
        resultArray.push(obj);
      }
      });

      setInfluencers(resultArray);
      }catch(err){
        console.log(err);
      }
    }
    fetchInfluencers()
  }, []);

  useEffect(() => {
    let listener = null;
    if(!loggedIn /*|| !profile.adminOfPartner || profile.permission_level !== 4*/){
      history.push("/")
    }
    else {
      const partnerId = profile.adminOfPartner;
      listener = Firebase.firestore().collection("partners").doc(partnerId).onSnapshot((doc) => {
        const obj = {...doc.data(), partnerId};
        setPartner(obj);
     })
    }
    return () => {
      if(listener){
        listener();
      }
    }
  }, [loggedIn, history, profile]);

  useEffect(() => {
    let listener = null
    const fetchCommunity = (communityId) => {
      listener = Firebase.firestore().collection("publisherCommunity").doc(communityId).onSnapshot((snapshot) => {
        if (snapshot.exists) {
          // Document exists, update the state with the document data
          setClub({
            ...snapshot.data(),
            clubId:communityId
          });
        } else {
          // Document doesn't exist
          setClub(null);
        }
      });
    }
    if(partner && partner.communityId){
      fetchCommunity(partner.communityId);
    }
    return () => {
      if(listener){
        listener();
      }
    }
  }, [partner]);

  const credit = useMemo(() => {
    if(partner && partner.credit){
      return partner.credit;
    }
    return 0;
  }, [partner]);

  useEffect(() => {
    const fetchGames = async () => {

      const snapshot = await Firebase.firestore()
        .collection("partnerGames")
        .where("companyId", "==", partner?.partnerId??"")
        .get();

        let resultArray = [];

        snapshot.forEach((doc) => {
          const product ={
            gameId:doc.id,
            ...doc.data()
          };
          resultArray.push(product);
        });
      setGames(resultArray);
    };

    if(partner){
      fetchGames();
    }
    
  },[partner]);


  return <Container>
    <BrandNav credit={credit}/>
    <SideBar currentSelect={currentSelect} partner={partner}/>
    <Content>
      <Route exact path={"/brand_admin/campaigns"} 
       component={() => <Campaigns partner={partner} />} />
      <Route exact path={"/brand_admin/campaigns/:campaignId"} component={CampaignDetail} />
      <Route path={"/brand_admin/search"} component={() => <SearchInfluencer influencers={influencers}/>} />
      <Route exact path={"/brand_admin/products/create"} component={() => <CreateProduct partner={partner} />} />
      <Route exact path={"/brand_admin/products"} component={() => <Products partner={partner} />} />
      <Route exact path={"/brand_admin/games/gameDetail/:gameId"} component={() => <GameDetail partner={partner} />} />
      <Route exact path={"/brand_admin/games/create"} component={() => <CreateGameByAppId partner={partner} />} />
      <Route exact path={"/brand_admin/games"} component={() => <Games partner={partner} games={games}/>} />
      <Route exact path={"/brand_admin/profile"} component={() => <BrandProfile partner={partner} />} />
      <Route exact path={"/brand_admin/club"} component={() => <ClubDetail partner={partner} games={games} club={club}/>} />
      <Route exact path={'/brand_admin/members'} component={() => <Members partner={partner} club={club} influencers={influencers} />} />
      <Route exact path={"/brand_admin/club/createPost"} component={() => <CreatePost partner={partner} club={club}/>} />
      {/* <Route path="/" element={<Navigate replace to="/brand_admin/campaigns" />} /> */}
    </Content>
  </Container>
}


const Container = styled.div`
width: 100%;
overflow-y: auto;
overflow-x: hidden;
background: #ffffff 0% 0% no-repeat padding-box;
position: relative;
`;


const Content = styled.div`
background-color: #F6F6F6;
min-height:calc(100vh);
width: 100%;
padding-top:108px;
padding-left:320px;
`;



export default withRouter(Board);