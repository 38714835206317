import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";
import BrandNav from '../component/brandNav';
import Firebase from "../../util/firebase";
import { withRouter } from "react-router-dom";
import SideBar from './sidebar';
import SelectProduct from './selectProduct';
import SelectGame from './selectGame';
import CampaignDetail from './campaignDetailForm';
import moment from "moment";
import Invite from './invite';
import ProposalReview from "./proposalReview";
import * as API from "../../util/api";
import { Mixpanel } from '../../util/mixpanel';

import axios from "axios";
import {
  message
} from 'antd';

const CreateCampaign = ({history, partner}) => {

  const [step, setStep] = useState(1);
  const [selectProduct, setSelectProduct] = useState(null);
  const [selectGame, setSelectGame] = useState(null);
  const [tempDetail, setTempDetail] = useState(null);
  const [selectInfluencers, setSelectedInfluencers] = useState([]);


  useEffect(()=> {
    if(partner){
      Mixpanel.track("[Publisher-Portal] Create campaign page", {
        partnerId:partner.partnerId,
        partnerName:partner.name
      })
    }
  }, [partner])

  // temp list
  const [selectedList, setSelectedList] = useState([]);

  const addToList = (influencerId) => {
    setSelectedList(list => [...list, influencerId])
  };
  const removeFromList = (influencerId) => {
    setSelectedList(list => list.filter(item => item !== influencerId))
  };

  const type = partner?.type??"brand";

  const credit = useMemo(() => {
    if(partner && partner.credit){
      return partner.credit;
    }
    return 0;
  }, [partner]);

  const saveSelectProduct = (product) => {
    setSelectProduct(product);
    setStep(2)
  }

  const saveSelectGame = (game) => {
    setSelectGame(game);
    setStep(2);
  }

  const saveTempDetail = (values) => {
    const date = values.date;
    const startDate = moment(date[0]).format();
    const endDate = moment(date[1]).format();
    setTempDetail({startDate, endDate, name: values.name, description: values.description, category:values.category.value, budget:values.budget});
    setStep(3);
    /* setStep(3); */
  };

  const saveForLater = async (values) => {
    const date = values.date;
    const startDate = moment(date[0]).format();
    const endDate = moment(date[1]).format();
    const campaign = {
      startDate,
      endDate,
      name:values.name,
      description:values.description,
      category:values.category.value, 
      budget:values.budget,
      partnerId:partner.partnerId,
    }
    if(type === 'brand'){
      campaign.type = 'brand';
      campaign.productId = selectProduct;
    }
    if(type === 'game_publisher'){
      campaign.type = 'game';
      campaign.gameId = selectGame;
    }
    const endPoint = API.SAVE_CAMPAIGN_FOR_LATER;
    try {
      const res = await axios.post(endPoint, campaign);
      if(res.status === 200){
        const {campaignId} = res.data;
        message.success("Campaign created! You will be redirected to the detail page");
        setTimeout(() => {
          history.push(`/brand_admin/campaigns/${campaignId}`)
        }, 2000)
      }else {
        message.error("Failed to send Proposals. Please try again")
      }
    }catch(err){
      message.error("Failed to send Proposals. Please try again")
    }
  }
    const updateTempDetail = (values) => {
    const date = values.date;
    const startDate = moment(date[0]).format();
    const endDate = moment(date[1]).format();
    const budgetInCredit =(values.budget)*10
    setTempDetail({startDate, endDate, name: values.name, description: values.description, category:values.category.value, budget:budgetInCredit});
  }

  const saveInflueners = (influeners) => {
    setSelectedInfluencers(influeners);
    setStep(4);
  }

  return <Container>
     <BrandNav credit={credit}/>
     <SideBar step={step}/>
     <Content>
     {
      step === 1 && type ==='brand' &&  <SelectProduct partner={partner} saveSelectProduct={saveSelectProduct}/>
     }
     {
      step === 1 && type ==='game_publisher' &&  <SelectGame partner={partner} saveSelectGame={saveSelectGame}/>
     }
     {
      step === 2 && <CampaignDetail saveTempDetail={saveTempDetail} saveForLater={saveForLater}/>
     }
     {
      step === 3 && <Invite partner={partner} 
      tempDetail={tempDetail} 
      saveInflueners={saveInflueners} 
      selectedList={selectedList}
      addToList={addToList}
      removeFromList={removeFromList}
      editExisting={false}
      saveForLater={saveForLater}
      />
     }
     {
      step === 4 && <ProposalReview tempDetail={tempDetail} selectInfluencers={selectInfluencers} 
      partner={partner}
      updateTempDetail={updateTempDetail}
      selectProduct={selectProduct}
      selectGame={selectGame}
      campaignMutable={true}
      editExisting={false}
      backToSelectInfluencers={() => {
        setStep(3)
      }}
      />
     }
     </Content>
  </Container>

}

const Container = styled.div`
width: 100%;
overflow-y: auto;
overflow-x: hidden;
background: #F6F6F6 0% 0% no-repeat padding-box;
position: relative;
`;


const Content = styled.div`
background-color: #F6F6F6;
height:calc(100vh);
width: 100%;
padding-top:108px;
padding-left:320px;
`;

export default withRouter(CreateCampaign)
