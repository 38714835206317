import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Input, Button, Table, List, Rate } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import countryList from 'react-select-country-list';
import { categoriesOption, CCVOptions, followerOptions, sortedByCVV, sortedByFollowers } from "../createCampaign/option";
import Select from 'react-select';
import moment from "moment";
import ISO6391 from 'iso-639-1';
import { createClient } from '@supabase/supabase-js';
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore
import { LockOutlined } from '@ant-design/icons';

const supabaseUrl = 'https://yeyotfuxvnzyzelrakwi.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlleW90ZnV4dm56eXplbHJha3dpIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODYyNDI1MzEsImV4cCI6MjAwMTgxODUzMX0.KiFzhtS6VPBlQX9MnkE8aSQdvwcryh4ipJPV5DyXk2g';
const supabase = createClient(supabaseUrl, supabaseKey);

const InfluencerList = ({influencers, viewInfluencerDetail, }) => {
  const [searchInput, setSearchInput] = useState([]);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const [countryFilter, setCountryFilter] = useState([]); 
  const [gameOptions, setGameOptions] = useState([]);
  const [influencersDan, setInfluencers] = useState([]);

  // Analytics
  const trackJuneEvent = () => {
    const user = firebase.auth().currentUser;
    const userEmail = user ? user.email : null;
  
    if (userEmail) {
      window.june.track('Upgrade Button Clicked', {
        email: userEmail, // Add the user's email as a property
        // Add any additional event properties here
      });
    } else {
      console.log('User is not logged in or email is not available');
    }
  };
  
  
  const languageName = ISO6391.getAllNames()??[];

  // For only displaying by permission level
  const [filteredInfluencers, setFilteredInfluencers] = useState([]);

  // New state for pagination and loading
  const [displayedInfluencers, setDisplayedInfluencers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const observer = useRef();


  const languageOptions = languageName.map((key) => {
    return {
      value: key,
      label: key
    }
  })

  const [ccvFilter, setCCVFilter] = useState([]);
  const [followerFilter, setFollowerFilter] = useState([]);
  const [searchFilter, setSearchFilter] = useState(false);
  const [languageFilter, setLanguageFilter] = useState([]);
  // Added by Daniel
  const [gameFilter, setGameFilter] = useState([]);

  // Added by Daniel
  useEffect(() => {
    const fetchGames = async () => {
      const { data, error } = await supabase
        .from('games_tracking')
        .select('app_id, game_name')
        .eq('show_filter', true); // Add this line to filter games where show_filter is TRUE
  
      if (error) {
        console.error('Error fetching games:', error);
      } else {
        const options = data.map((game) => ({
          value: game.app_id,
          label: game.game_name,
        }));
        setGameOptions(options);
      }
    };
  
    fetchGames();
  }, []);
  


  // Added for pagenation purposes
  const pageSize = 200;
  const [allInfluencers, setAllInfluencers] = useState([]);
  const [hasMore, setHasMore] = useState(true);


  // Get user permission level
  const [userPermissionLevel, setUserPermissionLevel] = useState(null);

  useEffect(() => {
    const fetchUserPermissionLevel = async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const userDoc = await firebase.firestore().collection('users').doc(user.uid).get();
        if (userDoc.exists) {
          setUserPermissionLevel(userDoc.data().permission_level);
        }
      }
    };

    fetchUserPermissionLevel();
  }, []);

  // useEffect(() => {
  //   const fetchInfluencers = async () => {
  //     const { data, error } = await supabase.from('influencers').select('*');
  //     if (error) {
  //       console.error('Error fetching influencers:', error);
  //     } else {
  //       const filteredData = applyFiltersAndSlice(data);
  //       setFilteredInfluencers(filteredData);
  //     }
  //   };
  //   fetchInfluencers();
  // }, [userPermissionLevel]); // Add userPermissionLevel as a dependency if it's a state or prop
  
  // Only show 9 if the permission levele is != 4
  useEffect(() => {
    const fetchInfluencers = async () => {
      const { data, error } = await supabase
        .from('influencers')
        .select('*')
        .gte('avg_viewers', 40);
  
      if (error) {
        console.error('Error fetching influencers:', error);
      } else {
          setInfluencers(data);
      }
    };
  
    fetchInfluencers();
  }, [userPermissionLevel]);

  

  const lastInfluencerRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newDisplayed = allInfluencers.slice(0, displayedInfluencers.length + pageSize);
          setDisplayedInfluencers(newDisplayed);
          setHasMore(newDisplayed.length < allInfluencers.length);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore, allInfluencers, displayedInfluencers.length]
  );

  // Added by Daniel
  useEffect(() => {
    const fetchInfluencers = async () => {
      const { data, error } = await supabase
        .from('influencers')
        .select('*'); // Adjust the select to fetch the columns you need
  
      if (error) {
        console.error('Error fetching influencers:', error);
      } else {
        // Here we assume you are receiving the data in the format needed
        // If the data needs to be reformatted, do so before setting the state
        setInfluencers(data);
      }
    };
  
    fetchInfluencers();
  }, []);

  // Adjusted by Daniel

  const list = useMemo(() => {
    const arr = [];
    influencersDan.forEach((influencerData) => {
      // Assuming 'metricsData' comes from a separate table that you've already joined/merged with the 'influencers' data
      const { id, created_at, influencer_id, influencer_name, game_ids, rating, contact_email, avg_viewers, followers, max_viewers, twitch_url, profile_image } = influencerData;
      const gameIdsArray = influencerData.game_ids || [];

    
      // Create a simplified object based on the above properties
      const obj = {
        // Use 'influencer_name' instead of 'name'
        name: influencer_name,
        game_ids: gameIdsArray,
        // 'location' is not provided in your data model, you need to adjust accordingly
        location: 'Location not available', // Placeholder
        description: 'Description not available', // Placeholder or add this field to your model
        // Use 'avg_viewers' and 'followers' directly without '??"-"', since your data seems to have these fields
        avg_viewers: avg_viewers,
        followers: followers,
        // Use 'profile_image' instead of 'profile_image_url'
        profile_image_url: profile_image,
        language: 'Language not available', // Placeholder or add this field to your model
        // 'pricePerQuest' is not provided in your data model, you need to adjust accordingly
        pricePerStream: 0, // Placeholder or calculate this value based on your model
        influencerId: id, // Use 'id' as the unique identifier for 'influencerId'
        twitchUrl: twitch_url,
        // 'categories' is not provided in your data model, you need to adjust accordingly
        categories: [], // Placeholder or add this field to your model
        // 'priceSet' depends on whether 'pricePerQuest' is provided or not
        priceSet: false // Placeholder or adjust based on your model
      };
  
      arr.push(obj);
    });
    return arr;
  }, [influencersDan]);

  const sortedList = useMemo(() => {
    let sortedList = list.filter(influencer => influencer.avg_viewers > 40); // Filter influencers with avg_viewers > 40
    
    // Removed old filters for language and country

    // Daniel changes for game filter March 17, 2024
    if (gameFilter && gameFilter.length > 0) {
      const selectedGameIds = gameFilter.map((game) => Number(game.value)); // Convert the selected game IDs to numbers
      sortedList = sortedList.filter((influencer) => {
        return influencer.game_ids.some(gameId => selectedGameIds.includes(gameId)); // Compare numbers with numbers
      });
    }

    if(ccvFilter && ccvFilter.length > 0){
      const availableCVV = ccvFilter.reduce((acc, curr) => {
        acc.push(curr.value);
        return acc;
      }, []);
      sortedList = sortedByCVV(availableCVV, sortedList);
    }
    if(followerFilter && followerFilter.length > 0){
      const availablefollowers = followerFilter.reduce((acc, curr) => {
        acc.push(curr.value);
        return acc;
      }, []);
      sortedList = sortedByFollowers(availablefollowers, sortedList)
    }
    if(searchFilter && searchInput){
     sortedList = sortedList.filter(item => item.name.toUpperCase().includes(searchInput.toUpperCase()));
    }
    if (userPermissionLevel !== 4) {
      sortedList = sortedList.slice(0, 6);
    }
    return sortedList;
  }, [list, gameFilter, followerFilter, ccvFilter,  searchFilter, searchInput, languageFilter]);



  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width:256,
      height: "auto",
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000",
      borderRadius: "5px",
      boxShadow: "none",
      '&:hover': {
        border: '1px solid #000000',
     }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
      };
    },
  };

  const getFilteredList = (list) => {
    const string = list.reduce((acc, curr) => {
      return `\xa0 ${acc} ${curr.label} \xa0`
    }, '') ;
    return string;
  };



  return <Container>
    <TopContainer>
      <div>
        <Title>
          Search Influencers
        </Title>
        <Subtitle>
        Find who you want in your campaign!
        </Subtitle>
        </div>
        
    </TopContainer>
    <SearchContainer>
      <SearchTop>
        <SearchInput onChange={(e) => {
          setSearchInput(e.target.value);
          setSearchFilter(false);
        }}
        value={searchInput}
        placeholder='Search Influencer...'
        >
        </SearchInput>
        <PrimaryButton onClick={() => {
          setSearchFilter(true);
        }}>
          Search
        </PrimaryButton>
       {
        searchFilter && searchInput && <SecondaryButton onClick={() => {
          setSearchFilter(false);
          setSearchInput("")
        }}>
          Clear
        </SecondaryButton>
       }
      </SearchTop>
      <Filters>
          <div style={{width:"50%"}}>
            <FilterTitle>
              Filter By Game
            </FilterTitle>
            <div style={{marginTop:8}}>
              {/* { <div style={{display:'inline-block', marginRight:16}}>
              <Select options={countryOptions} 
                  isClearable={true}
                  isMulti
                  styles={colourStyles}
                  placeholder="Location"
                  value={countryFilter}
                  onChange={(items) => {
                    setCountryFilter(items)
                  }}
                  />
              </div> } */}
              <div style={{display:'inline-block', marginRight:16}}>
                <Select options={gameOptions} 
                  isClearable={true}
                  isMulti
                  styles={colourStyles}
                  placeholder="Select Similar Game"
                  value={gameFilter}
                  onChange={(item) => {
                    setGameFilter(item)
                  }}
                />
              </div>

            </div>
          </div>
          <div style={{width:"50%"}}>
          <FilterTitle>
              Filter By Performance
            </FilterTitle>
            <div style={{marginTop:8}}>
              <div style={{display:'inline-block', marginRight:16}}>
                  <Select options={followerOptions} 
                isClearable={true}
                isMulti
                styles={colourStyles}
                placeholder="Followers"
                value={followerFilter}
                onChange={(items) => {
                  setFollowerFilter(items)
                }}
                />
              </div>
              <div style={{display:'inline-block'}}>
              <Select options={CCVOptions} 
                  isClearable={true}
                  isMulti
                  styles={colourStyles}
                  placeholder="Average Concurrent Viewers"
                  value={ccvFilter}
                  onChange={(items) => {
                    setCCVFilter(items);
                  }}
                  />
              </div>
            </div>
          </div>
      </Filters>
      <FilterContainer>
          <FilterTitle style={{marginRight:12}}>
            Filters Applied:
          </FilterTitle>
          {
            countryFilter && countryFilter.length > 0 && <AppliedFilter>
              Creator Location: {getFilteredList(countryFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setCountryFilter([])
              }}
              />
            </AppliedFilter>
          }
          {
            languageFilter && languageFilter.length > 0 && <AppliedFilter>
              Creator Game: {getFilteredList(languageFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setLanguageFilter([])
              }}
              />
            </AppliedFilter>
          }
          {
            followerFilter && followerFilter.length > 0 && <AppliedFilter>
              Followers : {getFilteredList(followerFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setFollowerFilter([])
              }}/>
            </AppliedFilter>
          }
          {
            ccvFilter && ccvFilter.length > 0 && <AppliedFilter>
              Followers : {getFilteredList(ccvFilter)}
              <CloseOutlined style={{display:'inline-block', marginLeft:16, cursor:'pointer'}}
              onClick={() => {
                setCCVFilter([])
              }}/>
            </AppliedFilter>
          }
          <Reset onClick={() => {
            setLanguageFilter([])
            setCCVFilter([]);
            setFollowerFilter([]);
          }}>
            Reset Filters
          </Reset>
      </FilterContainer>
    </SearchContainer>
    
    <List
      grid={{ gutter: 16, column: 3 }}
      dataSource={sortedList}
      renderItem={(item, index) => {
        // Conditionally assign the ref to the last item
        const itemRef = index === sortedList.length - 1 ? lastInfluencerRef : null;

        return (
          <List.Item ref={itemRef}>
            <CardContainer>
              <div style={{ display: 'flex' }}>
                <ProfileImage src={item.profile_image_url ?? ""} alt="profile" />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <ProfileName>{item.name}</ProfileName>
                  <ProfileLink>
                    <a href={item.twitchUrl} target="_blank" rel="noopener noreferrer">
                      Click here to view Twitch Channel
                    </a>
                  </ProfileLink>
                  <div style={{ display: 'flex', flexWrap: 'wrap', minHeight: 16 }}>
                    {item.categories.map((category, index) => (
                      <ProfileTag key={index}>{category}</ProfileTag>
                    ))}
                  </div>
                </div>
              </div>
              <ProfileDesc style={{ marginTop: 16 }}>{item.description}</ProfileDesc>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '24px 0px' }}>
                <div style={{ width: '30%', textAlign: 'left', borderRight: '2px solid rgba(0, 0, 0, 0.42)' }}>
                  <ItemContent>
                    <img src="/assets/twitch_icon.png" alt="twitch" style={{ width: 12, height: 12, marginRight: 4 }} /> {item.followers}
                  </ItemContent>
                  <ItemTitle>Followers</ItemTitle>
                </div>
                <div style={{ width: '35%', textAlign: 'left', borderRight: '2px solid rgba(0, 0, 0, 0.42)', paddingLeft: 8 }}>
                  <ItemContent>
                    <img src="/assets/twitch_icon.png" alt="twitch" style={{ width: 12, height: 12, marginRight: 4 }} /> {item.avg_viewers}
                  </ItemContent>
                  <ItemTitle>Avg CCV</ItemTitle>
                </div>
                <div style={{ width: '35%', textAlign: 'left', paddingLeft: 8 }}>
                  <ItemContent>
                    <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{ width: 18, height: 18, marginRight: 4 }} />
                    {item.avg_viewers * 1.5}
                  </ItemContent>
                  <ItemTitle>Est. Rate Per Hour</ItemTitle>
                </div>
              </div>
            </CardContainer>
          </List.Item>
        );
      }}
    />
    {/* Conditionally render the upgrade box */}
    {userPermissionLevel !== 4 && (
      <a href="https://buy.stripe.com/bIY9DL2cZfPX0dW3ci" target="_blank" style={{ textDecoration: 'none' }}>
        <UpgradeBox style={{ fontSize: '24px', color: '#000000', marginBottom: '10px' }} onClick={trackJuneEvent}>
          <LockOutlined style={{ fontSize: '40px', color: '#000000', marginBottom: '10px' }} />
          Upgrade to View the Full List
        </UpgradeBox>
      </a>
    )}



  </Container>
}

// Bot to redirect users to upgrade to view the full list
const UpgradeBox = styled.div`
  width: 100%;
  height: 300px; /* Adjust the height to be equal to two rows of influencer data */
  background-image: url('/assets/blurbg.png'); /* Add your background image */
  background-size: cover; /* Cover the entire area of the box */
  background-position: center; /* Center the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack items vertically */
  border-radius: 10px;
  margin-top: 20px;
  font-size: 20px; /* Adjust font size if needed */
  font-weight: bold;
  color: #00000; /* Change text color to contrast with the background */
  position: relative; /* Needed for positioning the lock icon */
  cursor: pointer; 
`;


const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused
{
  background:#F3F2F3;
  border:none !important;
  box-shadow:none !important;
  border-radius:4px;
}

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}

.ant-rate {
  font-size:14px;
}
`;

const TopContainer = styled.div`
display:flex;

`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const Credits = styled.div`
margin-left:auto;
display:flex;
width: 576px;
height: 41px;
background: #FFFFFF;
border-radius: 7px;

font-weight: 600;
font-size: 14px;
color: #000000;
padding:8px;
`;

const Credit = styled.div`
display:flex;
width:50%;
justify-content:space-between;
align-items:center;
`;


const SearchContainer = styled.div`
margin-top:24px;
width:100%;
height:auto;
background: #FFFFFE;
border: 1px solid #D9D9D9;
border-radius: 10px;
margin-bottom:24px;
`;

const SearchTop = styled.div`
display:flex;
height:68px;
border-bottom: 1px solid #D9D9D9;
padding:18px 24px;
`;

const SearchInput = styled(Input)`
width:325px;
margin-right:16px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;


const Filters = styled.div`
width:100%;
height:auto;
border-bottom: 1px solid #D9D9D9;
display:flex;
padding:12px 24px;
`;


const FilterTitle = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
color: #000000;
`;


const FilterContainer = styled.div`
width:100%;
height:auto;
display:flex;
padding:16px 24px 16px 24px;
flex-wrap: wrap;
row-gap: 8px;
align-items:center;
`;


const ProfileContainer = styled.div`
width:232px;
height:auto;
display:flex;
`;

const ProfileImage = styled.img`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const AppliedFilter = styled.div`
width:auto;
height: 25px;
padding:0 12px;

background: rgba(247, 35, 117, 0.09);
border-radius: 7px;

font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 25px;

color: #F72375;

margin-right:16px;
`;

const Reset = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-decoration-line: underline;
margin-left:auto;
color: #000000;
cursor:pointer;
`;


const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

const CardContainer  = styled.div`
width: 370px;
height:auto;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
padding:16px;
cursor:pointer;
`;

const ProfileLink = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const ItemContent = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:8px;
text-align:left;
`;

const ItemTitle = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align:left;
`;


export default InfluencerList;