/* eslint-disable no-useless-escape */
import {
  Button,
  Form,
  Input,
  Upload,
  message,
  Row, Col
} from 'antd';
import React, {useState} from 'react';
import firebase from "firebase/app";
import Firebase from "../../util/firebase";
import * as API from "../../util/api";
import axios from "axios";
import styled from 'styled-components';
import { debounce } from "lodash";

const { TextArea } = Input;

const CreateProductForm = ({partner}) => {

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  
  const onFinish = async (values) => {
    try {
      const requestBody = {
        ...values,
        companyId:partner.partnerId
      };
      const url = API.CREATE_PRODUCT;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.info('Product Created Successfully');
        form.resetFields();
        setLoading(false);
      }else {
        message.info('Failed to Created Product, Please Try Again');
        setLoading(false)
      }
    }catch(err){
      console.log(err);
      message.info('Failed to Created Product, Please Try Again');
      setLoading(false)
    }
  };

  const debounceFinish = debounce((values) => {
    onFinish(values)
  },500)

  const customRequestBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              bannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestMobileBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              mobileBannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestLowerBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              lowerBannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestProductUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productImage/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              productUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestVideo = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productVideo/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              video:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };


  const handleChange = async (info) => {
    if (info.file.status === "done") {
      console.log("done");
    }
    if (info.file.status === "error") {
      console.log("upload error");
    }
  };

  return (<FormContainer >
    <Form
      name="validate_other"
      onFinish={(values) => {
        if(!loading){
          setLoading(true);
          debounceFinish(values)
        }
      }}
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
    >
      <Row gutter={24}>
      <Col span={24}>
        <Form.Item
          name="name"
          label="PRODUCT NAME"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
      </Form.Item>
      </Col>
      <Col span={24}>
      <Form.Item
        name="link"
        label="PRODUCT LINK"
        labelAlign='Right'
        rules={[{ required: true,  message: "this field is required" }]}
      >
        <Input autoComplete="off" />
      </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="bannerUrl"
          label="LARGE BANNER IMAGE"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Col>

      <Col span={1}>
        <div style={{paddingTop:"30px", lineHeight:"32px", fontWeight:700, fontSize:12}}>
          or
        </div>
      </Col>

      <Col span={11}>
      <Upload 
        onChange={handleChange}
        accept="image/*"
        customRequest={customRequestBannerUrl}
        showUploadList={false}>
           <UploadButton style={{marginTop:30}}>UPLOAD</UploadButton>
      </Upload>
      </Col>

      <Col span={12}>
        <Form.Item
          name="mobileBannerUrl"
          label="MOBILE BANNER IMAGE"
          labelAlign='Right'
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Col>

      <Col span={1}>
        <div style={{paddingTop:"30px", lineHeight:"32px", fontWeight:700, fontSize:12}}>
          or
        </div>
      </Col>

      <Col span={11}>
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestMobileBannerUrl}
          showUploadList={false}>
            <UploadButton style={{marginTop:30}}>UPLOAD</UploadButton>
        </Upload>
      </Col>

      <Col span={12}>
        <Form.Item
          name="lowerBannerUrl"
          label="SECONDAER BANNER IMAGE"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Col>

      <Col span={1}>
        <div style={{paddingTop:"30px", lineHeight:"32px", fontWeight:700, fontSize:12}}>
          or
        </div>
      </Col>

        <Col span={11}>
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestLowerBannerUrl}
          showUploadList={false}>
            <UploadButton style={{marginTop:30}}>UPLOAD</UploadButton>
        </Upload>
      </Col>

      <Col span={12}>
        <Form.Item
          name="productUrl"
          label="PRODUCT IMAGE"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Col>

      <Col span={1}>
        <div style={{paddingTop:"30px", lineHeight:"32px", fontWeight:700, fontSize:12}}>
          or
        </div>
      </Col>

      <Col span={11}>
        <Upload 
          onChange={handleChange}
          accept="image/*"
          customRequest={customRequestProductUrl}
          showUploadList={false}>
            <UploadButton style={{marginTop:30}}>UPLOAD</UploadButton>
        </Upload>
      </Col>

      <Col span={12}>
        <Form.Item
          name="video"
          label="PRODUCT VIDEO"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required"}]}
          style={{marginBottom:0}}
        >
          <Input autoComplete="off" />
        </Form.Item>
      </Col>

      <Col span={1}>
        <div style={{paddingTop:"30px", lineHeight:"32px", fontWeight:700, fontSize:12}}>
          or
        </div>
      </Col>

      <Col span={11}>
        <Upload 
          onChange={handleChange}
          accept="video/*"
          customRequest={customRequestVideo}
          showUploadList={false}>
            <UploadButton style={{marginTop:30}}>UPLOAD</UploadButton>
        </Upload>
      </Col>
      
      <Col span={24}>
      <Form.Item
        name="description"
        label="DESCRIPTION"
        labelAlign='Right'
      >
         <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
        />
      </Form.Item>
      </Col>

      <Col span={24}>

        <div style={{display:'flex', alignItem:'center', marginTop:12}} >
         <SaveButton type="primary" htmlType="submit" disabled={loading} onClick={() => {
          form.submit()
         }}>
           Save
         </SaveButton>
         <Tip>
         All fields can be edited later 
         </Tip>
         </div>
      </Col>
      </Row>
    </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
font-family: SF Pro Display !important;
.ant-form-item-label > label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height:16px;
}
  .ant-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
  }
  .ant-form-item {
    margin-bottom:8px;
  }

  .ant-btn {
    border: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background: #F72375;
    color: #ffffff !important;
    border: none !important;
  }
`;

const UploadButton = styled.div`
height: 32px;
background: #000000;
border: 1px solid #000000;
border-radius: 5px;
line-height:32px;
width:308px;
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 32px;
color: #F6F6F6;
text-align:center;
cursor:pointer;
`;

const SaveButton = styled(Button)`
width: 130px;
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
`;

const Tip = styled.div`
font-weight: 400;
font-size: 14px;
line-height: 32px;
color: #F72375;
`;

export default CreateProductForm;