import React from 'react';
import styled from 'styled-components';
import {List, Button, message} from 'antd';
import * as API from "../../util/api";
import axios from "axios";

const AvailableCodes = ({game}) => {
  const availableCodes = game.availableCodes??[];

  const deleteCode = async (code) => {
    try {
      const requestBody = {
        code,
        gameId:game.gameId
      }
      const url = API.DELETE_GAME_CODE;
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        message.success("Your code is deleted")
      }else {
        message.error("Failed to delete the code, please try again")
      }
    }catch(err){
      message.error("Failed to delete the code, please try again")
    }
    
  }
  return <Container>
     <List
      bordered
      pagination={{
        pageSize:20
      }}
      size="small"
      dataSource={availableCodes}
      header={<div>Code</div>}
      renderItem={item => (
        <List.Item>
          <div style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}}>
            <div>{item}</div>
            <PrimaryButton onClick={() => {
              deleteCode(item)
            }}>Delete Code</PrimaryButton>
          </div>
        </List.Item>
      )}
    />
  </Container>
}

const Container = styled.div`
width:100%;
.ant-list {
  background:white;
  border-radius:10px;
  color: #000;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
&:focus {
  color: #FFFFFF !important;
  border:none;
  background: #F72375;
  box-shadow:none;
}
`;

export default AvailableCodes;

