import React, {useState, useEffect} from 'react';
import InfluencerList from './influencerList';
import InfluencerDetail from './influencerDetail';
import { Mixpanel } from '../../util/mixpanel';
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore

const SearchInfluencer = ({partner, influencers}) => {
  const [view, setView] = useState(1);
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if(partner){
      Mixpanel.track("[Publisher-Portal] Search Influencers", {
        partnerId:partner.partnerId,
        partnerName:partner.name
      })
    }
  }, [partner])

  const viewInfluencerDetail = (influencerId) => {
    const influencer = influencers.find(item => item.influencerId === influencerId);
    setCurrent(influencer);
    setView(2);
  }
  
  return <>
  {
    view === 1 && <InfluencerList influencers={influencers} viewInfluencerDetail={viewInfluencerDetail} 
    />
  }
  {
    view === 2 && <InfluencerDetail current={current} back={() => {
      setView(1);
      setCurrent(null)
    }}/>  
  }
  </>
}

export default SearchInfluencer;