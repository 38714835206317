import React, {useMemo} from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { Menu, Dropdown, Popover, Skeleton, List, Empty} from "antd";
import {
  BellOutlined, AreaChartOutlined
} from '@ant-design/icons'
import { useDispatch } from "react-redux";
import { useJune } from "../util/useJune";

const PublisherNavBar = ({activeTab, history, isDarkMode}) => {
  const { loggedIn, profile, id: userId, linkedProfile} = useSelector((state) => state.user);
  const analytics = useJune('NI29pX081wlcqqoi');

  const token_count = useMemo(() => {
    return profile?.token_count??0;
  }, [profile]);

  const isAdmin = useMemo(() => {
    const permission_level = profile?.permission_level??0;
    if(permission_level === 4 && profile.adminOfPartner){
      return true;
    }
    return true;
  }, [profile]);

  // // Change so permission level isn't required to access dashboard
  // const isAdmin = useMemo(() => {
  //   return true;
  // }, [[profile]]);

  // Updated to remove the requirement of having a linked account so that I can sell to more people automatically
  const redirectToDashboard = () => {
    // Navigate the user to the dashboard without checking linkedProfile
    history.push("/brand_admin/search");
  };

  // const redirectToDashboard = () => {
  //   //const type = linkedProfile.type ?? "";
    
  //   if (analytics) {
  //     analytics.track('Redirect to Dashboard', {
  //       userType: type,
  //       // Add any other relevant properties here
  //     });
  //   }
  //  // changed for any permission and type
  //  history.push("/brand_admin/search");
  //  if (type === 'game_publisher') {
  //     history.push("/brand_admin/search");
  //  } else {
  //    history.push("/brand_admin/campaigns");
  //  }
  // };

  const dispatch = useDispatch();

  const logout = () => ({
    type:"LOG_OUT",
    payload:null
  });


  const menu = (
    <StyledMenu>
      {
        isAdmin && <Menu.Item
        onClick={() => {
          redirectToDashboard()
        }}
      >
        <MenuBox>
        <Item>Brand Portal</Item>
        <AreaChartOutlined  style={{
          fontSize:20
        }}/>
        </MenuBox>
      </Menu.Item>
      }

      <Menu.Item
        onClick={() => {dispatch(logout())}}
      >
        <MenuBox>
        <Item>Logout</Item>
        <img
          src="/assets/nav-logout.png"
          alt=""
          style={{
            height: "20px",
            width: "20px",

          }}
        />
        </MenuBox>
      </Menu.Item>
    </StyledMenu>
  );
  return (
    <Container isDarkMode={isDarkMode}>
      <Logo
        onClick={() => {
          history.push("/");
        }}
        src="../assets/white_logo.png"
      ></Logo>
      <Left>
        <NavButton
          onClick={() => {
            history.push("/");
          }}
          isActive={activeTab === "home"}
          isDarkMode={isDarkMode}
        >
          Home
        </NavButton>
        {isAdmin && (
          <NavButton
            onClick={() => {
              redirectToDashboard();
            }}
            isDarkMode={isDarkMode}
          >
            Search
          </NavButton>
        )}
        {/* <NavButton onClick={toTournament} isActive={activeTab === 'tournament'} isDarkMode={isDarkMode}>Exchange</NavButton> */}
      </Left>
      <Right>
        {loggedIn ? (
          <>
            {/* <TokenCount isDarkMode={isDarkMode}>
                <img
                  src="/assets/ic_mytokens.png"
                  alt=""
                  style={{
                    height: "16px",
                    width: "20px",
                    marginRight: "8px",
                  }}
                />
                {token_count}
              </TokenCount> */}
            <SecondaryButton
              onClick={() => {
                dispatch(logout());
              }}
            >
              Log Out
            </SecondaryButton>
            {/* Removing dropdown for now because the logout from the dropdown is in the button instead and I don't have the profile set up March 22, 2024 */}
            {/* <Dropdown
                overlay={menu}
                placement="bottomLeft"
                onClick={(e) => e.preventDefault()}
                trigger={["click"]}
              >
                <Profile isDarkMode={isDarkMode} />
              </Dropdown> */}
          </>
        ) : (
          <>
            <PrimaryButton
              onClick={() => {
                history.push("/login");
              }}
            >
              Login
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                history.push("/signup");
              }}
            >
              Sign Up
            </SecondaryButton>
          </>
        )}
      </Right>
    </Container>
  );
  
}

// Added to change the login and sign up buttons style

const PrimaryButton = styled.div`
  display: flex;
  width: 100px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F72375;
  cursor: pointer;
  margin-right: 16px;
  color: #FAFAFA;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;

  @media (max-width: 768px) {
    width: 80px;
    height: 40px;
    font-size: 14px;
    margin-right: 8px;
  }
`;

const SecondaryButton = styled.div`
  display: flex;
  width: 100px;
  height: 45px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #F72375;
  color: #F72375;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;

  @media (max-width: 768px) {
    width: 80px;
    height: 40px;
    font-size: 14px;
  }
`;

const Container = styled.div`
  height: 78px;
  padding: 15px 40px;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 7px #57575729;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  ${p => p.isDarkMode && `background: #18181B 0% 0% no-repeat padding-box;`}

  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically on mobile
    height: auto; // Adjust the height to fit the logo
    padding: 15px 20px; // Reduce padding on mobile
    justify-content: center; // Center the logo
  }
`;


const NavButton = styled.div`
  width: auto;
  margin-right: 24px;
  font: normal normal 600 20px SF PRO, serif;
  color: #484848;
  cursor: pointer;
  ${(props) => props.isDarkMode && `color: #FAFAFA;`}
  ${(props) => props.isActive && `color: #F72375;`}

  @media (max-width: 768px) {
    font-size: 16px;
    margin-right: 12px;
  }
`;


const Left = styled.div`
  display: flex;
  
  align-items: center;
  

  @media (max-width: 768px) {
    display: none; /* Hide in mobile view */
  }
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-right: 48px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-right: 0; // Center the logo on mobile
    display: block; // Ensure the logo is displayed
    margin: 0 auto; // Center the logo horizontally
  }
`;


const Profile = styled.img`
  background: url("/assets/adaptive-icon-large.png") no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  cursor: pointer;
  ${(props) =>
    props.isDarkMode && `border:1px solid #18181B`}
`;  

const TokenCount = styled.div`
  font: normal normal bold 16px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
  margin-right: 32px;
  ${(props) =>
    props.isDarkMode && `color: #FAFAFA;`}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #bfbfbf;
  padding-left: 32px;
  
  @media (max-width: 768px) {
    display: none; // Hide the right navigation buttons and login/signup buttons on mobile
  }
`;

const StyledMenu = styled(Menu)`
  width: 245px;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  padding: 8px 8px;
`;

const MenuBox = styled.div`
width: 225px;
height: 54px;
display: flex;
align-items: center;
justify-content: space-between;
padding:0 32px 0 16px;
`;

const Item = styled.div`
  font: normal normal 600 18px SF PRO, serif;
  letter-spacing: 0px;
  color: #484848;
`;

const GetMore = styled.div`
  width: 112px;
  height: 32px;
  background: linear-gradient(
    102deg,
    rgba(247, 133, 104, 1) 0%,
    rgba(248, 97, 111, 1) 99%
  );
  border-radius: 7px;
  font: normal normal bold 18px/32px SF PRO, serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  margin-right: 32px;
`;

export default withRouter(PublisherNavBar)