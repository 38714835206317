import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Firebase from "../../util/firebase";
import Select from 'react-select';
import {
  message,
} from 'antd';

const SelectGame = ({partner, saveSelectGame}) => {
  const [availableGames, setAvailableGames] = useState([]);
  const [selectGame, setSelectGame] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      const partnerId = partner.partnerId;
      Firebase.firestore()
      .collection("partnerGames")
      .where("companyId", "==", partnerId)
      .onSnapshot(function (querySnapshot) {
      let games = [];
      querySnapshot.forEach(function (doc) {
        const obj = {
          ...doc.data(),
          gameId: doc.id,
        };
        games.push(obj);
      });
      setAvailableGames(games);
    });
    }
    if(partner && partner.partnerId){
      fetchProducts()
    }
  }, [partner]);

  const options = useMemo(() => {
    const products = [];
    availableGames.forEach((item) => {
      const obj = {
        value: item.gameId,
        label: item.name
      }
      products.push(obj);
    })
    return products;
  }, [availableGames]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width:718,
      height: 32,
      background: " #FFFFFF 0% 0% no-repeat padding-box",
      border: "1px solid #000000",
      borderRadius: "5px",
      boxShadow: "none",
      marginTop:16,
      marginBottom:12,
      '&:hover': {
        border: '1px solid black',
     }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "white",
      };
    },
  };
  const setCampaignGame = () => {
    if(selectGame){
      saveSelectGame(selectGame);
    }else {
      message.error("Please Select Product");
    }
  }

  return <Container>
    <Title>
      Select Your Game
    </Title>
    <Subtitle>
    What is the Game that you want your influencers to promote? Enter its details below.
    </Subtitle>
    <SelectContainer>
        <Subtitle style={{fontWeight:700}}>SELECT GAME</Subtitle>
        <Select options={options} onChange={(item) => {
          setSelectGame(item?.value?? null)
        }}
        styles={colourStyles}
        isClearable={true}
        placeholder="Your Game"
        />
        <NextButton onClick={() => {
          setCampaignGame()
        }}>
            Next
        </NextButton>
    </SelectContainer>
  </Container>

}

const Container = styled.div`
display:flex;
flex-direction:column;
font-family: SF Pro Display;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const SelectContainer = styled.div`
margin-top:24px;
width: 750px;
height: auto;

background: #FFFFFF;
border-radius: 10px;
padding:12px;
margin-bottom:24px;
`;

const NextButton = styled.div`
width: 130px;
height: 32px;
background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 32px;
text-align: center;
color: #F6F6F6;
`;



export default SelectGame;