import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  DatePicker,
  message
} from 'antd';

import moment from "moment";
import {categoriesOption} from "./option";
import Select from "react-select"

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
};

const colourStyles = {
  control: (styles) => ({
    ...styles,
    width:"auto",
    height: "auto",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    borderRadius: "5px",
    boxShadow: "none",
    '&:hover': {
      border: '1px solid #000000',
   }
  }),
};


const EditDetailModal = ({open, closeModal, tempDetail, updateTempDetail}) => {
  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: 600,
      height:'auto',
      padding:'24px',
      background: "#FFFFFF",
      borderRadius: "10px",
      zIndex: "10000",
    },
  };

  const [form] = Form.useForm();
  return <Modal
  isOpen={open}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
<Top>
  <ArrowLeftOutlined style={{marginRight:8}}/>
  Edit Campaign Detail
</Top>
<Container>
<Form
      name="validate_other"
      onFinish={(values) => {
        updateTempDetail(values);
        closeModal();
        message.success("Campaign Detail is Updated")
      }}
      initialValues={{
        name:tempDetail?.name??"",
        budget:tempDetail?.budget??"",
        date:[moment(tempDetail.startDate), moment(tempDetail.endDate)],
        category:{
          value:tempDetail?.category??"",
          label:tempDetail?.category??""
        },
        description:tempDetail?.description??""
      }}
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
    > 
      <Form.Item
          name="name"
          label="CAMPAIGN NAME"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
          name="budget"
          label="BUDGET($USD)"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" type="number"/>
      </Form.Item>
      <Form.Item
          name="date"
          label="DURATION"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <RangePicker disabledDate={disabledDate} />
      </Form.Item>
      <Form.Item
        name="category"
        label="Category"
        labelAlign='Right'
      >
          <Select
          styles={colourStyles}
      options={categoriesOption}
    />
      </Form.Item>
      <Form.Item
          name="description"
          label="DESCRIPTION"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
           <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
        />
      </Form.Item>
      <PrimaryButton type="primary" htmlType="submit"  onClick={() => {
          form.submit()
         }}>
         Update
         </PrimaryButton>
      </Form>
      </Container>
</Modal>
}

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const Container = styled.div`
.ant-form-item-label > label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height:16px;
}
  .ant-input,
  .ant-input-number-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
  }
  .ant-form-item {
    margin-bottom:8px;
  }

  .ant-btn {
    border: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background: #F72375;
  }

  .ant-picker,
  .ant-picker-focused {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
    width:550px;
  }
  margin:24px 0px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
float:right;
margin-top:24px;
`;

export default EditDetailModal;