import React, {useState, useMemo, useEffect} from 'react';
import styled from 'styled-components';
import Firebase from "../../util/firebase";
import Loading from '../../util/loading';

const PostDetail = ({postId}) => {
  const [post, setPost] = useState(null);

  useEffect(() => {
    let listener = null;
    const fetchPost = (productId) => {
      listener = Firebase.firestore().collection("clubPost").doc(postId).onSnapshot((doc) => {
        if(doc.data()){
          const obj = {...doc.data(), postId};
          setPost(obj)
        }
       })
    } 
    fetchPost()
    return () => {
      if(listener){
        listener();
      }
    } 
  }, [postId])
  return <Container>
    {
      post ? <>
      <div style={{display:'flex', marginBottom:16}}>
        {
          post?.creator?.image ? <img src={post?.creator?.image} alt="avator" style={{
            height:30,
            width:30,
            borderRadius:'50%',
            marginRight:12
          }}/> : <img src="/assets/user_black.svg" alt='avator' style={{
            height:30,
            width:30,
            marginRight:12
          }} />
        }
        <Name>
          {post?.creator?.name}
        </Name>
      </div>
      <Content dangerouslySetInnerHTML={{ __html: post?.content }} />
      </> : <Loading />
    }
  </Container>
}

const Container = styled.div`
width: 753px;
height: auto;
flex-shrink: 0;
padding:24px;
border-radius: 10px;
background: #FFF;
margin-bottom:24px;
min-height:144px;
`;

const Name = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 30px;
`;

const Content = styled.div`
/* color:white !important;

h1, h2 {
  color:white !important;
} */
`;

export default PostDetail