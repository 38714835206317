import {useStripe, useElements} from '@stripe/react-stripe-js';
import {CardElement} from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { Button } from 'antd';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/auth'; // If using authentication
import 'firebase/firestore'; // If using Firestore

const PaymentCard = () => {
    const stripe = useStripe();
    const elements = useElements();
  

  const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
      base: {
        'color': '#32325d',
        'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
        'fontSmoothing': 'antialiased',
        'fontSize': '16px',
        'backgroundColor':"#FFFFFF",
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  /* const handleSubmitSub = async (event) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }else {
      try {
        const email = profile.email;
        const name = profile.first_name;
    
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        });
    
        if (result.error) {
          setLoading(false);
          toast.error(result.error.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
          });
        } else {
            // change api (create payment) => stripe/publisherSubInitial
          const endpoint = API.STRIPE_MULTIPLIER_SUB;
          const res = await axios.post(endpoint, {'payment_method': result.paymentMethod.id, 'email': email, 'name':name});
          // eslint-disable-next-line camelcase
          if(res.status === 404){
            setLoading(false)
            toast.error("Payment Error, please try again or contact support", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
          }else {
            // change the property name got from the api 
            const {client_secret, status, platformSubId, renewAt, startAt } = res.data;
    
            if (status === 'requires_action') {
              stripe.confirmCardPayment(client_secret).then(async function(result) {
                if (result.error) {
                  //failed
                  setLoading(false)
                  toast.error("Payment Error, please try again or contact support", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                  });
                } else {
                  //success
                  // sub/publish_sub_store follow the request body property
                  const requestBody = {
                    platformSubId,
                    renewAt:moment(renewAt*1000).format(),
                    startAt:moment(startAt*1000).format(),
                    userId
                  }
                  const subEndpoint = API.STORE_MULTIPLIER_SUB;
                  const res = await axios.post(subEndpoint, requestBody);
                  if(res.status === 200){
                    successSub();
                  }
                }
              });
            } else if(status === "succeeded"){
              //success
              // sub/publish_sub_store follow the request body property
              const requestBody = {
                platformSubId,
                renewAt:moment(renewAt*1000).format(),
                startAt:moment(startAt*1000).format(),
                userId
              }
              const subEndpoint = API.STORE_MULTIPLIER_SUB;
              const res = await axios.post(subEndpoint, requestBody);
              if(res.status === 200){
                successSub();
              }
            }else {
              toast.error("Payment Error, please try again or contact support", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }
          }
        }
      }catch(err){
        setLoading(false)
        toast.error("Payment Error, please try again or contact support", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    }
  }; */

  return <CardContainer>
  <CardElement options={CARD_ELEMENT_OPTIONS} />
  <Button type='primary' onClick={() => {
    /* handleSubmitSub() */
  }}>
    Submit
  </Button>
</CardContainer>

}

const CardContainer = styled.div`
width:435px;
margin-top:16px;
padding:12px;
background-color:#FFFFFF;
border-radius:5px;
margin-bottom:24px;
`;

export default PaymentCard;