import {
  Button,
  Form,
  Input,
  Upload,
  message,
  DatePicker,
  InputNumber,
  Row, 
  Col
} from 'antd';
import Modal from "react-modal";
import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import Firebase from "../../util/firebase";
import * as API from "../../util/api";
import axios from "axios";
import styled from 'styled-components';
import { debounce } from "lodash";
import { withRouter } from 'react-router-dom';
import { ArrowLeftOutlined} from '@ant-design/icons';
import moment from 'moment'

const { TextArea } = Input;

const EditGameModal = ({game, open, closeModal}) => {
  const customStyles = {
    content: {
      top: "88px",
      margin: "0 auto",
      bottom: "auto",
      width: 888,
      height: 800,
      padding:'24px',
      background: "#FFFFFF",
      borderRadius: "10px",
      zIndex: 100,
      overflowY:'auto'
    },
  };

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [productImages, setProductImages] = useState([]);

  useEffect(() => {
    const images = game.productImages.map((item) => {
      return {
        status:'done',
        url:item
      }
    })
    setProductImages(images)
  }, [game]);

  const debounceUpdateGame =  debounce((values) => {
    updateGame(values)
  },500);

  const updateGame = async (values) => {
    const images = productImages.map((item) => {
      return item.url
    })
    const updatedValues = {
      ...values,
      productImages:images
    }
    const requestBody = {
      gameId: game.gameId,
      updatedValues
    }
    const url = API.UPDATE_GAME;
    try {
      const res = await axios.post(url, requestBody);
      if(res.status === 200){
        setLoading(false);
        closeModal()
        message.success("Your game is updated")
      }else {
        setLoading(false);
        message.error("Failed to update game, please try again")
      }
    }catch(err){
      setLoading(false);
        message.error("Failed to update game, please try again")
    }
  }



  const customRequestBannerUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              bannerUrl:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const customRequestVideo = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productVideo/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            form.setFieldsValue({
              video:downloadURL
            });
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      console.log("done");
    }
    if (info.file.status === "error") {
      console.log("upload error");
    }
  };

  const customRequestProductUrl = async ({ onError, onSuccess, file }) => {
    const pattern = /[`~!@#$^&*()=|{}':;',\\\[\]\<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
    const fileName = file.name.replace(pattern, "");
    const uploadTask = Firebase.storage()
      .ref()
      .child("productBanner/" + fileName)
      .put(file);
    try {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {},
        (error) => {
          console.log(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            const newImage = {
              status:'done',
              url:downloadURL
            }
            setProductImages(prev => {
              return [...prev, newImage]
            })
          });
        }
      );
      onSuccess(() => console.log("success"));
    } catch (err) {
      onError(err);
      console.log(err);
    }
  };

  return <Modal
  isOpen={open}
  style={customStyles}
  onRequestClose={closeModal}
  ariaHideApp={false}
>
<>
<Top>
  <ArrowLeftOutlined style={{marginRight:8, cursor:'pointer'}} onClick={closeModal}/>
  Update Game
</Top>
<FormContainer>
       <Form
      name="validate_other"
      onFinish={(values) => {
        
      }}
      labelCol={{ span: 24 }}
      style={{maxWidth:800}}
      form={form}
      initialValues={{
        name:game.name??undefined,
        steam_link:game.steam_link??undefined,
        createdDate:game.createdDate? moment(game.createdDate) : undefined,
        genre:game.genre??undefined,
        categories:game.categories??undefined,
        regular_price:game.regular_price??undefined,
        bannerUrl:game.bannerUrl??undefined,
        video:game.video??undefined,
        long_description:game.long_description??"123",
        short_description:game.short_description??undefined
      }}
    >
       <Row gutter={24} style={{marginBottom:96}}>
       <Col span={12}>
        <Form.Item
          name="name"
          label="Game NAME"
          labelAlign='Right'
          rules={[{ required: true,  message: "this field is required" }]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
        name="steam_link"
        label="Steam Link"
        labelAlign='Right'
        rules={[{ required: true,  message: "this field is required" }]}
      >
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="createdDate"
        label="Created Date"
        labelAlign='Right'
      >
        <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
      </Form.Item>
      <Form.Item
        name="genre"
        label="Genre"
        labelAlign='Right'
      >
        <Input autoComplete="off" />
      </Form.Item>

      <Form.Item
        name="categories"
        label="Categories"
        labelAlign='Right'
      >
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item
        name="regular_price"
        label="Price on steam"
      >
        <InputNumber
          min={0}
          onWheel={() => document.activeElement.blur()}
        />
      </Form.Item>
      <Form.Item
        name="bannerUrl"
        label="Banner Image"
        labelAlign='Right'
      >
      <Input autoComplete="off" />
      </Form.Item>
      <Upload 
        onChange={handleChange}
        accept="image/*"
        customRequest={customRequestBannerUrl}
        showUploadList={false}>
          <UploadButton>UPLOAD</UploadButton>
      </Upload>
      <Form.Item
        name="video"
        label="Upload Video"
        labelAlign='Right'
      >
       <Input autoComplete="off" />
      </Form.Item>
      <Upload 
        onChange={handleChange}
        accept="video/*"
        customRequest={customRequestVideo}
        showUploadList={false}>
          <UploadButton>UPLOAD</UploadButton>
      </Upload>
      </Col>
      <Col span={12}>
      <Form.Item
        name="short_description"
        label="Short description"
        labelAlign='Right'
      >
         <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 2, maxRows: 4 }}
          maxLength={256}
        />
      </Form.Item>
      <Form.Item
        name="long_description"
        label="Long description"
        labelAlign='Right'
      >
         <TextArea
          type="text"
          showCount
          autoSize={{ minRows: 8, maxRows: 12 }}
          maxLength={2056}
        />
      </Form.Item>
      <Text>
        upload gameplay images
      </Text>
      <Upload
            accept="image/*"
            listType="picture"
            className="upload-list-inline"
            customRequest={customRequestProductUrl}
            fileList={productImages}
            onRemove={(file) => {
              setProductImages(prev => {
                const newList = prev.filter(item => item !== file);
                return newList;
              })
            }}
            >
            <UploadButton>UPLOAD</UploadButton>
        </Upload>
      </Col>
       </Row>
       <div style={{width:'100%', display:'flex'}}>
       <PrimaryButton type="primary" htmlType="submit" disabled={loading} onClick={() => {
        /* const values = form.getFieldsValue(true);
        console.log(values) */
          if(!loading){
            setLoading(true);
            const values = form.getFieldsValue(true);
            debounceUpdateGame(values)
          }
         }}>
          {
            loading? 'Loading':'Save'
          }
         </PrimaryButton>
         <Tip>
         All fields can be edited later 
         </Tip>
       </div>
    </Form>
  </FormContainer>
</>
</Modal>
}

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const FormContainer = styled.div`
border-radius: 10px;
width:800px;
padding:24px;
background: #FFF;
font-family: SF Pro Display !important;
.ant-form-item-label > label {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height:16px;
  text-transform: uppercase;
}
  .ant-input,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input:hover,
  .ant-input-number
  {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
    width:100%%;
  }
  .ant-form-item {
    margin-bottom:12px;
  }

  .ant-btn {
    border: none;
  }
  .ant-btn:hover,
  .ant-btn:focus {
    background: #F72375;
    color: #ffffff !important;
    border: none !important;
  }

  .ant-picker,
  .ant-picker-focused {
    border: 1px solid #000000;
    box-shadow: none !important;
    border-radius:5px;
    width:100%;
  }
`;

const UploadButton = styled.div`
height: 32px;
background: #000000;
border: 1px solid #000000;
border-radius: 5px;
line-height:32px;
width:364px;
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 32px;
color: #F6F6F6;
text-align:center;
cursor:pointer;
`;

const Text = styled.div`
font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  height:16px;
  text-transform: uppercase;
  margin-bottom:12px;
`;

const Tip = styled.div`
font-weight: 400;
font-size: 14px;
line-height: 32px;
color: #F72375;
margin-left:auto;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;

const SecondaryButton = styled(Button)`
width: 130px;
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

export default EditGameModal;