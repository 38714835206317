import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  Input,
  Button,
  Table,
  Row,
  Col,
  DatePicker,
  message,
  List,
  Rate
} from 'antd';
import { ArrowLeftOutlined, CheckOutlined} from '@ant-design/icons';
import moment from "moment"
import Select from "react-select";
import {categoriesOption} from "./option";

import EditDetailModal from './editDetailModal';
import Modal from "react-modal";

import * as API from "../../util/api";

import axios from "axios";

import { debounce } from "lodash";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const colourStyles = {
  control: (styles) => ({
    ...styles,
    height: "32px",
    background: " #FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #000000",
    borderRadius: "5px",
    boxShadow: "none",
    '&:hover': {
      border: '1px solid #000000',
   }
  }),
};

const ProposalReview = ({tempDetail, 
  selectInfluencers, 
  updateTempDetail,
   partner, 
   selectProduct,
   selectGame,
   history, 
   campaignMutable, 
   editExisting, 
   campaignId, 
   unMutableInfluencersList,
   backToSelectInfluencers
  }) => {

  const [editModalOpen, setEditModalOpen] = useState(false);

  const [sendModalOpen, setSendModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const type = partner?.type??"brand";

  const selectedValue = {
    value:tempDetail?.category??"",
    label:tempDetail?.category??""
  }

  const list = useMemo(() => {
    const arr = [];
    selectInfluencers.forEach((item) => {
      const {location, description, metricsData, name, profile_image_url, language, pricePerQuest, influencerId} = item;
      const avg_viewers = metricsData?.avg_viewers??"-";
      const followers = metricsData?.followers??"-";
      const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
      const pricePerStream = pricePerQuest ? pricePerQuest: estimatedPrice;
      const obj = {
        location,
        pricePerStream,
        description,
        name,
        avg_viewers,
        followers,
        profile_image_url,
        categories: item.categories??[],
        language,
        influencerId,
        priceSet:pricePerQuest? true:false
      }
      arr.push(obj)
    });
    const unMutableArr = arr.filter(item => (unMutableInfluencersList??[]).includes(item.influencerId));
    const restArr = arr.filter(item => !(unMutableInfluencersList??[]).includes(item.influencerId));
    const result = unMutableArr.concat(restArr);
    return result;
  }, [selectInfluencers, unMutableInfluencersList]);


  const debounceSendProposals =debounce(() => {
    console.log(editExisting, campaignId)
    if(!editExisting){
      createAndSendProposals()
    }else if(editExisting && campaignId){
      console.log('send ')
      sendProposalsOnly()
    }
  },500)

  const sendProposalsOnly = async () => {
    try {
      const influencersList = selectInfluencers.reduce((acc, curr) => {
        acc.push(curr.influencerId);
        return acc;
      },[]);
      const requestBody = {
        newInfluencers:influencersList, 
        campaignId
      }
      const endPoint = API.SEND_NEW_PROPOSALS;
      const res = await axios.post(endPoint, requestBody);
      if(res.status === 200){
        setLoading(false);
        setSendModalOpen(false)
        message.success("New Proposal sent! You will be redirected to the detail page");
        setTimeout(() => {
          history.push(`/brand_admin/campaigns/${campaignId}`)
        }, 2000)
      }else {
        setLoading(false);
        setSendModalOpen(false)
        message.error("Failed to send Proposals. Please try again")
      }
    }catch(err){
      setLoading(false);
      setSendModalOpen(false)
      message.error("Failed to send Proposals. Please try again")
    }
  }


  const createAndSendProposals = async () => {
      try {
      const influencersList = selectInfluencers.reduce((acc, curr) => {
        acc.push(curr.influencerId);
        return acc;
      },[])
      const requestBody = {
        ...tempDetail,
        influencersList,
        partnerId:partner.partnerId,
      }
      if(type === 'brand'){
        requestBody.type = 'brand';
        requestBody.productId = selectProduct
      }
      if(type === 'game_publisher'){
        requestBody.type = 'game';
        requestBody.gameId = selectGame;
      } 
      const endPoint = API.CREATE_CAMPAIGN;


      const res = await axios.post(endPoint, requestBody);
      if(res.status === 200){
        const {campaignId} = res.data;
        setLoading(false);
        setSendModalOpen(false)
        message.success("Campaign created! You will be redirected to the detail page");
        setTimeout(() => {
          history.push(`/brand_admin/campaigns/${campaignId}`)
        }, 2000)
      }else {
        setLoading(false);
        setSendModalOpen(false)
        message.error("Failed to send Proposals. Please try again")
      }
    }catch(err){
        setLoading(false);
        setSendModalOpen(false)
        message.error("Failed to send Proposals. Please try again")
    }
    
  }

  const isInUnmutableList = (influencerId) => {
    return unMutableInfluencersList??[].includes(influencerId);
  }


  const getInfluencerStatus = (influencerId) => {
    if(isInUnmutableList(influencerId)){
      return <StatusContainer unmuted={true}>
       <CheckOutlined style={{fontSize:18, marginRight:8}}/>
          Selected
      </StatusContainer>
    }else {
        return <StatusContainer 
        selected={true}
        >
          <CheckOutlined style={{fontSize:18, marginRight:8}}/>
          Selected
          </StatusContainer>
      }
   
  }

  const allocatedBudget = useMemo(() => {
    const startDate = tempDetail?.startDate?? "";
    const endDate = tempDetail?.endDate??"";
    const weeks = Math.ceil(moment(endDate).diff(moment(startDate), 'weeks', true));
    const budgets = selectInfluencers.reduce((acc, curr) => {
    const avg_viewers = curr.metricsData?.avg_viewers??"-";
    const estimatedPrice = !isNaN(avg_viewers) ? (avg_viewers * 2): 0;
    const pricePerStream = curr.pricePerQuest ? curr.pricePerQuest:estimatedPrice;
    const price = pricePerStream*weeks;
    return acc + price;
    }, 0);
    return budgets;
  }, [selectInfluencers, tempDetail]);

  const remainingBudget = useMemo(() => {
    return (tempDetail?.budget??0) - (allocatedBudget??0); 
  }, [tempDetail, allocatedBudget]);


  const customStyles = {
    content: {
      top: "108px",
      margin: "0 auto",
      bottom: "auto",
      width: 600,
      height:'auto',
      padding:'24px',
      background: "#FFFFFF",
      borderRadius: "10px",
      zIndex: "10000",
    },
  };


  return <Container>
    <TopContainer>
      <div>
        <Title>
        Send Proposals
        </Title>
        <Subtitle>
        Confirm the influencer you want to invite to the campaign. Once you finalize you can’t add/invite more.
        </Subtitle>
        </div>
        <Operations>
          <SecondaryButton onClick={() => {
            backToSelectInfluencers()
          }}>
            <ArrowLeftOutlined /> Previous Step 
          </SecondaryButton>
          <SecondaryButton>
            Save For Later
          </SecondaryButton>
          <PrimaryButton onClick={()=> {
            setSendModalOpen(true)
          }}>
            Send Proposals
          </PrimaryButton>
        </Operations>
    </TopContainer>
    <DetailContainer>
    <Row gutter={16}>
      <Col className="gutter-row" span={8} >
            <ColItem>
            <ColTitle>
              campaign name
            </ColTitle>
            <Input value={tempDetail?.name??""} disabled style={{display:'block'}}/>
            </ColItem>
            <ColItem>
            <ColTitle>
            budget ($USD)
            </ColTitle>
            <Input value={tempDetail?.budget??""} disabled style={{display:'block'}}/>
            </ColItem>
      </Col>
      <Col className="gutter-row" span={8}>
          <ColItem>
            <ColTitle>
              DURATION
            </ColTitle>
            <RangePicker disabled value={[moment(tempDetail.startDate), moment(tempDetail.endDate)]} />
            </ColItem>
            <ColItem>
            <ColTitle>
              category
            </ColTitle>
            <Select
          styles={colourStyles}
      options={categoriesOption}
      isDisabled
      value={selectedValue}
      disabled
    />
            </ColItem>
      </Col>
      <Col className="gutter-row" span={8} style={{width:354}}>
      <ColTitle>
              description
            </ColTitle>
            <TextArea
          type="text"
          autoSize={{ minRows: 3, maxRows: 8 }}
          maxLength={320}
          value={tempDetail?.description??""}
          disabled
        />
        {
          campaignMutable &&  <PrimaryButton style={{marginTop:8,float:'right', marginRight:0}} onClick={() => {
            setEditModalOpen(true)
          }}>
            Edit
          </PrimaryButton>
        }
      </Col>
    </Row>
    </DetailContainer>
    <TopContainer style={{marginBottom:24}}>
      <Title>
        Selected Influencers
      </Title>
      <Credits>
          <Credit style={{borderRight:"1px solid #A8A8A8"}}>
            <span style={{display:'inline-block'}}>
              Allocated Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {allocatedBudget}
              </span>
            </div>
          </Credit>
          <Credit>
          <span style={{display:'inline-block', marginLeft:8}}>
          Est. Remaining of Budget
            </span>
            <div style={{marginRight:8}}>
              <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{
                width:12,
                height:12,
                marginRight:4
              }}/>
              <span style={{display:'inline-block'}}>
                {remainingBudget}
              </span>
            </div>
          </Credit>
        </Credits>
    </TopContainer>
    {/* <Table columns={columns} dataSource={list} /> */}
    <List
    grid={{ gutter: 16, column: 3 }}
    dataSource={list}
    renderItem={(item) => (
      <List.Item>
        <CardContainer 
          /* onClick={() => {
            viewInfluencerDetail(item.influencerId)
          }} */
        >
            <div style={{display:'flex'}}>
              <ProfileImage src={item.profile_image_url??""} alt="profile"/>
              <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                <ProfileName>
                  {item.name}
                </ProfileName>
                <ProfileLink>
                  {item.twitchUrl}
                </ProfileLink>
                <div style={{display:'flex', flexWrap:'wrap', minHeight:16}}>
              {
                item.categories.map((item) => <ProfileTag>
                  {item}
                </ProfileTag>)
              }
              </div>
              </div>
            </div>
            <ProfileDesc style={{marginTop:16}}>
              {item.description}
            </ProfileDesc>
            <div style={{display:'flex', justifyContent:'space-evenly', margin:'24px 0px'}}>
              <div style={{width:'30%', textAlign:'left', borderRight:'2px solid rgba(0, 0, 0, 0.42)'}}>
                <ItemContent>
                <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/> {item.followers}
                </ItemContent>
                <ItemTitle>
                  Followers
                </ItemTitle>
              </div>
              <div style={{width:'35%', textAlign:'left', borderRight:'2px solid rgba(0, 0, 0, 0.42)',paddingLeft:8}}>
                <ItemContent>
                <img src="/assets/twitch_icon.png" alt="twitch" style={{width:12, height:12, marginRight:4}}/> {item.avg_viewers}
                </ItemContent>
                <ItemTitle>
                  Concurrent Viewers
                </ItemTitle>
              </div>
              <div style={{width:'35%', textAlign:'left', paddingLeft:8}}>
                <ItemContent>
                <Rate disabled defaultValue={5} />
                </ItemContent>
                <ItemTitle>
                  Reviews(0)
                </ItemTitle>
              </div>
            </div>
            <ItemContent>
            <img src="/assets/dollar-sign-circle.svg" alt="credit" style={{width:18, height:18, marginRight:4}}/>
            {item.pricePerStream}
            </ItemContent>
            <ItemTitle>
            {
                item.priceSet ? 'Price Per Steam' : "Estimated Price Per Stream"
              }
            </ItemTitle>
            {getInfluencerStatus(item.influencerId)}
           
          </CardContainer>
      </List.Item>
    )}
    />
    {
      editModalOpen && <EditDetailModal open={editModalOpen} closeModal={() => {
        setEditModalOpen(false)
      }} 
      tempDetail={tempDetail}
      updateTempDetail={updateTempDetail}
      />
    }
    {
      sendModalOpen && <Modal isOpen={sendModalOpen}
      style={customStyles}
      onRequestClose={() => {
        setSendModalOpen(false);
      }}
      ariaHideApp={false}>
        <Top>
        <ArrowLeftOutlined style={{marginRight:8}}/>
        send proposals
      </Top>
      <Text>
      Upon confirming, all of your invites will be sent out to the influencers you’ve selected. Your campaign won’t be live but the influencer will receive your proposals and can accept or deny them . You will still be able to reject influencers who accepted your campaign proposal.
      </Text>
      <SecondaryButton onClick={()=> {
        if(!loading){
          setLoading(true);
          debounceSendProposals()
        }
      }}
      disabled={loading}
      >
        {loading? 'Loading...':'Send'}
      </SecondaryButton>
      </Modal>
    }
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;

.ant-input,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused,
.ant-input[disabled],
.ant-picker-disabled
{
  border: 1px solid #000000;
  box-shadow: none !important;
  border-radius:5px;
  background:#FFFFFF;
  color: #000000;
  height:38px;
}

.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}

.ant-rate {
  font-size:14px;
}
`;

const TopContainer = styled.div`
display:flex;
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
  border:none;
}
`;

const Operations = styled.div`
display:flex;
margin-left:auto;
`;

const SecondaryButton = styled(Button)`
height: 32px;

background: #FFFFFF;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F72375;
margin-right:16px;
border: 1px solid #F72375 !important;
&:hover {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
}
&:focus {
  background: #FFFFFF !important;
  color: #F72375 !important;
  border: 1px solid #F72375 !important;
  box-shadow:none;
}
`;

const DetailContainer = styled.div`
width: 100%;
height: auto;

background: #FFFFFF;
border-radius: 10px;
padding:24px;
margin:24px 0px;
`;

const ColItem = styled.div`
display:flex;
flex-direction:column;
padding-right:16px;
margin-bottom:16px;
`;

const ColTitle = styled.div`
font-style: normal;
font-weight: 700;
font-size: 12px;
line-height: 14px;
display: flex;
align-items: center;
text-transform: uppercase;
margin-bottom:12px;
`;

const ProfileContainer = styled.div`
width:232px;
height:auto;
display:flex;
`;

const ProfileImage = styled.img`
width:65px;
height:65px;
border-radius:50%;
margin-right:12px;
`;

const ProfileName = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
color: #000000;
`;

const ProfileDesc = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height:12px;
overflow:hidden;
white-space:nowrap;
text-overflow:ellipsis;
`;

const Followers = styled.div`
display:flex;
align-items:center;
font-style: normal;
font-weight: 700;
font-size: 24px;
`;

const ProfileTag = styled.div`
width: auto;
height: 16px;

background: #EFEFEF;
border-radius: 3px;
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height */
margin-right:8px;
padding:0 2px;

color: #000000;
`;

const Credits = styled.div`
margin-left:auto;
display:flex;
width: 576px;
height: 41px;
background: #FFFFFF;
border-radius: 7px;

font-weight: 600;
font-size: 14px;
color: #000000;
padding:8px;
`;

const Credit = styled.div`
display:flex;
width:50%;
justify-content:space-between;
align-items:center;
`;

const Top = styled.div`
font-style: normal;
font-weight: 600;
font-size: 16px;
/* identical to box height */

text-transform: uppercase;

color: #000000;
`;

const Text = styled.div`
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #000000;
margin:24px 0px;
`;

const CardContainer  = styled.div`
width: 370px;
height:auto;
flex-shrink: 0;
border-radius: 10px;
background: #FFF;
padding:16px;
cursor:pointer;
`;

const ProfileLink = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const ItemContent = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom:8px;
text-align:left;
`;

const ItemTitle = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align:left;
`;

const StatusContainer = styled.div`
margin-top:24px;
width: 100%;
height: 36px;
text-align:center;
border-radius: 5px;
background: #F72375;

color: #FFF;
font-family: SF Pro Display;
font-size: 14px;
font-style: normal;
line-height: 36px;

${p => p.selected && `background:white; color: #F72375; border:1px solid #F72375;`}
${p => p.unmuted && `background:white; color: #6A6A6A; border:1px solid #6A6A6A; cursor:not-allow;`}
`;

export default withRouter(ProposalReview)