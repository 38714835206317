import React, { useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Table,
  Rate,
  Progress
} from 'antd';
import Firebase from "../../util/firebase";
import {
  PlusOutlined
} from '@ant-design/icons';
import Loading from "../../util/loading";
import { Mixpanel } from '../../util/mixpanel';

const Games = ({history, partner, games}) => {

  useEffect(()=> {
    if(partner){
      Mixpanel.track("[Publisher-Portal] View all games page", {
        partnerId:partner.partnerId,
        partnerName:partner.name
      })
    }
  }, [partner])

  const calcPercent = (record) => {
    const {availableCodes, redeemedCodes} = record;
    const availableCount = (availableCodes??[]).length;
    const redeemedCount = (redeemedCodes??[]).length;
    const total = availableCount + redeemedCount;
    return (redeemedCount/total) *100;
  }

  const getAverageRating = (record) => {
    const {rating} = record;
    if(rating.length === 0) {
      return 5;
    }else {
      const itemAverage = rating.reduce((acc, curr) => {
        const average = ((curr.rating.easy??5) + (curr.rating.fun??5) + (curr.rating.refer??5))/3;
        acc = acc + average;
        return acc;
      }, 0);
      const average = itemAverage/(rating.length);
      console.log(average)
      return average;
    }
  }

  const columns = [
   
    {
      title:"Title",
      key:"name",
      render:(record) => {
        return <Name>
         {record.name}
        </Name>
      }
    },

    {
      title:"Description",
      key:'description',
      render:(record) => {
        return <Description>
          {record.short_description}
        </Description>
      }
    },
    {
      title:"Reviews",
      render:(record) => {
        return  <>
        <Rate disabled defaultValue={getAverageRating(record)} allowHalf/>
        <RateCount>
          ({record.rating.length??0})
        </RateCount>
        </>
      }
    },
    {
      title:"Codes",
      render:(record) => {
        return <div style={{width:180}}>
          <Progress
            percent={calcPercent(record)}
            showInfo={false}
            strokeColor="#F72375"
            strokeWidth={5}
            />
          <div style={{display:'flex', width:'100%', justifyContent:'space-between'}}>
            <div>
              <Count>
                Redeemed
              </Count>
              <Count>
                {(record.redeemedCodes??[]).length}
              </Count>
            </div>
            <div>
              <Count>
                Remaining
              </Count>
              <Count style={{textAlign:'right'}}>
              {(record.availableCodes??[]).length}
              </Count>
            </div>
          </div>
        </div>
      }
    },
    {
      title:"",
      key:"operation",
      render: (record) => {
        return <PrimaryButton style={{width:130}} onClick={() => {
          history.push(`/brand_admin/games/gameDetail/${record.gameId}`)
        }}>
          View
        </PrimaryButton>
      }
    },

  ]

  return <Container>
      <TopContainer>
      <div>
        <Title>
        Games
        </Title>
        <Subtitle>
        Put all the games you want to share with your influencers here
        </Subtitle>
        </div>
        <PrimaryButton style={{marginLeft:'auto'}} onClick={() => {
            history.push("/brand_admin/games/create")
          }}>
            Add Game <PlusOutlined />
          </PrimaryButton>
    </TopContainer>
    <Table columns={columns} dataSource={games} />
  </Container>
}

const Container = styled.div`
width:1142px;
display:flex;
flex-direction:column;
font-family: SF Pro Display;


.ant-btn {
  border: none;
}
.ant-btn:hover,
.ant-btn:focus {
  background: #F72375;
}

.ant-table-container {
  border: 1px solid #D9D9D8
}

.ant-table-cell {
  background:#FFFFFF !important;
  font-style: normal;
font-weight: 700;
font-size: 14px;
padding:8px;
}
`;

const Title = styled.div`
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
color: #000000;
`;

const Subtitle = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
color: #000000;
`;

const TopContainer = styled.div`
display:flex;
margin:24px 0px;
`;

const PrimaryButton = styled(Button)`
height: 32px;

background: #F72375;
border-radius: 7px;
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #F6F6F6;
margin-right:16px;
&:hover {
  color: #FFFFFF !important;
  border:none;
}
&:focus {
  color: #FFFFFF !important;
}
`;

const Name = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #000000;
`;

const Description = styled.div`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 19px;
width:300px;
`;

const RateCount = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;

const Count = styled.div`
color: #000;
font-family: SF Pro Display;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;



export default withRouter(Games)